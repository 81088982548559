import React from "react";
import { Input } from "antd";
import { debounce } from "throttle-debounce";

interface Props {
    className: string;
    placeholder: string;
    name: string;
    value: string | number;
    changeValue: (name: string, value: string) => void;
    type: string;
    disabled?: boolean;
    required?: boolean;
    isIp?: boolean;
    isMac?: boolean;
    color?: string;
    isPort?: boolean;
    firstDiv?: boolean;
    min?: string;
    max?: string;
    isFilterWeb?: boolean;
    indexNetworks?: number;
    indexField?: number;
    indexPorts?: number;
    isOption?: boolean;
    ariaLabel?: string;
    isJustIp?: boolean;
    isUrl?: boolean;
    style?: StyleSheet;
}

interface State {
    name: string | number;
    q: string | number;
}

class TextField extends React.PureComponent<Props, State> {
    // eslint-disable-next-line @typescript-eslint/ban-types
    private autocompleteSearchDebounced: Function;

    constructor(props: any) {
        super(props);
        this.state = {
            name: "",
            q: "",
        };
        this.autocompleteSearchDebounced = debounce(500, this.autocompleteSearch);
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState((prevState) => ({ ...prevState, q: this.props.value }));
        }
    }
    //
    // changeValue = (value: string) => {
    //     this.setState(
    //         () => ({ name: this.props.name, q: value }),
    //         () => {
    //             this.autocompleteSearchDebounced(this.state.q);
    //         },
    //     );
    // }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.value !== this.props.value) {
            this.setState((prevState) => ({ ...prevState, q: this.props.value }));
        }
    }

    autocompleteSearch = (q: string) => {
        this.props.changeValue(this.state.name as string, q);
    };

    changeQuery = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState(
            () => ({ name: event.target.name, q: event.target.value }),
            () => {
                this.autocompleteSearchDebounced(this.state.q);
            }
        );
    };

    render() {
        const {
            className,
            placeholder,
            name,
            ariaLabel,
            type,
            disabled,
            required,
            isIp,
            isPort,
            isMac,
            min,
            max,
            firstDiv,
            isJustIp,
            isFilterWeb,
            indexNetworks,
            indexField,
            indexPorts,
            isOption,
            isUrl,
            style,
        } = this.props;
        let formProps: any = {};

        if (required) formProps["data-required"] = "true";
        if (isIp || isJustIp) formProps["data-isip"] = "true";
        if (isMac) formProps["data-ismac"] = "true";
        if (isPort) formProps["data-isport"] = "true";
        if (firstDiv) formProps["data-isfirst"] = "true";
        if (isFilterWeb) formProps["data-isfilterweb"] = "true";
        if (isUrl) formProps["data-isurl"] = "true";
        if (indexNetworks) formProps["data-index"] = indexNetworks;
        if (indexPorts) formProps["data-index"] = indexPorts;
        if (indexField) formProps["data-index-field"] = indexField;
        if (isOption) formProps["data-option"] = "true";

        return (
            <Input
                className={`${className} TextField`}
                type={type}
                name={name}
                placeholder={placeholder}
                value={this.state.q}
                onChange={this.changeQuery}
                disabled={disabled}
                aria-label={ariaLabel}
                style={{ color: this.props.color }}
                onClick={(event) => {
                    event.stopPropagation();
                }}
                min={min}
                max={max}
                {...formProps}
            />
        );
    }
}

export default TextField;
