import React from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/animated";
import { convertValueUnitBytes, convertValueUnitOctets } from "../../utils/Math";

am4core.useTheme(am4themes_animated);

interface Props {
    data: Array<{ from: string; to: string; value: number }>;
    unit: "octets" | "bytes";
}

class SankeyChart extends React.Component<Props> {
    componentDidMount() {
        this.initChart();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.data !== this.props.data || prevProps.unit !== this.props.unit) {
            if (this.props.data) {
                this.initChart();
            }
        }
    }

    initChart = () => {
        am4core.useTheme(am4themes_animated);
        am4core.useTheme(am4themes_material);

        const chart = am4core.create("chartdiv", am4charts.SankeyDiagram);
        chart.hiddenState.properties.opacity = 0;

        chart.data = this.props.data;
        let unit = this.props.unit;

        let hoverState = chart.links.template.states.create("hover");
        hoverState.properties.fillOpacity = 0.9;

        chart.dataFields.fromName = "from";
        chart.dataFields.toName = "to";
        chart.dataFields.value = "value";

        var nodeTemplate = chart.nodes.template;
        nodeTemplate.clickable = false;
        nodeTemplate.draggable = false;
        nodeTemplate.width = 150;
        nodeTemplate.minHeight = 15;
        nodeTemplate.height = am4core.percent(100);
        nodeTemplate.nameLabel.locationX = 0.2;
        nodeTemplate.nameLabel.label.fill = am4core.color("#fff");
        nodeTemplate.nameLabel.label.fontWeight = "bold";

        var linkTemplate = chart.links.template;
        linkTemplate.colorMode = "gradient";
        linkTemplate.adapter.add("tooltipText", function (text, target) {
            // return "{from} → {to}\nVolume: " + convertValueUnitOctets(target.dataItem.value);
            if (unit === "octets") {
                return "{from} → {to}\nVolume: " + convertValueUnitOctets(target.dataItem.value);
            } else {
                return "{from} → {to}\nVolume: " + convertValueUnitBytes(target.dataItem.value);
            }
            // return "{from} → {to}\nVolume: " + unit === "octets" ? convertValueUnitOctets(target.dataItem.value) : convertValueUnitBytes(target.dataItem.value);
        });
    };
    // componentWillUnmount() {
    //     if (chart) {
    //         chart.dispose();
    //     }
    // }

    render() {
        return <div id="chartdiv" style={{ width: "100%", height: "80vh" }}></div>;
    }
}

export default SankeyChart;
