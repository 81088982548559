import { Route, Switch } from 'react-router';
import Dashboard from '../views/Dashboard/Dashboard';
import Notifications from '../views/Notifications/Notifications';
import Equipements from '../views/Equipements/Equipements';
import Rapport from '../views/Rapport/Rapport';
import Labo from '../views/Labo/Labo';
import Securite from '../views/Securite/Securite';
import Parametres from "../views/Setting/Setting";
import AccesInternet from '../views/AccesInternet/AccesInternet';
import IconDashboard from '../common/SVGComponents/IconDashboard';
import IconNotifications from '../common/SVGComponents/IconNotifications';
import IconEquipements from '../common/SVGComponents/IconEquipements';
import IconRapport from '../common/SVGComponents/IconRapport';
import IconLabo from '../common/SVGComponents/IconLabo';
import IconSecurite from '../common/SVGComponents/IconSecurite';
import IconAccesInternet from '../common/SVGComponents/IconAccesInternet';
import IconParametre from "../common/SVGComponents/Settings";
export const routes = [
    {
        name: 'Dashboard',
        component: Dashboard,
        icon: <IconDashboard />,
    },
    {
        name: 'Equipements',
        component: Equipements,
        icon: <IconEquipements />,
    },
    {
        name: 'Notifications',
        component: Notifications,
        icon: <IconNotifications />,
    },
    {
        name: 'Rapport',
        component: Rapport,
        icon: <IconRapport />,
    },
    {
        name: 'Labo',
        component: Labo,
        icon: <IconLabo />,
    },
    {
        name: 'Securite',
        component: Securite,
        icon: <IconSecurite />,
    },
    {
        name: 'AccesInternet',
        component: AccesInternet,
        icon: <IconAccesInternet />,
    },
    {
        name: 'Parametres',
        component: Parametres,
        icon: <IconParametre />,
    }
];

function Routes() {
  return (
    <Switch>
      {routes.map((link) => (
        <Route
          key={link.name}
          exact
          path={`/${link.name}`}
          component={link.component}
        />
      ))}
    </Switch>
  );
}

export default Routes;
