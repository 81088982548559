import React from 'react';
import {EntityType, GatewayType, RoleType, RoutageType} from "../../../../../../types";
import SelectSimple from "../../../../../../common/SelectSimple/SelectSimple";
import { connect, ConnectedProps } from "react-redux";
import Collections from "../../../../../../utils/Collections";
import TextField from "../../../../../../common/TextField/TextField";
import RoutageStatus from "./RoutageStatus/RoutageStatus";
import './Routage.scss'
import {GlobalTypes} from "../../../../../../store/types";

interface ReduxState {
    collections: {
        Entity: Array<EntityType>,
    }
	global: GlobalTypes;
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
		entityId: state.global.entity_id,
    };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface State {
	filterRoute: any
}

interface Props extends PropsFromRedux {
    routage: RoutageType,
    index: number,
    changeValue: (path: string, value: string | Array<RoutageType>) => void,
    Gateways: Array<GatewayType> | undefined
    removeRoutage: (index: number) => void,
    role: any;
    equipment_id: String;
}

class Routage extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
		this.state = {
			filterRoute: []
		}
    }

    resetGatewaysChoice = (status: String) => {
        const { changeValue, index, routage } = this.props;
       
        let prevData: Array<any> = []
        if (status === "load_balancing") {
            if (routage.load_balancing.length > 0) {
                routage.load_balancing.map(elem => prevData.push(elem))
                let length = prevData.length
                let remove = prevData.splice(length - 1, 1)
                let newData = prevData.filter(elem => elem !== remove)
                changeValue(`Routage[${index}].load_balancing`, newData);
            }
        } else {
            changeValue(`Routage[${index}].autonomous`, []);
        }
    }

	componentDidUpdate(prevProps: { routage: RoutageType; }) {
		const {entities, entityId, index, routage} = this.props
		const GroupeNetwork = Collections.flatRecursive(entities, 'GroupeNetwork', [], '');

		if(prevProps.routage !== routage) {
			const GroupeNetworks = GroupeNetwork?.filter((element: any) => element._id !== routage.route_in && element._id !== routage.route_out)
			this.setState({filterRoute: GroupeNetworks})

		}
	}

	componentDidMount() {
		const {entities, routage} = this.props
		const GroupeNetwork = Collections.flatRecursive(entities, 'GroupeNetwork', [], '');
		const GroupeNetworks = GroupeNetwork?.filter((element: any) => element._id !== routage.route_in && element._id !== routage.route_out)
		this.setState({filterRoute: GroupeNetworks})
	}

	valueRoute(name: string) {
		const {entities, routage} = this.props
		const GroupeNetwork = Collections.flatRecursive(entities, 'GroupeNetwork', [], '');
		for(const Group of GroupeNetwork) {
			// @ts-ignore
			if(Group._id === routage[name]) {
				return Group.name
			}
		}
	}

    render() {
        const { routage, entities, changeValue, index, Gateways, role, equipment_id, entityId } = this.props;
		const entity = entities.find((entity) => entity._id === entityId);
        const Interfaces = Collections.flatRecursive(entities, 'Interface', [], '').filter(elem => elem.equipment_id === equipment_id);

        const key = window.location.pathname.substring(1)
        const rolesCondition = !!(role[0] && role[0][key].write === false);

        return (
            <div className={'ctn__fields Routage'}>
                <div>
                    <label>Interface entrée</label>
                    <div className={'ctn__select'}>
                        <SelectSimple
	                        items={Interfaces}
	                        placeholder={"Any"}
                            valuesDisplay={'name'}
	                        valuesName={'_id'}
                            value={routage.interface_in}
	                        name={`Routage[${index}].interface_in`}
                            changeValue={changeValue}
	                        disabled={rolesCondition}
                            dropdownName={'dropdown__equipment__form interface__entree__dropdown'}
                        />
                    </div>
                </div>
                <div>
                    <label>Interface de sortie</label>
                    <div className={'ctn__select'}>
                        <SelectSimple
	                        items={Interfaces}
	                        placeholder={"Any"}
                            valuesDisplay={'name'}
	                        valuesName={'_id'}
                            value={routage.interface_out}
	                        name={`Routage[${index}].interface_out`}
                            changeValue={changeValue}
	                        disabled={rolesCondition}
                            dropdownName={'dropdown__equipment__form interface__sortie__dropdown'}
                        />
                    </div>
                </div>
                <div>
                    <label>Plage IP source</label>
                    <SelectSimple
	                    items={this.state.filterRoute}
	                    placeholder={"Any"}
                        valuesDisplay={'name'}
	                    valuesName={'_id'}
                        value={this.valueRoute("route_in")} name={`Routage[${index}].route_in`}
                        changeValue={changeValue} disabled={rolesCondition}
                        dropdownName={'dropdown__equipment__form interface__sortie__dropdown'}
                    />
                </div>
                <div>
                    <label>Plage IP destination</label>
                    <SelectSimple
	                    items={this.state.filterRoute}
	                    placeholder={"Any"}
	                    valuesDisplay={'name'}
	                    valuesName={'_id'}
	                    value={this.valueRoute("route_out")}
	                    name={`Routage[${index}].route_out`}
	                    changeValue={changeValue} disabled={rolesCondition}
	                    dropdownName={'dropdown__equipment__form interface__sortie__dropdown'}
                    />
                </div>
                <RoutageStatus
	                routage={routage}
	                index={this.props.index}
	                changeValue={changeValue}
	                Gateways={Gateways || []}
                    role={role}
                />
                {
                    role[0][key].write === true && routage.status !== "failover" &&
                    <div className="routage-btn" style={{ justifyContent: "flex-end" }}>
                        <svg onClick={() => this.resetGatewaysChoice(routage.status)}
                            style={{ fill: '#417BF6', cursor: "pointer", marginTop: "1%" }}
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path
                                d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm3.222 18.917c5.666-5.905-.629-10.828-5.011-7.706l1.789 1.789h-6v-6l1.832 1.832c7.846-6.07 16.212 4.479 7.39 10.085z" />
                        </svg>
                    </div>
                }
            </div>
        );
    }
}

export default connector(Routage);
