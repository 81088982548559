import React from "react";
import ContainerRestPage from "../../../common/ContainerRestPage/ContainerRestPage";
import BasicTable from "../../../common/BasicTable/BasicTable";
import {EntityType, LogType, ServerLdap} from "../../../types";
import AddButton from "../../../common/AddButton/AddButton";
import BarTableActions from "../../../common/BarTableAction/BarTableActions";
import Socket from "../../../utils/Socket";
import Collections from "../../../utils/Collections";
import {DatePicker} from "antd";
import moment from "moment";
import {ExportToCsv} from "export-to-csv";

import { saveAs } from "file-saver";


const columns = [
    {
        Header: 'Boitier',
        accessor: 'equipment_name',
        sort: true
    },
    {
        Header: 'Catégorie',
        accessor: 'category_id',
        sort: true
    },
    {
        Header: 'Description',
        accessor: 'details',
        sort: true
    },
    {
        Header: 'Date de début',
        accessor: 'begin_date',
        sort: true,
        Cell: function(row: any) {
            if(row.original.begin_date) {
                const beginHours = row.original.begin_date.slice(11, 16)
                return <div>{new Date(row.original.begin_date).toLocaleDateString('fr-FR') + " " + beginHours}</div>
            } else {
                return <div></div>
            }
        }
    },
    {
        Header: 'Date de fin',
        accessor: 'end_date',
        sort: true,
        Cell: function(row: any) {
            if(row.original.end_date) {
                const endHours = row.original.end_date.slice(11, 16)
                return <div>{new Date(row.original.end_date).toLocaleDateString('fr-FR') + " " + endHours}</div>
            } else {
                return <div></div>
            }
        }
    },

]

interface Props {
    Logs: Array<LogType> | undefined,
    role: Array<any>,
    entityID: string;
    entities: EntityType[]
}

interface State {
    visibleForm: boolean,
    selectedItems: string[],
    formType: string,
    begin_date: any;
    end_date: any;
}

export default class Logs extends React.Component<Props, State> {


    constructor(props: any) {
        super(props);
        this.state = {
            visibleForm: false,
            selectedItems: [],
            formType: '',
            begin_date: '',
            end_date: '',
        };
    }

    componentDidMount = () => {
        document.title = 'Wanup | Notifications';
        const end_date = new Date();
        const begin_date = new Date(new Date().setMonth(new Date().getMonth() - 1));
        this.setState((prevState) => ({
            ...prevState,
            begin_date,
            end_date,
        }));
    };

    openForm = (formType: string, selectedItem?: string): void => {
        if (selectedItem) {
            this.setState(() => ({ visibleForm: true, formType, selectedItems: [selectedItem] }));
        } else {
            this.setState(() => ({ visibleForm: true, formType }));
        }
    };

    closeForm = (): void => {
        this.setState(() => ({ visibleForm: false, formType: '', selectedItems: [] }));
    };

    addElement = () => {
        this.openForm('create');
    };

    closeEdit = (): void => {
        this.setState((state) => ({
            ...state,
            selectedItems: [],
        }));
    };

    deleteItems = async (): Promise<void> => {
        const { selectedItems } = this.state;
        const { Logs } = this.props;
        if (Logs) {
            try {
                const ids = Logs.filter((n) => selectedItems.includes(n._id)).map((n) => n.entity_id);
                const res = await Socket.deleteMany('Logs', ids, selectedItems);
                if (res.status === 200) this.setState(() => ({ selectedItems: [] }));
            } catch (e) {
                console.error(e);
            }
        }
    };


    selectItems = (ids: Array<string>): void => {
        this.setState(() => ({ selectedItems: ids }));
    };

    refactorData = () =>  {

        const beginDate = new Date(new Date(this.state.begin_date).setHours(0o0, 0o0, 0o0));
        const endDate = new Date(new Date(this.state.end_date).setHours(23, 59, 0o0));
        const equipments = Collections.flatRecursive(this.props.entities, 'Equipment', [], '')
        const logs = this.props.Logs?.map(log => {
            const equipment = equipments.find(equipment => equipment._id === log.equipment_id);
            return {...log, equipment_name: equipment.name}
        })
        return logs?.filter(log => {
            const alertBeginDate = new Date(log.begin_date);
            const alertEndDate = new Date(log.end_date);
            return (alertBeginDate > beginDate && alertEndDate < endDate && alertBeginDate < endDate || alertBeginDate > beginDate && alertBeginDate < endDate)
        }) || [];
    }

    changeDateStart = (e: any) => {
        if (e && e._d) {
            this.setState(() => ({
                begin_date: new Date(e._d),
            }));
        }
    };

    changeDateEnd = (e: any) => {
        if (e && e._d) {
            this.setState(() => ({
                end_date: new Date(e._d),
            }));
        }
    };

    render() {
        const logs = this.refactorData();
        const {begin_date, end_date} = this.state;
        const options = {
            fieldSeparator: ",",
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            showTitle: true,
            title: "My Awesome CSV",
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
        };
        const csvExporter = new ExportToCsv(options);
        const txtExporter = new Blob([JSON.stringify(logs)], {
            type: "text/plain;charset=utf-8",
        });

        return (
            <div>
                {/*<AddButton str={'un utilsateurs'} onClick={this.addElement} role={this.props.role}/>*/}
               <div className={'actions__labo'}>
                <div className={'ctn-inputs-date'}>
                    {begin_date && (
                        <div className={'ctn-input-date'}>
                            <label> Date de début</label>
                            <DatePicker
                                defaultValue={moment(begin_date, 'DD-MM-YYYY')}
                                onChange={this.changeDateStart}
                            />
                        </div>
                    )}
                    {end_date && (
                        <div className={'ctn-input-date'}>
                            <label> Date de fin</label>
                            <DatePicker
                                defaultValue={moment(end_date, 'DD-MM-YYYY')}
                                onChange={this.changeDateEnd}
                            />
                        </div>
                    )}
                </div>
                   <div className={'export__logs__btns'}>
                <button
                    className="btn_export_csv"
                    onClick={() => csvExporter.generateCsv(logs)}
                >
                    Export CSV
                </button>
                <button
                    className="btn_export_txt"
                    onClick={() => saveAs(txtExporter, "logsData.txt")}
                >
                    Export txt
                </button>
                   </div>
               </div>
                <ContainerRestPage>
                    <BasicTable openForm={this.openForm}  columns={columns} data={logs} tableName={'logs__table'} />
                </ContainerRestPage>
                <BarTableActions
                    selectedItems={this.state.selectedItems}
                    openForm={this.openForm}
                    deleteItems={this.deleteItems}
                    closeBar={this.closeEdit}
                    role={this.props.role}
                />
                {/*<UserForm*/}
                {/*    ServerLdap={this.props.Logs}*/}
                {/*    entityID={this.props.entityID}*/}
                {/*    selectedItems={this.state.selectedItems}*/}
                {/*    onClose={this.closeForm}*/}
                {/*    visible={this.state.visibleForm}*/}
                {/*    formType={this.state.formType}*/}
                {/*/>*/}
            </div>
        )
    }
}