import React from "react";
import TextField from "../TextField/TextField";
import { Button, Dropdown, Switch, Tooltip } from "antd";
import { GatewayType, InterfaceType, RoutageType } from "../../types";
import Icon3Points from "../SVGComponents/Icon3points";
import { ExclamationCircleTwoTone } from "@ant-design/icons";

interface Props {
    changeValue: (name: string, value: string | boolean) => void;
    data: InterfaceType | GatewayType | RoutageType;
    dataPathName: string;
    checkPathName: string;
    placeholder: string;
    remove: (index: number) => void;
    index: number;
    role: any;
    deleteType: string;
    firstDiv?: boolean;
    disabledRemove?: boolean;
    disabled?: boolean;
    textGateway?: any;
}

interface State {
    edit: boolean;
    visible: boolean;
}

class InputNameSwitch extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            edit: false,
            visible: false,
        };
    }

    actionsList = () => (
        <div className={"dropdown__inputNameSwitch"}>
            {!this.props.disabledRemove && (
                <button onClick={() => this.props.remove(this.props.index)}>Supprimer {this.props.deleteType}</button>
            )}
        </div>
    );

    handleVisibility = () => {
        this.setState((prevState) => ({ visible: !prevState.visible }));
    };

    handleVisibleChange = (flag: any) => {
        this.setState({ visible: flag });
    };

    render() {
        const { changeValue, data, dataPathName, placeholder, checkPathName, role, disabled, textGateway, index } =
            this.props;
        const { edit, visible } = this.state;
        const key = window.location.pathname.substring(1);
        const rolesCondition = !!(role[0] && role[0][key].write === false);
        return (
            <div className={"panelHeader InputNameSwitch"}>
                <TextField
                    // disabled={!edit}
                    className={""}
                    placeholder={placeholder}
                    name={dataPathName}
                    value={data.name}
                    changeValue={changeValue}
                    type={"text"}
                    required={true}
                    firstDiv={true}
                    disabled={rolesCondition}
                />
                <div
                    onClick={(event) => {
                        event.stopPropagation();
                    }}
                    style={{ display: "flex", justifyContent: "space-between" }}
                >
                    <Switch
                        // style={{ backgroundColor: data.state === "down" && data.enabled ? "#ff304a" : data.enabled ? "#417bf6" : ""}}
                        onChange={(checked) => changeValue(checkPathName, checked)}
                        checked={data.enabled}
                        defaultChecked={data.enabled}
                        disabled={rolesCondition ? rolesCondition : disabled}
                    />
                    <Dropdown
                        overlay={this.actionsList}
                        placement={"bottomRight"}
                        className="DropdownCollapse"
                        open={visible}
                        onOpenChange={this.handleVisibleChange}
                    >
                        <Button htmlType={"button"} onClick={this.handleVisibility}>
                            {role[0][key].write === true && !this.props.disabledRemove ? (
                                <Icon3Points className={"threepoints"} />
                            ) : (
                                //@ts-ignore
                                data &&
                                data.type &&
                                data.type !== "physical" && (
                                    <Tooltip title={`Interface liée à la passerelle ${textGateway}`} placement="left">
                                        <ExclamationCircleTwoTone
                                            style={{ fontSize: "2rem", height: "0.5rem" }}
                                            rev={undefined}
                                        />
                                    </Tooltip>
                                )
                            )}
                        </Button>
                    </Dropdown>
                    {/* @ts-ignore  */}
                    {data && data.type === "physical" && (
                        <p
                            // className={
                            //     data.state === "down" && data.enabled ? "numberInterface blink" : "numberInterface"
                            // }
                            className="numberInterface"
                            style={{
                                width: "3rem",
                                height: "3rem",
                                border:
                                    data.state === "down" && data.enabled
                                        ? " 1.5px solid #ff304a"
                                        : "1.5px solid #E6F0FE",
                                backgroundColor: data.state === "down" && data.enabled ? "#ff304a" : "#e6f0fe",
                                borderRadius: "50%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: data.state === "down" && data.enabled ? "white" : "#417bf6",
                            }}
                        >
                            {/* @ts-ignore */}
                            {data && data.type === "physical" && index + 1}
                        </p>
                    )}
                    {/* <span  
                        style={{
                            visibility:
                                role[0][key].write === true && !this.props.disabledRemove ? "visible" : "hidden",
                        }}
                    > */}
                    {/* </span> */}
                </div>
            </div>
        );
    }
}

export default InputNameSwitch;
