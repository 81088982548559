import React from "react";
import { EntityType, GroupNetworkType, GroupPortType } from "../../../types";
import Collections from "../../../utils/Collections";
import GroupNetworks from "./GroupNetworks/GroupNetworks";
import { Button, notification } from "antd";
import Socket from "../../../utils/Socket";
import { each } from "lodash";
import { isIP } from "is-ip";
import isCidr from "is-cidr";

interface Props {
    Entity: EntityType[];
    entityID: string;
}

interface State {
    originalGroups: GroupNetworkType[];
    groupNetworks: GroupNetworkType[];
    errorMessage: string;
}

const openNotificationWithIcon = (type: string) => {
    //@ts-ignore
    notification[type]({
        style: { color: "green", marginTop: "12rem", marginRight: '3rem'  },
        duration: 3,
        description: "Les modifications ont été prises en compte",
        //   description:
        //     'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    });
};

export default class NetworksObjects extends React.Component<Props, State> {
    private formRef: HTMLDivElement | null | undefined;

    constructor(props: Props | Readonly<Props>) {
        super(props);
        this.state = {
            originalGroups: [],
            groupNetworks: [],
            errorMessage: "",
        };
    }

    componentDidMount() {
        const groupNetworks = Collections.flatRecursive(this.props.Entity, "GroupeNetwork", [], "");
        this.setState(() => ({
            originalGroups: groupNetworks,
            groupNetworks,
        }));
    }

    changeGroupeValue = (type: "groupNetworks" | "groupPorts", data: Array<GroupPortType | GroupNetworkType>) => {
        // @ts-ignore
        this.setState(() => ({ groupNetworks: data }));
    };

    closeEdit = (): void => {
        this.setState((state) => ({
            ...state,
            selectedSource: "",
        }));
    };

    validateIpAddress = (ipaddress: any, type: string) => {
        return (type === "ip" && isIP(ipaddress)) || isCidr(ipaddress) !== 0;
    };

    checkRequiredError = () => {
        let error = false;
        let isIpValid: boolean;

        each(this.formRef?.querySelectorAll("[data-required]"), (element) => {
            // @ts-ignore
            const value = element.value ? element.value : element.getAttribute("data-value");
            const isIp = element.getAttribute("data-isip");

            if (isIp) isIpValid = this.validateIpAddress(value, "ip");

            if (!value || (isIp && !isIpValid)) {
                // element.classList.add('error__field__form');
                error = true;
            }
        });
        each(this.formRef?.querySelectorAll("[data-isip]"), (element) => {
            // @ts-ignore
            isIpValid = this.validateIpAddress(element.value, "ip");
            // @ts-ignore
            if (element.value && !isIpValid) {
                // element.classList.add('error__field__form');
                error = true;
            } else {
                element.classList.remove("error__field__form");
            }
        });
        return error;
    };

    saveGroups = () => {
        if (this.checkRequiredError()) {
            this.setState(() => ({ errorMessage: "Des champs semblent incorrects ou manquants." }));
        } else {
            Socket.updateGroups(
                "GroupeNetwork",
                this.state.originalGroups,
                this.state.groupNetworks,
                this.props.entityID
            )
                .then((res) => {
					console.log(res);
                    openNotificationWithIcon("success");
                    this.closeEdit();
                })
                .catch((err) => console.error(err));
        }
    };

    render() {
        console.log(this.state.groupNetworks);

        return (
            <>
                <div className={"objects"}>
                    <GroupNetworks
                        groupNetworks={this.state.groupNetworks.filter(
                            (element) => element.entity_id === this.props.entityID
                        )}
                        changeGroupeValue={this.changeGroupeValue}
                    />
                </div>
                <Button className={"submit__group"} onClick={this.saveGroups}>
                    Enregistrer
                </Button>
            </>
        );
    }
}
