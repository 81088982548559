import React from "react";
import { ArpType, InterfaceType } from "../../../../../../../types";
import TextField from "../../../../../../../common/TextField/TextField";
import "./Arp.scss";

interface Props {
    interf: InterfaceType;
    arp: Array<ArpType> | undefined;
    interfaceIndex: number;
    changeValue: (path: string, value: string | boolean) => void;
    role: any;
}

class Relais extends React.Component<Props> {
    render() {
        const { arp, interfaceIndex, changeValue } = this.props;
        // const key = window.location.pathname.substring(1)
        // const rolesCondition = role[0] && role[0][key].write === false ? true : false;
        return (
            <div>
                <div className={"Arp ctn__fields"}>
                    <div>
                        <h3>Adresse IP</h3>
                        <h3>Adresse MAC</h3>
                    </div>
                    <br />
                    {arp && arp?.length > 0 && arp?.map((elem) => (
                        <div>
                            <p>{elem.ip}</p>
                            <p>{elem.mac}</p>
                        </div>
                    ))}
                    <br />
                </div>
            </div>
        );
    }
}

export default Relais;
