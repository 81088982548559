import React from 'react';
import {ServerLdap} from "../../../../types";
import {ServerLdapState} from "../../../../state";
import BasicDrawerForm from "../../../../common/BasicDrawerForm/BasicDrawerForm";
import TextField from "../../../../common/TextField/TextField";
import {ifNameExist} from "../../../../utils/CheckError";
import {each} from "lodash";
import {isIP} from "is-ip";
import isCidr from "is-cidr";
import Socket from "../../../../utils/Socket";
import {Alert, Switch} from "antd";
import Loader from "../../../../common/Loader/Loader";


interface Props {
    ServerLdap: ServerLdap[] | undefined
    entityID: string
    selectedItems: string[]
    onClose: () => void
    visible: boolean
    formType: string
}


interface State {
    serverLdap: ServerLdap
    errorMessage: string
	loader: boolean
}

export default class LDAPForm extends React.Component<Props, State> {

    private formRef: HTMLDivElement | null

    constructor(props: any) {
        super(props);
        this.state = {
            serverLdap: ServerLdapState,
            errorMessage: '',
	        loader: false
        }
        this.formRef = null;
    }

    create = () => {
        Socket.insert('Server_Ldap', this.state.serverLdap.entity_id, this.state.serverLdap)
            .then(() => {
                this.props.onClose();
	            this.setState(() => ({loader:false}))
            })
            .catch((error) => console.error(error));
    };

    update = () => {
        Socket.update('Server_Ldap', this.state.serverLdap._id, this.state.serverLdap.entity_id, this.state.serverLdap)
            .then(() => {
                this.props.onClose();
	            this.setState(() => ({loader:false}))
            })
            .catch((error) => console.error(error));
    };

    duplicate = () => {
        this.setState(state => ({...state, serverLdap: {...state.serverLdap, '_id': ''}}), () => {

            this.create();
        })
    };

    validateIpAddress = (ipaddress: any, type: string) => {
        return type === "ip" && isIP(ipaddress) || isCidr(ipaddress) !== 0;
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.selectedItems !== this.props.selectedItems) {
            if (this.props.selectedItems.length > 0 && this.props.ServerLdap && this.props.ServerLdap.length > 0) {
                const serverLdapFind = this.props.ServerLdap.find((serverLdap) => this.props.selectedItems[0] === serverLdap._id);
                if (serverLdapFind) {
                    this.setState((state) => ({...state, serverLdap: serverLdapFind}));
                }
            } else {
                this.setState((state) => ({...state, serverLdap: ServerLdapState}));
            }
        }
        if (prevProps.formType !== this.props.formType && this.props.formType === 'create') {
            this.setState(() => ({serverLdap: {...ServerLdapState, entity_id: this.props.entityID}}));
        }
        if (prevProps.visible !== this.props.visible) {
            if (!this.props.visible) {
                this.setState(() => ({errorMessage: ''}));
            }
        }
    }


    changeValue = (name: string, value: string | boolean) => {
        this.setState(state => ({...state, serverLdap: {...state.serverLdap, [name]: value}}))
    }

    checkRequiredError = () => {
        let error = false;
        let isIpValid: boolean;

        each(this.formRef?.querySelectorAll('[data-required]'), (element) => {
            // @ts-ignore
            const value = element.value ? element.value : element.getAttribute('data-value');
            const isIp = element.getAttribute("data-isip")

            if (isIp) isIpValid = this.validateIpAddress(value, "ip");


            if (!value || isIp && !isIpValid) {
                element.classList.add('error__field__form');
                error = true;
            } else {
                element.classList.remove('error__field__form');
            }
        });
        each(this.formRef?.querySelectorAll('[data-isip]'), (element) => {
            // @ts-ignore
            isIpValid = this.validateIpAddress(element.value, "ip");
            // @ts-ignore
            if (element.value && !isIpValid) {
                element.classList.add('error__field__form');
                error = true;
            }
        })
        return error;
    };

    submit = () => {
        if (ifNameExist(this.props.formType, this.state.serverLdap, this.props.ServerLdap)) {
            this.setState(() => ({errorMessage: 'Nom Déjà existant'}));
        } else if (this.checkRequiredError()) {
            this.setState(() => ({errorMessage: 'Des champs semblent incorrects ou manquants.'}));

            // this.setState(() => ({ errorMessage: 'Des champs semblent incorrects ou manquants.' }));
        } else {
            // @ts-ignore
            this[this.props.formType]();
	        this.setState(() => ({loader:true}))
        }
    }

    render() {
        return (
            <BasicDrawerForm className={`ipsec__form ipsec__form__${this.props.formType}`} onClose={this.props.onClose}
                             submit={this.submit} visible={this.props.visible} header={<p>LDAP</p>}
                             data={this.state.serverLdap} formType={this.props.formType}
            loader={this.state.loader}>
	            {!this.state.loader ?
		            <>
                <div ref={(form) => (this.formRef = form)}>
                    <div className={'ipsec__form__row'}>
                        <label>Nom *</label>
                        <TextField className={''} placeholder={'Nom'} name={'name'} value={this.state.serverLdap.name}
                                   changeValue={this.changeValue} type={'text'} required={true} disabled={this.props.formType === 'view'}/>

                    </div>
                    <div className={'ipsec__form__row'}>
                        <label>Adresse IP Serveur</label>
                        <TextField isIp={true} className={''} placeholder={'Entrez une adresse IP serveur'} name={'ip'}
                                   value={this.state.serverLdap.ip} changeValue={this.changeValue}
                                   type={'text'} disabled={this.props.formType === 'view'}/>
                    </div>
                    <div className={'ipsec__form__row'}>
                        <label>Port Serveur</label>
                        <TextField className={''} placeholder={'Entrez un port serveur'} name={'port'}
                                   value={this.state.serverLdap.port} changeValue={this.changeValue}
                                   type={'text'} disabled={this.props.formType === 'view'}/>
                    </div>
                    <div className={'ipsec__form__row'}>
                        <label>Common Name Identifier</label>
                        <TextField className={''} placeholder={'Entrez un common name identifier'} name={'common_name_identifier'}
                                   value={this.state.serverLdap.common_name_identifier} changeValue={this.changeValue}
                                   type={'text'} disabled={this.props.formType === 'view'}/>
                    </div>
                    <div className={'ipsec__form__row'}>
                        <label>Distinguished Name</label>
                        <TextField className={''} placeholder={'Entrez un distinguished identifier'} name={'distinguished_name'}
                                   value={this.state.serverLdap.distinguished_name} changeValue={this.changeValue}
                                   type={'text'} disabled={this.props.formType === 'view'}/>
                    </div>
                    <div className={'ipsec__form__row'}>
                        <label>Login</label>
                        <TextField className={''} placeholder={'Entrez un login'} name={'login'}
                                   value={this.state.serverLdap.login} changeValue={this.changeValue} type={'text'} disabled={this.props.formType === 'view'}/>
                    </div>
                    <div className={'ipsec__form__row'}>
                        <label>Mot de passe</label>
                        <TextField className={''} placeholder={'Entrez un mot de passe'} name={'password'}
                                   value={this.state.serverLdap.password} changeValue={this.changeValue}
                                   type={'password'} disabled={this.props.formType === 'view'}/>
                    </div>
                    <div className={'ipsec__form__row'}>
                        <label>Connexion sécurisée</label>
                        <Switch
                            onChange={(checked) => this.changeValue('secure_connexion', checked)}
                            checked={this.state.serverLdap.secure_connexion}
                            defaultChecked={this.state.serverLdap.secure_connexion} disabled={this.props.formType === 'view'}/>

                    </div>
                    {this.state.errorMessage && (
                        <Alert className={'alert__error__fields'} message={this.state.errorMessage} type="error"/>
                    )}
                </div>
		            </>
		            :
		            <div className={"contain-loader"}>
			            <Loader />
		            </div>
	            }
            </BasicDrawerForm>
        )
    }
}