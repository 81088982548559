import React from 'react';
import { Select } from 'antd';
import { nanoid } from 'nanoid';
import { matchSorter } from 'match-sorter';

const { Option } = Select;

interface Props {
	name?: string;
	value: Array<string> | undefined;
	className?: string;
	placeholder?: string;
	items: Array<any>;
	valuesName?: string;
	valuesDisplay?: string;
	changeValue?: any;
	disabled?: boolean;
	dropdownName?: string,
	ariaLabel?: string,
	orderAlphabetique?: string
}

class SelectMultiple extends React.PureComponent<Props> {
	onChange = (value: Array<string>) => {
		const { name } = this.props;
		if (name) {
			this.props.changeValue(name, value);
		}
		else {
			this.props.changeValue(value);
		}
	};


	render() {
		const { items, value, className, placeholder, valuesName, valuesDisplay, disabled, dropdownName, ariaLabel, orderAlphabetique } = this.props;
		return (

			<Select
				// defaultOpen={true}
				dropdownClassName={'SelectMultiple__dropdown ' + dropdownName}
				className={`${className ? className : ''} SelectMultiple`}
				mode="multiple"
				allowClear
				placeholder={placeholder}
				value={value}
				onChange={this.onChange}
				maxTagCount={!disabled ? 1 : undefined}
				disabled={disabled}
				filterOption={(input, option) => {
					return matchSorter([option], input, { keys: ['children'] }).length > 0;
				}}
				filterSort={(optionA, optionB) => {
					if (orderAlphabetique !== "decroissant" && optionA && optionA.children && optionB && optionB.children) {
						if(optionA.children[0] && optionB.children[0]) {
							return optionA.children[0].toLowerCase().localeCompare(optionB.children[0].toLowerCase())
						}
					}
				}}
				aria-label={ariaLabel}
			>
				{items.map((item) => {
					if (valuesName && valuesDisplay) {
						return (
							<Option key={nanoid()} value={item[valuesName]}>
								{item[valuesDisplay]}
							</Option>
						);
					}

					else {
						return (
							<Option key={nanoid()} value={item}>
								{item}
							</Option>
						);
					}
				})}
			</Select>
		);
	}
}

export default SelectMultiple;

