import React from "react";
import Firewall from "../../components/Securite/Firewall/Firewall";
import FiltrageWeb from "../../components/Securite/FiltrageWeb/FiltrageWeb";
import { GlobalTypes } from "../../store/types";
import { EntityType } from "../../types";
import { connect, ConnectedProps } from "react-redux";
import Collections from "../../utils/Collections";

import Fade from "../../common/Fade/Fade";
import { Tabs } from "antd";
import { tooltipInfo } from "../../utils/Tab";
import SmoothScrolling from "../../common/SmoothScrolling/SmoothScrolling";

const { TabPane } = Tabs;

interface ReduxState {
  collections: {
    Entity: Array<EntityType>;
  };
  global: GlobalTypes;
}

const mapStateToProps = (state: ReduxState) => {
  return {
    entities: state.collections.Entity,
    entity_id: state.global.entity_id,
    roles: state.global.roles,
  };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class Securite extends React.Component<PropsFromRedux> {
  componentDidMount() {
    document.title = "Wanup | Sécurité";
  }

  render() {
    const { entities, entity_id, roles } = this.props;
    const fireWall = Collections.flatRecursive(entities, "FireWall", [], "");


    return (
      // <SmoothScrolling>
      <div className={"Securite ContainerMainPageTabs"}>
        <Fade>
          <Tabs className={"tabs"} destroyInactiveTabPane={true}>
            <TabPane key={"Firewall"} tab={tooltipInfo("Firewall")}>
              <Firewall firewall={fireWall} entityId={entity_id} role={roles} />
            </TabPane>
            {/* <TabPane key={'Filtrage web'} tab={tooltipInfo('Filtrage web')}>
                            <FiltrageWeb
                                equipments={equipments}
                                filtrageWeb={filtrageWeb}
                                entityId={entity_id}
                                role={roles}
                            />
                        </TabPane> */}
          </Tabs>
        </Fade>
      </div>
      // </SmoothScrolling>
    );
  }
}

export default connector(Securite);
