

import React from 'react';
import {MenuOutlined} from '@ant-design/icons';
import {Switch, Table} from 'antd';
import {arrayMoveImmutable} from 'array-move';

import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';
import {FireWallType, RuleType} from "../../../../../types";
import Socket from "../../../../../utils/Socket";

const DragHandle = SortableHandle(() => (
    <MenuOutlined
        style={{
            cursor: 'grab',
            color: '#999',
        }} rev={undefined}    />
));

const SortableItem = SortableElement((props: any) => <tr {...props} />);
const SortableBody = SortableContainer((props: any) => <tbody {...props} />);

interface Props {
    firewall: FireWallType | undefined,
    selectedItems: Array<string>;
    selectItems: any;
    selectAllItems: any;
    isUpdate: (bool: boolean) => void
    isUpdateState: boolean,
    rules: RuleType[]
}

interface State {
    dataSource: RuleType[]
}

export default class RegleTable extends React.Component<Props, State>{
    constructor(props: any) {
        super(props)
        this.state = {
            dataSource: []
        }
    }

    initData() {
        if (this.props.firewall && this.props.firewall.Rules) {
            const rules = this.props.firewall.Rules;
            const dataSource = rules && rules.length > 0 ? rules.sort((a, b) => a.index - b.index) : [];
            this.setState(() => ({dataSource}))
            this.props.isUpdate(false);
        }
    }

    componentDidMount() {
        this.initData()
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(
            (prevProps.rules !== this.props.rules &&
            this.props.isUpdateState) ||
            prevProps.firewall?._id !== this.props.firewall?._id ||
            prevProps.firewall?.Rules?.length !== this.props.firewall?.Rules?.length
        ) {
                this.initData()
        }
    }

    updateOrderRulesBDD(newData: RuleType[]) {
        const tab = newData.map((element, i) => {
            Object.assign(element, {
                firewall_uuid: element.firewall_uuid,
                entity_id: element.entity_uuid
            });
            element.index = i;
            // return {...element, firewall_uuid: element.firewall_uuid,
            //     entity_id: element.entity_uuid, index: i};
            return element
        })
        for (const property in tab) {
            Socket.update("Rules", tab[property]._id, tab[property].entity_uuid, tab[property])
                .then(res => console.info(res))
                .catch(error => console.error(error))
        }
    }

    onSortEnd = ({oldIndex, newIndex}: any) => {
        if (oldIndex !== newIndex) {
            const newData = arrayMoveImmutable(this.state.dataSource.slice(), oldIndex, newIndex).filter(
                (el) => !!el,
            );

            this.updateOrderRulesBDD(newData)
            this.setState(() => ({dataSource: newData}))
        }
    };

    DraggableContainer = (props: any) => (
        <SortableBody
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={this.onSortEnd}
            {...props}
        />
    );

    DraggableBodyRow = ({className, style, ...restProps}: any) => {
        // function findIndex base on Table rowKey props and should always be a right array index
        const index = this.state.dataSource.findIndex((x) => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    render() {

        console.log("REGLES SIMON",this.state.dataSource);

        const columns = [
            {
                title: '',
                dataIndex: 'sort',
                width: 30,
                className: 'drag-visible',
                render: () => <DragHandle/>,
            },
            {
                title: (
                    <input
                        onChange={() => this.props.selectAllItems(this.state.dataSource)}
                        type="checkbox"
                        className="choice-all-ipsec"
                        checked={this.props.selectedItems.length === this.state.dataSource.length}
                    />
                ),
                className: 'drag-visible',
                render: (row: { _id: string }) => (
                    <div style={{display: 'flex', alignItems: 'baseline'}}>
                        <i className="fas fa-sort-amount-up-alt"/>
                        <input
                            type="checkbox"
                            className="choice-table-ipsec"
                            checked={!!this.props.selectedItems.find((i) => i === row._id)}
                            onChange={(e) => this.props.selectItems(e, row, this.state.dataSource)}
                        />
                    </div>
                ),
            },
            {
                title: 'État',
                dataIndex: 'enabled',
                // className: 'drag-visible',
                render: (row: boolean) => {
                    return <Switch checked={row} disabled/>
                }
            },
            {
                title: "Interface d'entrée",
                dataIndex: 'interface_in',
            },
            {
                title: 'Interface de sortie',
                dataIndex: 'interface_out',
            },
            {
                title: 'IP source',
                dataIndex: 'source',
            },
            {
                title: 'Port source',
                dataIndex: 'sourcePort',
            },
            {
                title: 'IP destination',
                dataIndex: 'destination',
            },
            {
                title: 'Port destination',
                dataIndex: 'destinationPort',
            },
            {
                title: 'Protocole',
                dataIndex: 'protocols',
            },
            {
                title: 'Action',
                dataIndex: 'action',
            },
            {
                title: 'IP Nat',
                dataIndex: 'nat',
            },
            {
                title: 'Port Nat',
                dataIndex: 'natPort',
            },
            {
                title: 'Commentaires',
                dataIndex: 'comments',
            },
        ];


        return (
            <Table
                pagination={false}
                dataSource={this.state.dataSource}
                columns={columns}
                rowKey="index"
                components={{
                    body: {
                        wrapper: this.DraggableContainer,
                        row: this.DraggableBodyRow,
                    },
                }}
            />
        )
    }
}