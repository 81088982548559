import React from "react";
import { EntityType, GatewayType, InterfaceType } from "../../../../../../types";
import TextField from "../../../../../../common/TextField/TextField";
import InputNumber from "../../../../../../common/InputNumber/InputNumber";
import SelectSimple from "../../../../../../common/SelectSimple/SelectSimple";
import { connect, ConnectedProps } from "react-redux";
import Collections from "../../../../../../utils/Collections";
import { interfaceState } from "../../../../../../state";

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
    };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    gateway: GatewayType;
    gateways: Array<GatewayType>;
    gatewayIndex: number;
    changeValue: (path: string, value: string | number | boolean | Array<GatewayType>) => void;
    role: any;
    equipment_id: string;
    interfaces: InterfaceType[];
}

class Gateway extends React.Component<Props> {
    getSelectedInterface = (interfaceID: string) => {
        const interf = this.props.interfaces.find((interf) => interf._id === interfaceID);
        return interf ? interf : interfaceState;
    };

    selectInterface = (path: string, value: string) => {
        this.props.changeValue(`Gateways[${this.props.gatewayIndex}].interface_id`, value);
        this.props.changeValue(
            `Gateways[${this.props.gatewayIndex}].interface_name`,
            this.getSelectedInterface(value).name
        );
    };

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.interfaces !== this.props.interfaces) {
            const interf = this.getSelectedInterface(this.props.gateway.interface_id);
            this.props.changeValue(
                `Gateways[${this.props.gatewayIndex}].interface_name`,
                this.getSelectedInterface(interf._id).name
            );
        }
    }

    // getFilteredInterfaces = (gateways: Array<GatewayType>) => {
    //     const { interfaces } = this.props;

    //     const assignedInterfaces: any = gateways.map((gateway) => gateway.interface_id);

    //     const filteredInterfaces = interfaces.filter((item) => !assignedInterfaces.includes(item._id));

    //     return filteredInterfaces;
    // };

    getFilteredInterfaces = (gateways: Array<GatewayType>, currentGateway: GatewayType) => {
        const { interfaces } = this.props;

        // Find all interface_id already assigned in gateways (excluding the current gateway) and store them in an array
        const assignedInterfaceIds = gateways
            .filter((gateway) => gateway.interface_id !== currentGateway.interface_id)
            .map((gateway) => gateway.interface_id);

        // Filter interfaces, remove interfaces already assigned
        const filteredInterfaces = interfaces.filter((item) => !assignedInterfaceIds.includes(item._id));

        return filteredInterfaces;
    };

    render() {
        const { entities, gateway, gatewayIndex, changeValue, role, equipment_id, interfaces, gateways } = this.props;
        const key = window.location.pathname.substring(1);
        const rolesCondition = !!(role[0] && role[0][key].write === false);
        const interfacesFiltered = this.getFilteredInterfaces(gateways, gateway);
        return (
            <div className={"Gateway ctn__fields"}>
                <div>
                    <label>Adresse IP</label>
                    <TextField
                        className={""}
                        placeholder={"100.127.255.1"}
                        name={`Gateways[${gatewayIndex}].address`}
                        value={gateway.address}
                        changeValue={changeValue}
                        type={"text"}
                        isIp={true}
                        required={gateway.enabled}
                        disabled={rolesCondition}
                    />
                </div>
                <div>
                    <label>Adresse IP cible</label>
                    <TextField
                        className={""}
                        placeholder={"8.8.8.8"}
                        name={`Gateways[${gatewayIndex}].target_address`}
                        value={gateway.target_address}
                        changeValue={changeValue}
                        type={"text"}
                        required={gateway.enabled}
                        isIp={true}
                        disabled={rolesCondition}
                    />
                </div>
                <div>
                    <label>Latence max (ms)</label>
                    <InputNumber
                        className={""}
                        placeholder={"300"}
                        name={`Gateways[${gatewayIndex}].latency`}
                        value={gateway.latency}
                        required={gateway.enabled}
                        changeValue={changeValue}
                        type={"number"}
                        min={0}
                        max={1000}
                        disabled={rolesCondition}
                    />
                </div>
                <div>
                    <label>Pertes de paquets (%)</label>
                    <InputNumber
                        className={""}
                        placeholder={"20"}
                        name={`Gateways[${gatewayIndex}].packet_loss`}
                        value={gateway.packet_loss}
                        required={gateway.enabled}
                        changeValue={changeValue}
                        type={"number"}
                        min={0}
                        max={100}
                        disabled={rolesCondition}
                    />
                </div>
                <div>
                    <label>Interface</label>
                    <div className={"ctn__select"}>
                        <SelectSimple
                            items={interfaces}
                            dropdownName={"dropdown__equipment__form"}
                            valuesDisplay={"name"}
                            valuesName={"_id"}
                            required={gateway.enabled}
                            value={gateway.interface_id}
                            name={`Gateways[${gatewayIndex}].interface_id`}
                            changeValue={this.selectInterface}
                            placeholder={"Sélectionnez une interface"}
                            disabled={rolesCondition}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default connector(Gateway);
