import React from 'react';
import { DashboardType } from '../../../../types';
import Icon3Points from '../../../../common/SVGComponents/Icon3points';
import { Dropdown } from 'antd';
import HorizontallyChart from '../../../../common/HorizontallyChart/HorizontallyChart';

interface Props {
    Dashboard: DashboardType | undefined,
}

interface State {
    dataDuration: 'day' | 'week' | 'month'
}

class FiltrageWeb extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            dataDuration: "day"
        }
    }
    
    changeDuration = (value: 'day' | 'month' | 'week') => {
        this.setState(() => ({ dataDuration: value }));
    };
    
    durationList = () => (
        <div className={'duration__list'}>
            <button onClick={() => this.changeDuration('day')}>Jour</button>
            <button onClick={() => this.changeDuration('week')}>Semaine</button>
            <button onClick={() => this.changeDuration('month')}>Mois</button>
        </div>
    );
    
    render() {
        const { Dashboard } = this.props;
        const { dataDuration } = this.state;
        const filtre = dataDuration === "day" ? "Jour" : dataDuration === "month" ? "Mois" : "Semaine"

        return (
            <div className={`HorizontallyChart`}>
                <h3>Filtrage Web / {filtre}</h3>
                <Dropdown  overlayClassName={'trf__dropdown'} overlay={this.durationList()} placement="bottomRight" arrow>
                    <button className={'btn__class__dropdown'}>
                        <Icon3Points />
                    </button>
                </Dropdown>
                <div className="ctn__chart">
                    <HorizontallyChart name={"horizontallyChart"}
                    data={Dashboard ? Dashboard[`url_${dataDuration}`] : [{url: 'data not found', count: 0}]} />
                </div>
            </div>
            
        );
    }
}

export default (FiltrageWeb);
