import React from "react";
import Socket from "../../../../utils/Socket";
import { TrafficStatsDetails } from "../../../../types";
import BasicTable from "../../../../common/BasicTable/BasicTable";
import { ExportToCsv } from "export-to-csv";
import loaderGif from "../../../../assets/LOADER-WANUP_2.gif";
import PieChart from "../../../../common/PieChart/PieChart";
import SankeyChart from "../../../../common/Sankey/Sankey";

const columns = [
    {
        Header: "Début de session",
        accessor: "begin_session",
        Cell: function (row: any) {
            if (row.original.begin_session) {
                const beginHours = new Date(row.original.begin_session).toLocaleTimeString();
                return (
                    <div>
                        {new Date(row.original.begin_session).toLocaleDateString("fr-FR")} <br /> {beginHours}
                    </div>
                );
            } else {
                return <div></div>;
            }
        },
    },
    {
        Header: "Fin de session",
        accessor: "end_session",
        Cell: function (row: any) {
            if (row.original.end_session) {
                const endHours = new Date(row.original.end_session).toLocaleTimeString();
                return (
                    <div>
                        {new Date(row.original.end_session).toLocaleDateString("fr-FR")} <br /> {endHours}
                    </div>
                );
            } else {
                return <div></div>;
            }
        },
    },
    {
        Header: "Protocole L3",
        accessor: "protocol_l3",
        sort: true,
    },
    {
        Header: "Protocole L4",
        accessor: "protocol_l4",
        sort: true,
    },
    // {
    //     Header: "Interface d'entrée",
    //     accessor: "interface_in",
    //     sort: true,
    // },
    {
        Header: "Interface d'entrée",
        accessor: "interface_in",
        Cell: (row: any) => {
            return row.original.interface_in ? row.original.interface_in : "N/A";
        },
        sort: true,
    },
    {
        Header: "IP source",
        accessor: "adresse_ip_source",
        sort: true,
    },
    {
        Header: "Port source",
        accessor: "port_source",
        sort: true,
    },
    // {
    //     Header: "Interface de sortie",
    //     accessor: "interface_out",
    //     sort: true,
    // },
    {
        Header: "Interface de sortie",
        accessor: "interface_out",
        Cell: (row: any) => {
            return row.original.interface_out ? row.original.interface_out : "N/A";
        },
        sort: true,
    },
    {
        Header: "IP destination",
        accessor: "adresse_ip_destination",
        sort: true,
    },
    {
        Header: "Port destination",
        accessor: "port_destination",
        sort: true,
    },
    {
        Header: "Volume \n (octets)",
        accessor: "volume",
        sort: true,
    },
    {
        Header: "Protocole",
        accessor: "protocole",
        sort: true,
    },
    {
        Header: "Infos",
        accessor: "info",
        sort: true,
        width: 150,
    },
];

const options = {
    fieldSeparator: ",",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    showTitle: true,
    title: "Trafic détaillé",
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
};

interface Props {
    Filters: JSX.Element | JSX.Element[];
    live?: boolean;
    equipment_id?: string;
    timeFilter: { $gte: Date; $lt: Date };
    traficsStat?: Array<any>;
    unit: "octets" | "bytes";
}

interface State {
    data: Array<TrafficStatsDetails>;
    dataTopSourceVolume: Array<any>;
    dataTopDestinationVolume: Array<any>;
    dataTopSourceProtocol: Array<any>;
    dataTopDestinationProtocol: Array<any>;
    dataSankey: Array<any>;
    loading: boolean;
}

class TrafficDetails extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            dataTopSourceVolume: [],
            dataTopDestinationVolume: [],
            dataTopSourceProtocol: [],
            dataTopDestinationProtocol: [],
            dataSankey: [],
        };
    }

    componentDidMount() {
        this.initData();
        this.initTopSourceVolume();
        this.initTopSourceProtocol();
        this.initTopDestinationVolume();
        this.initTopDestinationProtocol();
        this.initTopSankey();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (prevProps.equipment_id !== this.props.equipment_id || prevProps.timeFilter !== this.props.timeFilter) {
            this.setState({ loading: true });
            this.initData();
            this.initTopSourceVolume();
            this.initTopSourceProtocol();
            this.initTopDestinationVolume();
            this.initTopDestinationProtocol();
            this.initTopSankey();
        }
    }

    async initData() {
        const TrafficDetail = await this.getTrafficDetails();
        // @ts-ignore
        this.setState(() => ({ loading: false, data: TrafficDetail }));
    }

    generateFilters = () => {
        const { equipment_id, timeFilter } = this.props;
        const beginTimeFilter = this.translateDate(timeFilter.$gte);
        const endTimeFilter = this.translateDate(timeFilter.$lt);

        return {
            equipment_id: equipment_id,
            begin_session: beginTimeFilter,
            end_session: endTimeFilter,
        };
    };

    initTopSourceData = async (protocolFlag: boolean, stateKey: any) => {
        const filters = this.generateFilters();
        try {
            const res = await Socket.getTop10Source(protocolFlag, filters);
            //@ts-ignore
            this.setState({ [stateKey]: res.data });
        } catch (e) {
            console.error(e);
        }
    };

    initTopSourceVolume = async () => {
        this.initTopSourceData(false, "dataTopSourceVolume");
    };

    initTopSourceProtocol = async () => {
        this.initTopSourceData(true, "dataTopSourceProtocol");
    };

    initTopDestinationData = async (protocolFlag: boolean, stateKey: any) => {
        const filters = this.generateFilters();
        try {
            const res = await Socket.getTop10Destination(protocolFlag, filters);
            //@ts-ignore
            this.setState({ [stateKey]: res.data });
        } catch (e) {
            console.error(e);
        }
    };

    initTopDestinationVolume = async () => {
        this.initTopDestinationData(false, "dataTopDestinationVolume");
    };

    initTopDestinationProtocol = async () => {
        this.initTopDestinationData(true, "dataTopDestinationProtocol");
    };

    initTopSankey = async () => {
        const filters = this.generateFilters();
        try {
            const res = await Socket.getTop10Sankey(filters);
            //@ts-ignore
            this.setState({ dataSankey: res.data });
        } catch (e) {
            console.error(e);
        }
    };

    getTrafficDetails = async () => {
        try {
            // const res = await Socket.readLean("TrafficStatsDetails", filters, {});
            const res = await Socket.getTrafficDetails(this.generateFilters());
            // console.log("TRAFFIC_DETAILS_DATA", res2);
            const sortedData = res.data.sort(
                (a: any, b: any) => new Date(a.begin_session).getTime() - new Date(b.begin_session).getTime()
            );
            return sortedData;
        } catch (e) {
            console.error(e);
        }
    };

    translateDate = (dateString: any) => {
        const date = new Date(dateString);
        const isoString = date.toISOString();
        return isoString;
    };

    render() {
        const { Filters, unit } = this.props;
        const {
            data,
            loading,
            dataTopSourceVolume,
            dataTopDestinationVolume,
            dataTopSourceProtocol,
            dataTopDestinationProtocol,
            dataSankey,
        } = this.state;
        const csvExporter = new ExportToCsv(options);

        console.log(data);

        return (
            <div className={"Trafic"}>
                {Filters}
                <div className={"ctn_category_chart"}>
                    <div className="global_data">
                        <div className={`ctn_chart`} style={{ width: "100%" }}>
                            <h5>Trafic Détaillé</h5>
                            {loading ? (
                                <div className={"loader"}>
                                    <img src={loaderGif} alt="loader" />
                                </div>
                            ) : (
                                <BasicTable columns={columns} data={data} tableName={"ok"} />
                            )}
                        </div>
                        {/* <button
                            style={{
                                background: "#417BF6",
                                borderRadius: "0.5vw",
                                padding: "0.5vw",
                                color: "#fff",
                                fontSize: "10px",
                                marginTop: "1vw",
                            }}
                            onClick={() => csvExporter.generateCsv(data)}
                        >
                            Exporter en CSV
                        </button> */}
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="overview">
                        <div>
                            <h5>{"Classement des sources/destinations par volume"}</h5>
                            {loading ? (
                                <div className={"loader"}>
                                    <img src={loaderGif} alt="loader" />
                                </div>
                            ) : (
                                dataSankey.length > 0 && <SankeyChart data={dataSankey} unit={unit} />
                            )}
                        </div>
                        <br />
                        <div className="ctn_charts">
                            {/* {filterData.length > 0
                                    ? filterData.map((d, index) => {
                                        if (d.protocols.length > 0) {
                                            return (
                                                <div key={d._id} className={`ctn_chart`}>
                                                    <h5>{d.name}</h5>
                                                    <PieChart data={d.protocols} index={index + 1} color={d.color} />
                                                </div>
                                            );
                                        }
                                    })
                                    : data.map((d, index) => {
                                        if (d.protocols.length > 0) {
                                            return ( */}
                            <div key={0} className={`ctn_chart`}>
                                <h5>{"Top 10 (IP source / Volume)"}</h5>
                                <PieChart data={dataTopSourceVolume} index={0} color={""} unit={unit} />
                            </div>
                            <div key={1} className={`ctn_chart`}>
                                <h5>{"Top 10 (IP destination / Volume)"}</h5>
                                <PieChart data={dataTopDestinationVolume} index={1} color={""} unit={unit} />
                            </div>
                            <div key={2} className={`ctn_chart`}>
                                <h5>{"Top 10 (IP source / Protocole)"}</h5>
                                <PieChart data={dataTopSourceProtocol} index={2} color={""} unit={unit} />
                            </div>
                            <div key={3} className={`ctn_chart`}>
                                <h5>{"Top 10 (IP destination / Protocole)"}</h5>
                                <PieChart data={dataTopDestinationProtocol} index={3} color={""} unit={unit} />
                            </div>
                            {/* );
                                        }
                                    })} */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TrafficDetails;
