import React from 'react';
import {InterfaceType} from '../../../types';
import GraphLine from "../GraphLine/GraphLine";

interface Props {
    data: Array<{ in: number; out: number; time: Date; interface: string }> | 'loading';
    Filters: JSX.Element | JSX.Element[];
    Analyse: JSX.Element | JSX.Element[];
    live: boolean;
    equipment_id: string;
    graphValues: Array<string>;
    Interfaces: Array<InterfaceType>;
}

class BandePassante extends React.Component<Props> {
    render() {
        const {data, Filters, Analyse, Interfaces, live} = this.props;
        console.log(data);
        return (
            <>
                <div className={'ctn__graph__lines'}>
                    {Filters}
                    <div className={'ctn__graphs'}>
                        <div className={'ctn__graph__line'}>
                            <GraphLine data={data} Interfaces={Interfaces} graphName={'bande__passante'} valuesLines={['in', 'out']} live={live} />
                            {/*<GraphLines*/}
                            {/*    containerName={'BandePassante'}*/}
                            {/*    Interfaces={Interfaces}*/}
                            {/*    live={live}*/}
                            {/*    equipment_id={equipment_id}*/}
                            {/*    graphName={'bande__passante'}*/}
                            {/*    data={data}*/}
                            {/*    valuesLines={graphValues}*/}
                            {/*/>*/}
                        </div>
                    </div>
                </div>
                {Analyse}
            </>
        );
    }
}

export default BandePassante;
