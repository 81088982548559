import React from "react";
import { DhcpStatiqueType, DhcpType } from "../../../../../../../../types";
import { dhcpStatiqueState } from "../../../../../../../../state";
import DhcpStatic from "../DhcpStatique/DhcpStatic/DhcpStatic";
import { Button } from "antd";
import "./DhcpStatics.scss";

interface Props {
  dhcpStatics?: Array<DhcpStatiqueType>;
  interfaceIndex: number;
  changeValue: (path: string, value: string | Array<DhcpStatiqueType>) => void;
  role: any;
}

class DhcpStatics extends React.Component<Props> {
  addDhcpStatic = () => {
    const { changeValue, interfaceIndex, dhcpStatics } = this.props;
    if (dhcpStatics) {
      changeValue(`Interface[${interfaceIndex}].Dhcp.static`, [
        ...dhcpStatics,
        dhcpStatiqueState,
      ]);
    }
  };

  render() {
    const { changeValue, interfaceIndex, dhcpStatics, role } = this.props;
    const key = window.location.pathname.substring(1);
    const rolesCondition =
      role[0] && role[0][key].write === false ? true : false;

    return (
      <div className="DhcpStatics">
        <p>IPs statiques</p>
        {dhcpStatics &&
          dhcpStatics.map((dhcpStatic, dhcpStaticIndex) => (
            <DhcpStatic
              dhcpStaticIndex={dhcpStaticIndex}
              changeValue={changeValue}
              interfaceIndex={interfaceIndex}
              dhcpStatic={dhcpStatic}
              dhcpStatics={dhcpStatics}
              role={role}
            />
          ))}
        {role[0][key].write === true && (
          <Button
            htmlType="button"
            onClick={this.addDhcpStatic}
            className={"btn__dhcpStatics__add"}
          >
            Ajouter un IP statique
          </Button>
        )}
      </div>
    );
  }
}

export default DhcpStatics;
