import React from "react";
import BasicDrawerForm from "../../../../common/BasicDrawerForm/BasicDrawerForm";
import { EquipmentType, FiltrageWebType, ServerLdap, UserType } from "../../../../types";
import TextField from "../../../../common/TextField/TextField";
import { Switch, Alert, Checkbox, Radio, Space, Select, DatePicker } from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import type { RadioChangeEvent } from "antd";
import Lists from "./Lists/Lists";
import SelectMultiple from "../../../../common/SelectMultiple/SelectMultiple";
import SelectSimple from "../../../../common/SelectSimple/SelectSimple";
import Socket from "../../../../utils/Socket";
import { checkEmptyFields, ifNameExist } from "../../../../utils/CheckError";
import { data } from "cypress/types/jquery";
import { array } from "@amcharts/amcharts4/core";
import moment from "moment/moment";
import AddButton from "../../../../common/AddButton/AddButton";
import Loader from "../../../../common/Loader/Loader";

interface Props {
    visible: boolean;
    onClose: () => void;
    selectedItems: Array<string>;
    formType: string;
    equipments: Array<EquipmentType>;
    filtrageWeb: Array<FiltrageWebType>;
    entityId: string;
    categories: Array<any>;
    LDAP: ServerLdap[];
    role: any;
}

interface State {
    data: FiltrageWebType;
    errors: FiltrageWebType;
    messageError: string;
    authentificationValue: any;
    simpleSelectValue: any;
    multipleSelectValue: [];
    users: UserType[];
    day: string;
    test: Array<string>;
    dataDefault: Boolean;
    loader: boolean;
}

const filtrageWebState: FiltrageWebType = {
    _id: "",
    name: "",
    enabled: false,
    categories: [],
    equipements: [],
    white_list: [],
    black_list: [],
    entity_id: "",
    local: [],
    ldap: "",
    authentification: "",
    time_rules: [],
};

class FiltrageWebForm extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: filtrageWebState,
            errors: filtrageWebState,
            messageError: "",
            authentificationValue: "Aucune",
            simpleSelectValue: "",
            multipleSelectValue: [],
            users: [],
            day: "",
            test: [],
            dataDefault: false,
            loader: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.selectedItems !== this.props.selectedItems) {
            if (this.props.selectedItems.length > 0 && this.props.filtrageWeb && this.props.filtrageWeb.length > 0) {
                const filtrageWebFind = this.props.filtrageWeb.find(
                    (filtrageWeb) => this.props.selectedItems[0] === filtrageWeb._id
                );
                if (filtrageWebFind) {
                    this.setState((state) => ({
                        ...state,
                        data: filtrageWebFind,
                        authentificationValue: filtrageWebFind.authentification,
                    }));
                }
            } else {
                this.setState((state) => ({ ...state, data: filtrageWebState }));
            }
        }
        // if (prevProps.formType !== this.props.formType && this.props.formType === "create") {
        // 	this.setState(() => ({
        // 		data: { ...filtrageWebState, entity_id: this.props.entityId },
        // 	}));
        // }
        if (prevProps.visible !== this.props.visible) {
            if (!this.props.visible) {
                this.setState(() => ({ messageError: "" }));
            }
        }
    }

    addIpAdress = (typeList: string) => {
        this.setState((state) => {
            //@ts-ignore
            const dataList = state.data[`${typeList}_list`];
            return {
                data: {
                    ...state.data,
                    [`${typeList}_list`]: [...dataList, { ip: "" }],
                },
            };
        });
    };

    create = (data: FiltrageWebType) => {
        this.setState(
            () => ({
                data: { ...data, entity_id: this.props.entityId },
            }),
            () => {
                Socket.insert("FiltrageWeb", this.state.data.entity_id, this.state.data)
                    .then((res) => {
                        if (res.status === 200) {
                            this.props.onClose();
                            this.setState(() => ({ loader: false }));
                        }
                    })
                    .catch((err) => console.error(err));
            }
        );
    };

    update = (data: FiltrageWebType) => {
        Socket.update("FiltrageWeb", data._id, data.entity_id, data)
            .then((res) => {
                if (res.status === 200) {
                    this.props.onClose();
                    this.setState(() => ({ loader: false }));
                }
            })
            .catch((err) => console.error(err));
    };

    duplicate = async (data: FiltrageWebType) => {
        let newState = { ...data };
        newState = { ...newState, _id: "" };

        try {
            const res = await Socket.insert("FiltrageWeb", newState.entity_id, newState);
            if (res.status === 200) {
                this.props.onClose();
                this.setState(() => ({ loader: false }));
            }
        } catch (err) {
            // todo faire alert erreur
            console.error(err);
        }
    };

    onChangeSelect = (value: any) => {
        console.log("LDAP", this.props.LDAP);
        this.setState((state) => ({ data: { ...state.data, ldap: value, local: [], authentification: "LDAP" } }));
        this.setState({ simpleSelectValue: value });
    };

    onChangeMultipleSelect = (value: any) => {
        console.log(value);
        this.setState(
            (state) => ({
                data: { ...state.data, local: value, ldap: "", authentification: "Local" },
                multipleSelectValue: value,
            }),
            () => console.log(this.state.data)
        );
        // this.setState({ multipleSelectValue: value });
    };
    submit = () => {
        const { formType, filtrageWeb } = this.props;
        const { data } = this.state;
        let sameWhite = false;
        let sameBlack = false;
        console.log("FORMTYPE", formType);

        data.white_list.forEach((e, i) => {
            data.white_list.forEach((elem, index) => {
                if (i !== index) {
                    sameWhite = elem.ip === e.ip;
                }
            });
        });

        data.black_list.forEach((e, i) => {
            data.black_list.forEach((elem, index) => {
                if (i !== index) {
                    sameBlack = elem.ip === e.ip;
                }
            });
        });

        if (ifNameExist(formType, data, filtrageWeb)) {
            this.setState(() => ({ messageError: "Ce nom est déjà utilisé" }));
        } else {
            console.log("DATA", data);
            let newdata = { ...data, authentification: this.state.authentificationValue };
            // @ts-ignore
            this[formType](newdata);
            this.setState(() => ({ loader: true }));
        }
        // else if (this.sameValueInLists(data) || sameWhite || sameBlack) {
        // 	this.setState(() => ({
        // 		messageError:
        // 			"Des IPs sont identiques dans liste blanche et/ou liste noire",
        // 	}));
        // } else {
        // 	const errors = checkEmptyFields(data, ["_id", "__v"]);
        // 	if (Object.keys(errors).length > 0) {
        // 		this.setState(() => ({ errors }));
        // 	} else {
        // 		this.setState(() => ({ messageError: "" }));
        // 		// @ts-ignore
        // 		this[formType](data);
        // 	}
        // }

        // if (ifNameExist(formType, data, filtrageWeb)) {
        // 	this.setState(() => ({ messageError: "Ce nom est déjà utilisé" }));
        // } else if (this.sameValueInLists(data) || sameWhite || sameBlack) {
        // 	this.setState(() => ({
        // 		messageError:
        // 			"Des IPs sont identiques dans liste blanche et/ou liste noire",
        // 	}));
        // } else {
        // 	const errors = checkEmptyFields(data, ["_id", "__v"]);
        // 	// if (Object.keys(errors).length > 0) {
        // 	// 	this.setState(() => ({ errors }));
        // 	// } else {
        // 	this.setState(() => ({ messageError: "" }));
        // @ts-ignore
        // this[formType](data);
        // }
        // }
    };

    sameValueInLists = (data: FiltrageWebType, typeList?: "black" | "white") => {
        const { white_list, black_list } = data;

        for (const iteratorW of white_list) {
            for (const iteratorB of black_list) {
                if (iteratorW.ip === iteratorB.ip) return true;
            }
        }
        return false;
    };

    changeValueIp = (name: string, value: string, typeList: "black" | "white", key: number) => {
        const { data } = this.state;
        const newData = data[`${typeList}_list`].map((elem, i) => (key === i ? { ip: value } : elem));
        this.setState((state) => ({
            data: { ...state.data, [`${typeList}_list`]: newData },
        }));
    };

    changeValue = (name: string, value: string) => {
        this.setState((state) => ({ data: { ...state.data, [name]: value } }));
    };

    changeSwitch = (enabled: boolean) => {
        this.setState((state) => ({ data: { ...state.data, enabled } }));
    };

    changeEquipmentValue = (name: string, value: Array<string>) => {
        const { equipments } = this.props;
        const newValue = value.map((id) => {
            const equipment: EquipmentType | undefined = equipments.find((e) => e._id === id);
            if (equipment) {
                return {
                    _id: equipment._id,
                    name: equipment.name,
                };
            }
        });
        this.setState((state) => ({ data: { ...state.data, [name]: newValue } }));
    };
    changeCategoryValue = (name: string, value: Array<string>) => {
        const { categories } = this.props;
        const newValue = value.map((id) => {
            const categorie: any | undefined = categories.find((e) => e._id === id);
            if (categorie) {
                return {
                    _id: categorie._id,
                    name: categorie.name,
                };
            }
        });
        this.setState((state) => ({ data: { ...state.data, [name]: newValue } }));
    };

    removeAddressIp = (index: number, typeList: "black" | "white") => {
        const { data } = this.state;
        const { formType } = this.props;
        if (formType !== "view") {
            const newFiltrageWebs = data[`${typeList}_list`].filter((_, i) => i !== index);
            this.setState((state) => ({
                data: { ...state.data, [`${typeList}_list`]: newFiltrageWebs },
            }));
        }
    };

    setDataEquipments = (e: any) => {
        const { equipments } = this.props;

        let data: any = { equipements: [] };

        equipments.map((elements) => {
            data.equipements?.push({
                name: elements.name,
                _id: elements.id,
            });
        });

        this.setState({
            data: {
                ...this.state.data,
                equipements: e.target.checked === true ? data.equipements : [],
            },
        });
    };

    setDataCategories = (e: any) => {
        const { categories } = this.props;

        this.setState({
            data: {
                ...this.state.data,
                categories: e.target.checked === true ? categories : [],
            },
        });
    };
    onChangeRadio = (e: RadioChangeEvent) => {
        this.setState({
            authentificationValue: e.target.value,
            simpleSelectValue: "",
        });
    };

    componentDidMount() {
        Socket.read("User").then((resUser) => {
            Socket.readLean("EntityUser", { entity_uuid: this.props.entityId }, { user_uuid: 1 }).then(
                (resEntityUser) => {
                    const users: UserType[] = [];
                    for (const user of resUser.data) {
                        if (
                            resEntityUser.data.find(
                                (entityUser: { user_uuid: string }) => entityUser.user_uuid === user._id
                            )
                        ) {
                            // @ts-ignore
                            users.push({ _id: `${user._id}`, name: `${user.firstname} ${user.lastname}` });
                        }
                    }
                    this.setState(() => ({ users: users }));
                }
            );
        });
    }

    onChangeDate = (value: string, key: number) => {
        const data = {
            ...this.state.data,
            time_rules: this.state.data.time_rules.map((element, index) => {
                let time = element.split(" ")[1] && element.split(" ")[1].split("-");
                if (index === key) {
                    return value + " " + time[0] + "-" + time[1];
                } else {
                    return element;
                }
            }),
        };
        this.setState(() => ({ data }));
    };

    onChangeTime = (value: any, key: number, time: string) => {
        const newTime = value._d.getHours() + ":" + value._d.getMinutes();
        const split = newTime.split("-");

        const data = {
            ...this.state.data,
            time_rules: this.state.data.time_rules.map((element, index) => {
                const extractDay = element.split(" ");
                const extractTime = element.split(" ")[1] && element.split(" ")[1].split("-");

                if (index === key) {
                    if (time === "start") {
                        return extractDay[0] + " " + split[0] + "-" + extractTime[1];
                    } else {
                        return extractDay[0] + " " + extractTime[0] + "-" + split[0];
                    }
                } else {
                    return element;
                }
            }),
        };

        this.setState(() => ({ data }));
    };

    refactorDataDays = () => {
        let tab: any = [];

        for (const rules of this.state.data.time_rules) {
            let obj = {
                day: rules.split(" ")[0],
                time: rules.split(" ")[1] && rules.split(" ")[1].split("-"),
            };
            tab.push(obj);
        }
        return tab;
    };

    addDate = () => {
        const data = {
            ...this.state.data,
            time_rules: [...this.state.data.time_rules, "Lundi 00:00-00:00"],
        };

        this.setState(() => ({ data }));
    };

    removeDate = (key: number) => {
        const data = {
            ...this.state.data,
            time_rules: this.state.data.time_rules.filter((element, index) => {
                if (index !== key)
                    return {
                        ...this.state.data.time_rules,
                        element,
                    };
            }),
        };
        this.setState(() => ({ data }));
    };

    render() {
        const { visible, onClose, formType, equipments, categories, selectedItems } = this.props;
        const { data, errors, messageError, users } = this.state;
        const categorieOrder = categories.sort((a, b) => a.name.localeCompare(b.name));
        
        return (
            <BasicDrawerForm
                formType={formType}
                data={data}
                className={`FiltrageWebForm FiltrageWebForm__${formType}`}
                header={<div>Filtrage Web</div>}
                onClose={onClose}
                submit={this.submit}
                visible={visible}
                loader={this.state.loader}
            >
                {!this.state.loader ? (
                    <>
                        <div className={"ctn__fields"}>
                            <div>
                                <label>{data.enabled ? "Activé" : "Désactivé"}</label>
                                <Switch
                                    checked={data.enabled}
                                    onChange={this.changeSwitch}
                                    disabled={formType === "view"}
                                    aria-label="switch filtrageWeb"
                                />
                                ,
                            </div>
                            <div>
                                <label>Nom</label>
                                <TextField
                                    className={errors.name}
                                    placeholder={"Nom du filtrage"}
                                    name={"name"}
                                    value={data.name}
                                    changeValue={this.changeValue}
                                    type={"text"}
                                    disabled={formType === "view"}
                                    required={true}
                                    ariaLabel="nom"
                                />
                            </div>
                            <div>
                                <label>Équipements</label>
                                <div className={"ctn__select"} style={{ display: "flex", alignItems: "baseline" }}>
                                    <div style={{ padding: "2rem" }}>
                                        <label style={{ marginRight: "1rem" }}>Tout</label>
                                        <Checkbox
                                            disabled={formType === "view"}
                                            onChange={(e) => this.setDataEquipments(e)}
                                        />
                                    </div>
                                    <SelectMultiple
                                        items={equipments}
                                        changeValue={this.changeEquipmentValue}
                                        valuesName={"_id"}
                                        name={"equipements"}
                                        valuesDisplay={"name"}
                                        value={data.equipements.map((element) => element._id)}
                                        placeholder={"Sélectionnez vos équipements"}
                                        disabled={formType === "view"}
                                        ariaLabel="équipements"
                                    />
                                </div>
                            </div>
                            <div>
                                <label>Catégories</label>
                                <div className={"ctn__select"} style={{ display: "flex", alignItems: "baseline" }}>
                                    <div style={{ padding: "2rem" }}>
                                        <label style={{ marginRight: "1rem" }}>Tout</label>
                                        <Checkbox
                                            disabled={formType === "view"}
                                            onChange={(e) => this.setDataCategories(e)}
                                        />
                                    </div>
                                    <SelectMultiple
                                        items={categorieOrder}
                                        changeValue={this.changeCategoryValue}
                                        valuesName={"_id"}
                                        name={"categories"}
                                        valuesDisplay={"name"}
                                        value={data.categories.map((element) => element._id)}
                                        placeholder={"Sélectionnez vos catégories"}
                                        disabled={formType === "view"}
                                    />
                                </div>
                            </div>
                            <div>
                                <label>Authentification</label>
                                <div>
                                    {formType !== "view" ? (
                                        <Radio.Group
                                            onChange={this.onChangeRadio}
                                            value={this.state.authentificationValue}
                                        >
                                            <Space direction="horizontal">
                                                <Radio value={"LDAP"}>LDAP</Radio>
                                                <Radio value={"Local"}>Locale</Radio>
                                                <Radio value={"Aucune"}>Aucune</Radio>
                                                {/*<Radio value={"Radius"}>Radius</Radio>*/}
                                            </Space>
                                        </Radio.Group>
                                    ) : (
                                        this.state.authentificationValue
                                    )}
                                </div>
                            </div>
                            <div>
                                <label>
                                    {this.state.authentificationValue !== "Aucune"
                                        ? this.state.authentificationValue
                                        : ""}
                                </label>
                                {this.state.authentificationValue === "LDAP" && (
                                    <SelectSimple
                                        items={this.props.LDAP}
                                        placeholder={"LDAP"}
                                        changeValue={this.onChangeSelect}
                                        value={data.ldap}
                                        name={""}
                                        valuesDisplay={"name"}
                                        valuesName={"_id"}
                                        disabled={formType === "view"}
                                    />
                                )}
                                {this.state.authentificationValue === "Local" && (
                                    <SelectMultiple
                                        items={this.state.users}
                                        valuesName={"_id"}
                                        valuesDisplay={"name"}
                                        placeholder={"Locale"}
                                        changeValue={this.onChangeMultipleSelect}
                                        name={""}
                                        //@ts-ignore
                                        value={data.local}
                                        disabled={formType === "view"}
                                    />
                                    // <Select
                                    // 	mode={'multiple'}
                                    // 	onChange={this.onChangeMultipleSelect}
                                    // 	options={this.state.users}
                                    // 	defaultValue={this.state.multipleSelectValue}
                                    // />
                                )}
                            </div>
                            {this.refactorDataDays().map((element: any, key: any) => (
                                <div className={"time"}>
                                    <div>
                                        <label>Période</label>
                                        <SelectSimple
                                            value={element?.day}
                                            items={[
                                                "Lundi",
                                                "Mardi",
                                                "Mercredi",
                                                "Jeudi",
                                                "Vendredi",
                                                "Samedi",
                                                "Dimanche",
                                                "Semaine",
                                                "Week-end",
                                            ]}
                                            changeValue={(value: string) => this.onChangeDate(value, key)}
                                            name={""}
                                            className={"Select__periode"}
                                            orderAlphabetique={"decroissant"}
                                            disabled={formType === "view"}
                                        />
                                    </div>
                                    <div>
                                        <label>heure de debut</label>
                                        <DatePicker
                                            format="HH:mm"
                                            picker={"time"}
                                            value={moment(element.time !== undefined && element.time[0], "HH:mm")}
                                            onChange={(value) => this.onChangeTime(value, key, "start")}
                                            disabled={formType === "view"}
                                        />
                                    </div>
                                    <div>
                                        <label>heure de fin</label>
                                        <DatePicker
                                            format="HH:mm"
                                            picker={"time"}
                                            value={moment(element.time !== undefined && element.time[1], "HH:mm")}
                                            onChange={(value) => this.onChangeTime(value, key, "end")}
                                            disabled={formType === "view"}
                                        />
                                    </div>
                                    {formType !== "view" && (
                                        <div
                                            onClick={() => this.removeDate(key)}
                                            className={"icon__cross__removes"}
                                            title={"supprimer"}
                                        />
                                    )}
                                </div>
                            ))}

                            {formType !== "view" && (
                                <div className={"btn__add"}>
                                    <AddButton onClick={this.addDate} str={"une date"} role={this.props.role} />
                                </div>
                            )}

                            {messageError && (
                                <Alert
                                    style={{ textAlign: "center" }}
                                    className={"alert__error__fields"}
                                    message={messageError}
                                    type="error"
                                />
                            )}
                        </div>
                        <Lists
                            disabled={formType === "view"}
                            formType={formType}
                            addIpAddress={this.addIpAdress}
                            white_list={data.white_list}
                            black_list={data.black_list}
                            changeValue={this.changeValueIp}
                            removeAddressIp={this.removeAddressIp}
                        />
                    </>
                ) : (
                    <div className={"contain-loader"}>
                        <Loader />
                    </div>
                )}
            </BasicDrawerForm>
        );
    }
}

export default FiltrageWebForm;
