import React from "react";
import {
    DhcpOptionType,
    DhcpStatiqueType,
    EntityType,
    EntryType,
    EquipmentType,
    GatewayType,
    InterfaceAddressType,
    InterfaceType,
    QosType,
} from "../../../../../types";
import { Button, Collapse } from "antd";
import InterfacePanel from "./InterfacePanel/InterfacePanel";
import InputNameSwitch from "../../../../../common/InputNameSwitch/InputNameSwitch";
import { interfaceState } from "../../../../../state";
import { connect, ConnectedProps } from "react-redux";
import Collections from "../../../../../utils/Collections";
import { v4 as uuidv4 } from "uuid";
import "./Interfaces.scss";
import { GlobalTypes } from "../../../../../store/types";
import DualButton from "../../../../../common/DualButton/DualButton";

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
    };
    global: GlobalTypes;
}

const mapStateToProps = (state: ReduxState) => {
    return {
        collapseEquipmentForm: state.global.collapseEquipmentForm,
        Entities: state.collections.Entity,
        entityId: state.global.entity_id,
    };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const { Panel } = Collapse;

interface Props extends PropsFromRedux {
    interfaces: Array<InterfaceType>;
    equipmentId: string;
    changeValue: (
        path: string,
        value:
            | string
            | boolean
            | InterfaceType
            | Array<InterfaceType | InterfaceAddressType | EntryType | DhcpOptionType | DhcpStatiqueType | string>
    ) => void;
    role: any;
    equipment: EquipmentType;
    gateways: Array<GatewayType>;
}

interface State {
    activeCollapseInterface: string | string[];
    activeNavInterface: string | string[];
}

class Interfaces extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            activeCollapseInterface: [],
            activeNavInterface: ""
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.collapseEquipmentForm !== this.props.collapseEquipmentForm) {
            this.setState(() => ({
                activeCollapseInterface: [this.props.collapseEquipmentForm.interfaceIndex],
                activeNavInterface: this.props.collapseEquipmentForm.activeNavInterface
            }));
        }
        if (
            prevProps.equipment._id === this.props.equipment._id &&
            JSON.stringify(prevProps.interfaces) !== JSON.stringify(this.props.interfaces)
        ) {
            if (this.props.interfaces && this.props.interfaces.length > 0) {
                this.props.interfaces.map((elem, i) => {
                    if (elem.qos_id && prevProps.interfaces && prevProps.interfaces[i].qos_id !== elem.qos_id) {
                        this.updateInterfacesOfQos(elem.qos_id, elem._id);
                    } else if (elem.qos_id && prevProps.interfaces && prevProps.interfaces[i].qos_id !== "") {
                        this.updateInterfacesOfQos(prevProps.interfaces[i].qos_id, elem._id);
                    }
                });
            }
        }
    }

    updateInterfacesOfQos = (idQos: string, idInterface: any) => {
        const Qos = Collections.flatRecursive(this.props.Entities, "Qos", [], "");
        const QosSelected = Qos.filter((elem) => elem._id === idQos);
        let qosWithSameInterface: Array<QosType> = [];
        Qos.map((elem) => {
            if (elem.interfaces.includes(idInterface) && elem._id !== idQos) qosWithSameInterface.push(elem);
        });

        if (QosSelected.length > 0) {
            if (!QosSelected[0].interfaces.includes(idInterface)) {
                QosSelected[0].interfaces.push(idInterface);
            } else if (QosSelected[0].interfaces.includes(idInterface)) {
                QosSelected[0].interfaces.splice(QosSelected[0].interfaces.indexOf(idInterface));
            }
        }
        if (qosWithSameInterface.length > 0) {
            qosWithSameInterface.map((qos, i: number) => {
                qos.interfaces.splice(qos.interfaces.indexOf(qos.interfaces[idInterface]), 1);
            });
        }

        let qosToUpdate = QosSelected.concat(qosWithSameInterface);
        this.props.changeValue("Qos", qosToUpdate);
    };

    addInterface = () => {
        const { interfaces, changeValue } = this.props;
        const virtualInterface = {
            ...interfaceState,
            _id: uuidv4(),
            type: "virtuelle",
        };
        if (interfaces) changeValue("Interface", [...interfaces, virtualInterface]);
    };

    changeCollapse = (value: string | string[]) => {
        this.setState(() => ({ activeCollapseInterface: value, activeNavInterface: "" }));
    };

    removeInterface = (index: number) => {
        const { changeValue, interfaces } = this.props;
        if (interfaces) {
            const newInterfaces = interfaces.filter((_, i) => i !== index);
            changeValue("Interface", newInterfaces);
        }
    };

    changeLanOrWan = (value: string, index: number) => {
        const { interfaces, changeValue } = this.props;
        let newInterfaces = [...interfaces];
        if (newInterfaces[index].zone !== value) {
            newInterfaces[index] = { ...newInterfaces[index], zone: value };
            changeValue("Interface", newInterfaces);
        }
    };

    getGatewaysRemovable = (
        gateways: GatewayType[],
        interfaces: InterfaceType[],
        option: "gatewaysRemovable" | "filteredGateways"
    ) => {
        if (!Array.isArray(gateways) || !Array.isArray(interfaces)) {
            return [];
        }

        const gatewayInterfaceIds = new Set(gateways.map((gateway) => gateway.interface_id));

        const gatewaysRemovable: string[] = [];
        const filteredGateways: any = {};

        interfaces.forEach((interf) => {
            if (!gatewayInterfaceIds.has(interf._id) && interf.type !== "physical") {
                gatewaysRemovable.push(interf._id);
            } else {
                const gateway = gateways.find((g) => g.interface_id === interf._id);
                if (gateway && interf.type !== "physical") {
                    filteredGateways[interf._id] = gateway.name;
                }
            }
        });

        if (option === "gatewaysRemovable") {
            return gatewaysRemovable;
        } else {
            return filteredGateways;
        }
    };

    render() {
        const { interfaces, changeValue, role, equipmentId, Entities, entityId, gateways } = this.props;
        const { activeNavInterface } = this.state;
        const key = window.location.pathname.substring(1);
        const entity = Entities.find((entity) => entity._id === entityId);
        let gatewaysRemovable = this.getGatewaysRemovable(gateways, interfaces, "gatewaysRemovable");
        let gatewaysLinked = this.getGatewaysRemovable(gateways, interfaces, "filteredGateways");
        return (
            <Collapse
                className={"Interfaces"}
                activeKey={this.state.activeCollapseInterface}
                onChange={this.changeCollapse}
            >
                {interfaces &&
                    interfaces.map((interf, index) => (
                        <Panel
                            forceRender={true}
                            className={interfaces && interfaces[index].type === "virtuelle" ? "InterfaceVirtuelle" : ""}
                            header={
                                <>
                                    <InputNameSwitch
                                        changeValue={(path, value) => this.props.changeValue(path, value)}
                                        data={interf}
                                        dataPathName={`Interface[${index}].name`}
                                        placeholder={"Nom de l'interface"}
                                        checkPathName={`Interface[${index}].enabled`}
                                        index={index}
                                        remove={this.removeInterface}
                                        role={role}
                                        deleteType={"l'interface"}
                                        disabledRemove={!gatewaysRemovable.includes(interf._id)}
                                        textGateway={gatewaysLinked[interf._id]}
                                    />
                                    <label
                                        onClick={(event) => {
                                            event.stopPropagation();
                                        }}
                                    >
                                        <DualButton
                                            style={{ position: "absolute", right: "22rem", marginTop: "-1.6rem" }}
                                            initial={interf.zone !== undefined ? interf.zone : "WAN"}
                                            onChange={(value: string) => this.changeLanOrWan(value, index)}
                                            disabled={!role[0][key].write === true}
                                            interf={interf}
                                        />
                                    </label>
                                </>
                            }
                            key={index}
                        >
                            <InterfacePanel
                                equipmentId={equipmentId}
                                changeValue={changeValue}
                                interf={interf}
                                interfaceIndex={index}
                                Interfaces={interfaces}
                                role={role}
                                zone={interf.zone}
                                gateways={gateways}
                                activeNavInterface={activeNavInterface ? activeNavInterface as string : ""}
                            />
                        </Panel>
                    ))}
                {role[0][key].write === true && (
                    <Button htmlType="button" className={"add__panel btn__add__interface"} onClick={this.addInterface}>
                        Ajouter une interface virtuelle
                    </Button>
                )}
            </Collapse>
        );
    }
}

export default connector(Interfaces);
