import React from "react";
import BasicTable from "../../../common/BasicTable/BasicTable";
import { FireWallType } from "../../../types";
import BarTableActions from "../../../common/BarTableAction/BarTableActions";
import Socket from "../../../utils/Socket";
import FirewallForm from "./FirewallForm/FirewallForm";
import AddButton from "../../../common/AddButton/AddButton";
import IconDuplicate from "../../../common/SVGComponents/IconDuplicate";
import Regles from "./Regles/Regles";
import ContainerRestPage from "../../../common/ContainerRestPage/ContainerRestPage";
import { Switch } from "antd";

const columns = [
    {
        Header: "Nom de la politique",
        accessor: "name",
        sort: true,
    },
    {
        Header: "État",
        accessor: "etat",
        Cell: function cell(row: { original: any }) {
            return <Switch className=" pull-right" checked={row.original.enabled} disabled={true} />;
        },
    },
];

interface Props {
    firewall: Array<FireWallType>;
    entityId: string;
    role: any;
}

interface State {
    selectedItems: Array<string>;
    visibleForm: boolean;
    formType: string;
    visibleRegles: boolean;
}

class Firewall extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedItems: [],
            visibleForm: false,
            formType: "",
            visibleRegles: false,
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.entityId !== this.props.entityId) {
            this.setState({ selectedItems: [] });
        }
    }

    selectItems = (ids: Array<string>) => {
        this.setState(() => ({ selectedItems: ids }));
    };

    openForm = (formType: string, selectedItem?: string): void => {
        if (selectedItem) {
            this.setState(() => ({ visibleForm: true, formType, selectedItems: [selectedItem] }));
        } else {
            this.setState(() => ({ visibleForm: true, formType }));
        }
    };

    closeEdit = (): void => {
        this.setState((state) => ({
            ...state,
            selectedItems: [],
        }));
    };

    closeForm = () => {
        this.setState((state) => ({
            ...state,
            selectedItems: [],
            visibleForm: false,
        }));
    };

    deleteItems = () => {
        const { firewall } = this.props;
        const { selectedItems } = this.state;
        if (firewall && firewall.length > 0) {
            const entitiesId: Array<string> = firewall
                .filter((i) => selectedItems.includes(i._id))
                .flatMap((i) => i.entity_id);
            Socket.deleteMany("FireWall", entitiesId, selectedItems)
                .then((res) => {
                    console.info(res);
                    this.closeEdit();
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    };

    openRegles = () => {
        this.setState(() => ({ visibleRegles: true }));
    };

    closeRegles = () => {
        this.setState(() => ({ visibleRegles: false }));
    };

    render() {
        const { firewall, role } = this.props;
        const { selectedItems, visibleForm, formType, visibleRegles } = this.state;
        console.log(this.props.entityId, selectedItems);
        return (
            <div className={"Firewall"}>
                <div className={"d-flex"}>
                    <h3>Firewall</h3>
                    <AddButton str={"une Politique"} onClick={() => this.openForm("create")} role={role} />
                </div>
                <ContainerRestPage>
                    <BasicTable
                        openForm={this.openForm}
                        selectedItems={selectedItems}
                        selectItems={this.selectItems}
                        columns={columns}
                        data={firewall}
                        tableName={"security__firewall__table"}
                        role={role}
                    />
                </ContainerRestPage>
                <BarTableActions
                    selectedItems={selectedItems}
                    openForm={this.openForm}
                    deleteItems={this.deleteItems}
                    closeBar={this.closeEdit}
                    role={role}
                    optionalItem={
                        <div className={selectedItems.length > 1 ? "d-none" : ""} onClick={this.openRegles}>
                            <IconDuplicate />
                            <p>Règles</p>
                        </div>
                    }
                />
                <FirewallForm
                    formType={formType}
                    onClose={this.closeForm}
                    visible={visibleForm}
                    selectedItems={selectedItems}
                    firewall={firewall}
                />
                <Regles
                    selectedFireWallsItems={selectedItems}
                    fireWalls={firewall}
                    visibleRegles={visibleRegles}
                    closeRegles={this.closeRegles}
                    role={role}
                />
            </div>
        );
    }
}

export default Firewall;
