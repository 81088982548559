import React from 'react';
import { EquipmentType, RoutageType } from "../../../../../types";
import Routage from "./Routage/Routage";
import { Collapse } from "antd";
import InputNameSwitch from "../../../../../common/InputNameSwitch/InputNameSwitch";
import { routagesState } from "../../../../../state";

const { Panel } = Collapse;

interface Props {
    equipment: EquipmentType,
    Routages: Array<RoutageType> | undefined,
    changeValue: (path: string, value: string | boolean | Array<RoutageType>) => void
    role: any
}

interface State {
    routages: Array<RoutageType>
}


class Routages extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
    }
    addRoutage = () => {
        const { changeValue, Routages, equipment } = this.props;
        const { Gateways } = equipment


        if (Routages && Gateways) {
            routagesState.gateway = Gateways
            routagesState.status = 'failover';
            changeValue('Routage', [...Routages, routagesState]);
        }
    }
    removeRoutage = (index: number) => {
        const { Routages, changeValue } = this.props;
        if (Routages) {
            const newRoutages = Routages.filter((_, i) => i !== index);
            changeValue('Routage', newRoutages);
        }
    }

    render() {
        const { changeValue, Routages, equipment, role } = this.props;
        const { Gateways } = equipment
        const key = window.location.pathname.substring(1)
        return (

            <Collapse className={"Routages"}>
                {Routages && Routages.map((routage, index) => (
                    <Panel key={routage._id !== "" ? routage._id : routage.name} header={
                        <InputNameSwitch changeValue={(path, value) => changeValue(path, value)}
                            data={routage} deleteType={"le routage"}
                            dataPathName={`Routage[${index}].name`}
                            placeholder={"Nom de la route"}
                            checkPathName={`Routage[${index}].enabled`} role={role}
                            index={index} remove={this.removeRoutage}
                            firstDiv={true}
                        />
                    }
                    >

                        <Routage index={index} routage={routage} changeValue={changeValue} Gateways={Gateways}
                            removeRoutage={this.removeRoutage} role={role} equipment_id={equipment._id} />
                    </Panel>
                ))}
                {
                    role[0][key].write === true &&
                    <button className={'add__panel'} onClick={this.addRoutage}>Ajouter un routage</button>
                }

            </Collapse>
        );
    }
}

export default Routages;