import * as React from "react";

//@ts-ignore
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16.1"
    style={{
      enableBackground: "new 0 0 16 16.1",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M8 .5C3.8.5.4 3.9.4 8c0 4.2 3.4 7.6 7.6 7.6 4.2 0 7.6-3.4 7.6-7.6C15.6 3.9 12.2.5 8 .5zm6.3 9.2c-.9.3-1.8.5-2.7.7.2-1.6.2-3.2 0-4.8.9.2 1.9.4 2.7.6.2.5.2 1.1.2 1.7.1.7 0 1.3-.2 1.8zM1.6 9.6c-.1-.5-.2-1-.2-1.6 0-.6.1-1.1.2-1.7.9-.3 1.9-.5 2.9-.7-.2 1.6-.2 3.2 0 4.8-.9-.2-1.8-.4-2.6-.7l-.3-.1zm3.9-4.1c1.7-.2 3.4-.2 5.1 0 .2 1.7.2 3.4 0 5.1-1.7.2-3.4.2-5.1 0-.2-1.7-.2-3.4 0-5.1zm8.4-.4c-.8-.2-1.6-.4-2.4-.5-.1-.8-.3-1.6-.5-2.4 1.3.7 2.3 1.7 2.9 2.9zM9.8 1.8c.3.9.5 1.8.6 2.7-1.6-.2-3.2-.2-4.8 0 .2-.9.4-1.8.7-2.6l.1-.2c.5-.1 1-.2 1.6-.2.6 0 1.3.1 1.8.3zm-4.6.3c-.2.9-.4 1.7-.5 2.5-.9.1-1.8.3-2.6.6.6-1.4 1.7-2.4 3.1-3.1zm-3.1 8.8h.1l2.4.6c.2.8.4 1.6.6 2.5-1.3-.7-2.5-1.8-3.1-3.1zm4.3 3.5c-.3-1-.5-1.9-.7-2.8.8.1 1.6.2 2.4.2.8 0 1.6-.1 2.4-.2-.2 1-.4 1.9-.6 2.7-.6.2-1.2.3-1.8.3-.7 0-1.2-.1-1.7-.2zm4.6-.5c.2-.8.4-1.6.5-2.4.8-.1 1.6-.3 2.4-.5-.6 1.2-1.6 2.2-2.9 2.9z"
      style={{
        fill:
          props.data === "operationnal"
            ? "#35D098"
            : props.data === "degraded"
            ? "#FFBA88"
            : props.data === "critical"
            ? "#EB5264"
            : "black",
      }}
    />
  </svg>
);

export default SvgComponent;
