import React from "react";
import { EquipmentType, GatewayType, InterfaceType } from "../../../../../types";
import Gateway from "./Gateway/Gateway";
import { Button, Collapse } from "antd";
import InputNameSwitch from "../../../../../common/InputNameSwitch/InputNameSwitch";
import { gatewayState, interfaceState } from "../../../../../state";
import { v4 as uuidv4 } from "uuid";

const { Panel } = Collapse;

interface Props {
    equipment: EquipmentType;
    Gateways: Array<GatewayType> | undefined;
    changeValue: (path: string, value: string | boolean | number | Array<GatewayType>) => void;
    role: any;
}

class Passerelles extends React.Component<Props> {
    addGateway = () => {
        const { Gateways, changeValue } = this.props;
        gatewayState._id = uuidv4();
        if (Gateways) changeValue("Gateways", [...Gateways, gatewayState]);
    };

    removeGateway = (index: number) => {
        const { changeValue, Gateways, equipment } = this.props;
        if (Gateways) {
            const newGateways = Gateways.filter((_, i) => index !== i);
            let Interfaces: any = equipment.Interface ? [...equipment.Interface] : [];
            let newInterfaces = Interfaces.map((elem: InterfaceType) => {
                if (elem._id === Gateways[index].interface_id) {
                    elem.zone = "LAN";
                }
                return elem;
            });
            changeValue(`Interface`, newInterfaces);
            changeValue(`Gateways`, newGateways);
        }
    };
    // changeGatewayName = (path: string, value: any, index: number) => {
    //     const { Gateways, changeValue, role, equipment } = this.props;
    //     const duplicateGateway: GatewayType | undefined =
    //         Gateways && Gateways.find((element: GatewayType) => element.name === value);
    //     if (duplicateGateway !== undefined) {
    //         let duplicateName = document.querySelectorAll(`input[name="Gateways[${index}].name"]`)[0];
    //         duplicateName.classList.add("error__field__form");
    //     }else {
    //         let duplicateName = document.querySelectorAll(`input[name="Gateways[${index}].name"]`)[0];
    //         duplicateName.classList.remove("error__field__form");
    //     }
    //     console.log("DUPLICATE_GATEWAY_NAME", duplicateGateway);
    //     // changeValue(path, value)
    // };

    render() {
        const { Gateways, changeValue, role, equipment } = this.props;
        const key = window.location.pathname.substring(1);

        return (
            <Collapse className={"Passerelles"}>
                {Gateways &&
                    Gateways.map((gateway, index) => (
                        <Panel
                            forceRender={true}
                            header={
                                <InputNameSwitch
                                    changeValue={(path, value) => changeValue(path, value)}
                                    dataPathName={`Gateways[${index}].name`}
                                    placeholder={"Nom de la passerelle"}
                                    data={gateway}
                                    checkPathName={`Gateways[${index}].enabled`}
                                    index={index}
                                    remove={this.removeGateway}
                                    role={role}
                                    deleteType={"la passerelle"}
                                    firstDiv={true}
                                    disabledRemove={!role[0][key].write === true}
                                />
                            }
                            key={index}
                        >
                            <Gateway
                                key={index}
                                changeValue={changeValue}
                                gatewayIndex={index}
                                gateway={gateway}
                                role={role}
                                equipment_id={this.props.equipment._id}
                                interfaces={equipment.Interface || []}
                                gateways={Gateways}
                            />
                        </Panel>
                    ))}
                {role[0][key].write === true && (
                    <Button htmlType="button" className={"add__panel"} onClick={this.addGateway}>
                        Ajouter une passerelle
                    </Button>
                )}
            </Collapse>
        );
    }
}

export default Passerelles;
