import React from "react";
import ContainerRestPage from "../../../common/ContainerRestPage/ContainerRestPage";
import BasicTable from "../../../common/BasicTable/BasicTable";
import {EntityType, UserType, VpnUserType} from "../../../types";
import AddButton from "../../../common/AddButton/AddButton";
import BarTableActions from "../../../common/BarTableAction/BarTableActions";
import Socket from "../../../utils/Socket";
import UserForm from "./UserForm/UserForm";
import nomade from "../../Labo/Nomade/Nomade";
import {vpnUserState} from "../../../state";
import Collections from "../../../utils/Collections";

const columns = [
    {
        Header: 'Name',
        accessor: 'lastname',
        sort: true
    },
    {
        Header: 'Prénom',
        accessor: 'firstname',
        sort: true
    },
    {
        Header: 'Téléphone',
        accessor: 'mobile',
        sort: true
    },
    {
        Header: 'E-mail',
        accessor: 'email',
        sort: true
    },
    {
        Header: 'Profil de nomade',
        accessor: 'nomade',
        sort: true,
        Cell: (user: any) => {
            const nomadeName = user.original.nomade && user.original.nomade.name;
            const vpn_connnected = user.original.EntityUser[0].filtrage_web;
            return (
                <div className={'d-flex'} >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16.1" style={{width: '1vw', marginRight: '0.3vw', display: nomadeName  ? 'block' : 'none'}}
                         className="logo-cloudConnector" >
                        <path d="M8 .5C3.8.5.4 3.9.4 8c0 4.2 3.4 7.6 7.6 7.6 4.2 0 7.6-3.4 7.6-7.6C15.6 3.9 12.2.5 8 .5zm6.3 9.2c-.9.3-1.8.5-2.7.7.2-1.6.2-3.2 0-4.8.9.2 1.9.4 2.7.6.2.5.2 1.1.2 1.7.1.7 0 1.3-.2 1.8zM1.6 9.6c-.1-.5-.2-1-.2-1.6 0-.6.1-1.1.2-1.7.9-.3 1.9-.5 2.9-.7-.2 1.6-.2 3.2 0 4.8-.9-.2-1.8-.4-2.6-.7l-.3-.1zm3.9-4.1c1.7-.2 3.4-.2 5.1 0 .2 1.7.2 3.4 0 5.1-1.7.2-3.4.2-5.1 0-.2-1.7-.2-3.4 0-5.1zm8.4-.4c-.8-.2-1.6-.4-2.4-.5-.1-.8-.3-1.6-.5-2.4 1.3.7 2.3 1.7 2.9 2.9zM9.8 1.8c.3.9.5 1.8.6 2.7-1.6-.2-3.2-.2-4.8 0 .2-.9.4-1.8.7-2.6l.1-.2c.5-.1 1-.2 1.6-.2.6 0 1.3.1 1.8.3zm-4.6.3c-.2.9-.4 1.7-.5 2.5-.9.1-1.8.3-2.6.6.6-1.4 1.7-2.4 3.1-3.1zm-3.1 8.8h.1l2.4.6c.2.8.4 1.6.6 2.5-1.3-.7-2.5-1.8-3.1-3.1zm4.3 3.5c-.3-1-.5-1.9-.7-2.8.8.1 1.6.2 2.4.2.8 0 1.6-.1 2.4-.2-.2 1-.4 1.9-.6 2.7-.6.2-1.2.3-1.8.3-.7 0-1.2-.1-1.7-.2zm4.6-.5c.2-.8.4-1.6.5-2.4.8-.1 1.6-.3 2.4-.5-.6 1.2-1.6 2.2-2.9 2.9z" style={{fill: vpn_connnected ? "#0dd2a6" : "#FF0000"}}/>
                    </svg>
                    {nomadeName || ''}
                </div>
            )
        }
    },

    {
        Header: 'Profil de filtrage',
        accessor: 'filtrage',
        sort: true,
        Cell: (user: any) => {
            const filtrageName = user.original.filtrage && user.original.filtrage.name;
            const vpn_connnected = user.original.EntityUser[0].vpn_connected;
            return (
                <div className={'d-flex'} >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16.1" style={{width: '1vw', marginRight: '0.3vw', display: filtrageName ? 'block' : 'none'}}
                         className="logo-cloudConnector" >
                        <path d="M8 .5C3.8.5.4 3.9.4 8c0 4.2 3.4 7.6 7.6 7.6 4.2 0 7.6-3.4 7.6-7.6C15.6 3.9 12.2.5 8 .5zm6.3 9.2c-.9.3-1.8.5-2.7.7.2-1.6.2-3.2 0-4.8.9.2 1.9.4 2.7.6.2.5.2 1.1.2 1.7.1.7 0 1.3-.2 1.8zM1.6 9.6c-.1-.5-.2-1-.2-1.6 0-.6.1-1.1.2-1.7.9-.3 1.9-.5 2.9-.7-.2 1.6-.2 3.2 0 4.8-.9-.2-1.8-.4-2.6-.7l-.3-.1zm3.9-4.1c1.7-.2 3.4-.2 5.1 0 .2 1.7.2 3.4 0 5.1-1.7.2-3.4.2-5.1 0-.2-1.7-.2-3.4 0-5.1zm8.4-.4c-.8-.2-1.6-.4-2.4-.5-.1-.8-.3-1.6-.5-2.4 1.3.7 2.3 1.7 2.9 2.9zM9.8 1.8c.3.9.5 1.8.6 2.7-1.6-.2-3.2-.2-4.8 0 .2-.9.4-1.8.7-2.6l.1-.2c.5-.1 1-.2 1.6-.2.6 0 1.3.1 1.8.3zm-4.6.3c-.2.9-.4 1.7-.5 2.5-.9.1-1.8.3-2.6.6.6-1.4 1.7-2.4 3.1-3.1zm-3.1 8.8h.1l2.4.6c.2.8.4 1.6.6 2.5-1.3-.7-2.5-1.8-3.1-3.1zm4.3 3.5c-.3-1-.5-1.9-.7-2.8.8.1 1.6.2 2.4.2.8 0 1.6-.1 2.4-.2-.2 1-.4 1.9-.6 2.7-.6.2-1.2.3-1.8.3-.7 0-1.2-.1-1.7-.2zm4.6-.5c.2-.8.4-1.6.5-2.4.8-.1 1.6-.3 2.4-.5-.6 1.2-1.6 2.2-2.9 2.9z" style={{fill: vpn_connnected ? "#0dd2a6" :"#FF0000"}}></path>
                    </svg>
                    {filtrageName || ''}
                </div>
            )
        }
    },
    {
        Header: 'Vérification',
        accessor: 'email',
        Cell: (user: any) => {
	        // const color = user.original.password ? "#0dd2a6" : "#FF0000";

	        const color = user.original.enabled === true ? "#0dd2a6" : "#FF0000";
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                    <path d="M10.2283 13.4938L11.305 14.5704L13.4583 12.4171" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8.61334 7.6996C8.54251 7.69252 8.45751 7.69252 8.37959 7.6996C6.69376 7.64294 5.35501 6.26169 5.35501 4.56169C5.34792 2.82627 6.75751 1.41669 8.49292 1.41669C10.2283 1.41669 11.6379 2.82627 11.6379 4.56169C11.6379 6.26169 10.2921 7.64294 8.61334 7.6996Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8.49294 15.4487C7.20378 15.4487 5.92169 15.1229 4.94419 14.4712C3.23003 13.3237 3.23003 11.4537 4.94419 10.3133C6.89211 9.00998 10.0867 9.00998 12.0346 10.3133" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            )
        }

    },

]

interface Props {
    Users: Array<UserType> | undefined,
    role: Array<any>,
    entityID: string,
    Entity: EntityType[];
}

interface UserTableData extends UserType {
    nomade: VpnUserType
}

interface State {
    visibleForm: boolean,
    selectedItems: string[],
    formType: string,
    users: UserTableData[]
}

export default class Users extends React.Component<Props, State> {


    constructor(props: any) {
        super(props);
        this.state = {
            visibleForm: false,
            selectedItems: [],
            formType: '',
            users: []
        };
    }

    getUsers = () => {
        Socket.read('User')
            .then(resUser => {
				console.log(resUser)
                const nomades = this.props.Entity.flatMap(entity => entity.Vpn_user);
                const filtrages = Collections.flatRecursive(this.props.Entity, "FiltrageWeb", [], "");
                Socket.read('EntityUser')
                    .then(resEntityUser => {
                        const users: Array<UserTableData> = []
                        console.log(resEntityUser)
						console.log(resUser)
                        for (const user of resUser.data) {
                            // @ts-ignore
                            if (resEntityUser.data.find((entityUser) => entityUser.user_uuid === user._id && user.profile === "")) {
                                const nomade = nomades.find(nomade => nomade?.local.includes(user._id as string)) || vpnUserState
                                const filtrage = filtrages.find(filtrage => filtrage?.local.includes(user._id as string)) || vpnUserState
                                //@ts-ignore
                                users.push({...user, nomade: nomade, filtrage: filtrage});
                            }

                        }

                        this.setState(() => ({users: users}))
                    })
            })
    }

    componentDidMount() {
        this.getUsers();
        Socket.live((res: any) => {
            console.log('LIVE', res)
            if(res.collection === "User") {
                this.getUsers()
            }
        })
    }

    openForm = (formType: string, selectedItem?: string): void => {
        if (selectedItem) {
            this.setState(() => ({visibleForm: true, formType, selectedItems: [selectedItem]}));
        } else {
            this.setState(() => ({visibleForm: true, formType}));
        }
    };

    closeForm = (): void => {
        this.setState(() => ({visibleForm: false, formType: '', selectedItems: []}));
    };

    addElement = () => {
        this.openForm('create');
    };

    closeEdit = (): void => {
        this.setState((state) => ({
            ...state,
            selectedItems: [],
        }));
    };

    deleteItems = async (): Promise<void> => {
        const {selectedItems, users} = this.state;
		console.log(selectedItems)
		console.log(users)

		// 	    return (
		//     const lol = this.state.users.map(user => {
		// 		    item === user._id ? { ...user, enabled: !user.enabled } : user
		// 	    )
		//     })
	    //     console.log(lol)
	    // })

	    // this.state.users.map(item => {
		// 	this.state.selectedItems.map(user => {
		// 		console.log(item._id)
		// 		console.log(user)
	    //
		// 		return item._id === user ? {...item, enabled: !item.enabled} : item
		// 	})
	    // })

	    Socket.deleteManyIds('User', this.props.entityID, this.state.selectedItems)

	    // return {
		//     ...devis,
		//     sites: devis.sites.map((site, devisSiteIndex) => {
		// 	    if (siteIndex === devisSiteIndex) {
		// 		    return {
		// 			    ...site,
		// 			    services: site.services.length > 1 ? site.services.filter((_, devisServiceIndex) => serviceIndex !== devisServiceIndex) : [serviceState]
		// 		    };
		// 	    } else {
		// 		    return site;
		// 	    }
		//     })
	    // }




	    // this.setState(() => ({
		//   selectedItems.map((element) => {
		// 	  ...element, {
		// 		  state: false
		// 	    }
		//     })
	    // }))


	    // if (users) {
        //     try {
        //         //@ts-ignore
        //         const EntityIDs = users.filter((n) => selectedItems.includes(n._id)).map(e => e.EntityUser[0].entity_uuid);
		//
        //         // const res = await Socket.deleteMany('Users', ids, selectedItems);
        //         // if (res.status === 200) this.setState(() => ({ selectedItems: [] }));
        //         const promisesUser: any[] = []
        //         selectedItems.forEach(userID => {
        //             const user = users.find(user => user._id === userID);
        //             if(user) {
        //                 // @ts-ignore
        //                 promisesUser.push(Socket.update('Users', userID, user ? user?.EntityUser[0].entity_uuid : this.props.entityID, {...user, enabled: false}))
		//
        //             }
        //         })
        //         const resUser = await Promise.all(promisesUser);
        //     } catch (e) {
        //         console.error(e);
        //     }
        // }
    };


    selectItems = (ids: Array<string>): void => {
        this.setState(() => ({selectedItems: ids}));
    };

    render() {
		console.log(this.state.users.map(element => element))

	    const filteredUsers = this.state.users.filter(user => {
		    // @ts-ignore
		    return user?.EntityUser[0]?.entity_uuid === this.props.entityID
	    });

		console.log(filteredUsers)
        return (
            <div>
                <AddButton str={'un utilisateur'} onClick={this.addElement} role={this.props.role}/>
                <ContainerRestPage>
                    <BasicTable
	                    openForm={this.openForm}
                        selectedItems={this.state.selectedItems}
                        selectItems={this.selectItems}
                        columns={columns}
                        data={filteredUsers}
                        tableName={'user__table'}
                    />
                </ContainerRestPage>
                <BarTableActions
                    selectedItems={this.state.selectedItems}
                    openForm={this.openForm}
                    deleteItems={this.deleteItems}
                    closeBar={this.closeEdit}
                    role={this.props.role}
                />
                <UserForm
                    User={filteredUsers}
                    entityID={this.props.entityID}
                    selectedItems={this.state.selectedItems}
                    onClose={this.closeForm}
                    visible={this.state.visibleForm}
                    formType={this.state.formType}
                    Entity={this.props.Entity}
                />
            </div>
        )
    }
}