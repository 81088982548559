import React from 'react';
import { GroupNetworkType } from '../../../../../types';
import TextField from '../../../../../common/TextField/TextField';
import { Button } from 'antd';
import {each} from "lodash";
import {isIP} from "is-ip";
import isCidr from "is-cidr";


interface Props {
    groupNetworks: Array<GroupNetworkType>;
    groupNetwork: GroupNetworkType;
    groupNetworkIndex: number;
    changeValue: (GroupNetworks: Array<GroupNetworkType>) => void;
    role: any;
    indexNetworks: number;

}

interface State {
    edit: boolean;
}

class GroupNetwork extends React.Component<Props, State> {
	private formRef: HTMLDivElement | null
	constructor(props: any) {
        super(props);
        this.state = {
            edit: false,

        };
	    this.formRef = null;

    }

    componentDidMount() {
        const { groupNetwork } = this.props;
        if (!groupNetwork._id) {
            this.setState(() => ({ edit: true }));
        }
    }

    edit = () => {
        this.setState((state) => ({ edit: !state.edit }));
    };

    remove = () => {
        const { groupNetworks, groupNetwork } = this.props;
        const newGroupPorts = groupNetworks.filter((group) => groupNetwork._id !== group._id);
        this.props.changeValue(newGroupPorts);
    };

    changeValue = (name: string, value: string, index?: number) => {
        const { groupNetworks, groupNetworkIndex} = this.props;
        const newGroupNetworks = groupNetworks.map((group, indexGroup) => {
            if (groupNetworkIndex !== indexGroup) return group;
            if (name === 'name') {
                return { ...group, [name]: value };
            } else {
                return {
                    ...group,
                    networks: group.networks.map((port, i) => {
                        if (index !== i) return port;
                        return { ...port, "value": value };
                    }),
                };
            }
        });
        this.props.changeValue(newGroupNetworks);
    };

    addIp = () => {
        const { groupNetworks, groupNetworkIndex } = this.props;
        const newGroupPorts = groupNetworks.map((group, index) => {
            if (index === groupNetworkIndex) {
                return { ...group, networks: [...group.networks, { value: '' }] }
            } else {
                return group;
            }
        })
        this.setState(() => ({ edit: true }));
        this.props.changeValue(newGroupPorts);
    }

    removeAddressIp = (index: number) => {
        const { changeValue, groupNetworks, groupNetworkIndex } = this.props;

        const newGroupNetworks = groupNetworks.map((group, indexGn) => {
            if (indexGn === groupNetworkIndex) {
                return {
                    ...group,
                    networks: group.networks.filter((_, i) => index !== i)
                }
            } else {
                return group;
            }
        })
        changeValue(newGroupNetworks);

    };

	validateIpAddress = (ipaddress: any, type: string) => {
		return type === "ip" && isIP(ipaddress) || isCidr(ipaddress) !== 0;
	}

	checkRequiredError = () => {
		let error = false;
		let isIpValid: boolean;

		each(this.formRef?.querySelectorAll('[data-required]'), (element) => {
			// @ts-ignore
			const value = element.value ? element.value : element.getAttribute('data-value');
			const isIp = element.getAttribute("data-isip")

			if (isIp) isIpValid = this.validateIpAddress(value, "ip");


			if (!value || isIp && !isIpValid) {
				// element.classList.add('error__field__form');
				error = true;
			}
		});
		each(this.formRef?.querySelectorAll('[data-isip]'), (element) => {
			// @ts-ignore
			isIpValid = this.validateIpAddress(element.value, "ip");
			// @ts-ignore
			if (element.value && !isIpValid) {
				// element.classList.add('error__field__form');
				error = true;
			}
			else {
				element.classList.remove('error__field__form');
			}
		})
		return error;
	};

    render() {
        const { groupNetwork, role, indexNetworks } = this.props;
        const { edit } = this.state;
        const key = window.location.pathname.substring(1)
        const rolesCondition = role[0] && role[0][key].write === false ? true : false;
        return (
            <div className={'GroupNetwork ctn__fields'}>
                <div>
                    <label>Nom du groupe</label>
                    <TextField
                        className={''}
                        placeholder={'Nom du groupe'}
                        name={'name'}
                        value={groupNetwork.name}
                        changeValue={this.changeValue}
                        type={'text'}
                        disabled={!edit}
                        required={true}
                        indexNetworks={indexNetworks}
                    />
                </div>
                <div className={'ctn__details'}>
                    <label>Détails</label>
                    <div className={'list__details'}>
                        {groupNetwork && groupNetwork.networks && groupNetwork.networks.length > 0 && groupNetwork.networks.map((detail: { value: string }, index) => {
                            return (
                                <div key={index} className={'d-flex'}>
                                    <div
                                        onClick={() => this.removeAddressIp(index)}
                                        className={'icon__cross__remove'}
                                        title={'Supprimer'}
                                        style={{ marginTop: "3.2rem" }}
                                    />
                                    <TextField
                                        key={index}
                                        className={''}
                                        placeholder={'Plage ip'}
                                        name={'networks'}
                                        value={detail.value}
                                        changeValue={(name, value) => this.changeValue(name, value, index)}
                                        type={'text'}
                                        disabled={!edit}
                                        required={true}
                                        isIp={true}
                                        indexNetworks={indexNetworks}
                                        indexField={index}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
                {
                    role[0][key].write === true &&
                    <>
                        <Button htmlType="button" onClick={this.addIp} className={'btn__add__ip btn__entry__update'}>
                            Ajouter un réseau
                        </Button>
                        <div className={'ctn__btns'}>

                            <Button htmlType="button" onClick={this.edit} className={'btn__entry__update'}>
                                Modifier
                            </Button>
                            <Button htmlType="button" onClick={this.remove} className={'btn__entry__delete'}>
                                Supprimer
                            </Button>
                        </div>
                    </>
                }
            </div>
        );
    }
}

export default GroupNetwork;
