import React from 'react';
import { Collapse } from 'antd';
import ChargesPanel from './ChargesPanel/ChargesPanel';
import DetailsPanel from './DetailsPanel/DetailsPanel';
import GroupsPanel from './GroupsPanel/GroupsPanel';
import {EquipmentType, GroupNetworkType, GroupPortType, ModelBuildingType} from '../../../../../types';

const { Panel } = Collapse;

interface Props {
    equipment: EquipmentType;
    changeInformation: (name: string, value: string | Array<string>) => void;
    role: any;
    groupNetworks: Array<GroupNetworkType>;
    groupPorts: Array<GroupPortType>;
    changeGroupeValue: (type: "groupNetworks" | "groupPorts", data: Array<GroupPortType | GroupNetworkType>) => void
}

class Informations extends React.Component<Props> {
    render() {
        const { equipment, changeInformation, role, groupNetworks, groupPorts } = this.props;
        const { name, serial, site_address1, site_name, _id, model } = equipment

        return (
            <Collapse>
                <Panel forceRender={true} header={'Details'} key={0}>
                    <DetailsPanel
                        changeInformation={changeInformation}
                        name={name}
                        serial={serial}
                        site_address1={site_address1}
                        site_name={site_name}
                        id={_id}
                        model={model}
                        gateways={equipment.Gateways}
                        routages={equipment.Routage}
                        role={role}
                    />
                </Panel>
                {/*<Panel forceRender={true} header={'Groupes'} key={1}>*/}
                {/*    <GroupsPanel changeGroupeValue={this.props.changeGroupeValue} groupNetworks={groupNetworks} groupPorts={groupPorts} changeInformation={changeInformation} role={role}/>*/}
                {/*</Panel>*/}
                <Panel header={'Charge'} key={1}>
                    <ChargesPanel equipment_id={equipment._id} />
                </Panel>
            </Collapse>
        );
    }
}

export default Informations;
