import React from "react";
import IconBp from "../../../../assets/images/bande-passante.png";
import IconCloud from "../../../../assets/images/Cloud.png";
import IconServer from "../../../../assets/images/Server.png";
import IconLink from "../../../../assets/images/link.png";
import IconSND from "../../../../assets/images/snd-white-2.png";
import IconRSnd from "../../../../assets/images/r-snd.png";
import IconBoitier from "../../../../assets/images/boitier-2.png";
import IconBorne from "../../../../assets/images/borne.png";
import { DashboardType } from "../../../../types";

interface Props {
    Dashboard: DashboardType | undefined;
}

class Banner extends React.Component<Props> {
    convertToGiga = (value: number) => {
        console.log(value);
        if (value >= 1000) {
            return value / 1000 + " " + "Gb/s";
        } else {
            return value + " " + "Mb/s";
        }
    };
    render() {
        const { Dashboard } = this.props;
        if (Dashboard) {
            return (
                <div className="bloc">
                    <div className={"Banner"}>
                        <div className={"sub__ctn"}>
                            <div className={"ctn__img"}>
                                <img className={"order_1"} src={IconBp} alt="" />
                            </div>
                            <p>
                                {Dashboard.activebandwidth && Dashboard.activebandwidth[0]?.download
                                    ? this.convertToGiga(Dashboard.activebandwidth[0].download)
                                    : 0}{" "}
                            </p>
                            <p>Bande passante</p>
                            <p>(Utilisée)</p>
                        </div>
                        <div className={"sub__ctn"}>
                            <div className={"ctn__img"}>
                                <img className={"order_2"} src={IconCloud} alt="" />
                            </div>
                            <p>
                                {Dashboard.activecloudconn && Dashboard.activecloudconn[0]?.count
                                    ? Dashboard.activecloudconn[0].count
                                    : 0}
                                /{Dashboard.cloudconn[0].count} <br /> Tunnels IPsec
                            </p>
                        </div>
                        <div className={"sub__ctn"}>
                            <div className={"ctn__img"}>
                                <img className={"order_3"} src={IconServer} alt="" />
                            </div>
                            <p>
                                {Dashboard.activevbox[0].count}/{Dashboard.vbox[0].count} <br /> Machines Virtuelles
                            </p>
                        </div>
                        <div className={"sub__ctn"}>
                            <div className={"ctn__img"}>
                                <img className={"order_4"} src={IconLink} alt="" />
                            </div>
                            <p>
                                {Dashboard.activevpnuser[0].count}/{Dashboard.vpnuser[0].count}
                                <br />
                                VPN {Dashboard.activelink[0].count}/{Dashboard.link[0].count} Liaisons
                            </p>
                        </div>
                        <div className={"sub__ctn"}>
                            <div className={"ctn__img"}>
                                <img className={"order_5"} src={IconSND} alt="" />
                            </div>
                            <p>
                                {Dashboard.activebox[0].count}/{Dashboard.box[0].count} <br /> Boîtier SD-WAN
                            </p>
                        </div>
                        <div className={"sub__ctn"}>
                            <div className={"ctn__img"}>
                                <img className={"order_6"} src={IconRSnd} alt="" />
                            </div>
                            <p>
                                {Dashboard.vpnuser[0].count}
                                <br />
                                {/* VPN  {Dashboard.activelink[0].count}/{Dashboard.link[0].count}  Liaisons */}
                                VPN Nomades
                            </p>
                        </div>
                    </div>
                    <div className={"Banner internet ml-3"} style={{ width: "20vw" }}>
                        <div className={"sub__ctn2"}>
                            <div className={"ctn__img2"}>
                                <img className={"order_7"} src={IconBoitier} alt="" />
                            </div>
                            <p>
                                {Dashboard.activeswitch[0].count}/{Dashboard.switch[0].count} <br /> Switchs
                            </p>
                        </div>
                        <div className={"sub__ctn2"}>
                            <div className={"ctn__img2"}>
                                <img className={"order_8"} src={IconBorne} alt="" />
                            </div>
                            <p>
                                {Dashboard.activeap[0].count}/{Dashboard.ap[0].count} <br /> Wi-Fi
                            </p>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="bloc">
                    <div className={"Banner"}>
                        <div className={"sub__ctn"}>
                            <div className={"ctn__img"}>
                                <img className={"order_1"} src={IconBp} alt="" />
                            </div>
                            <p>0Mb/s</p>
                            <p>Bande passante</p>
                            <p>(Utilisée)</p>
                        </div>
                        <div className={"sub__ctn"}>
                            <div className={"ctn__img"}>
                                <img className={"order_2"} src={IconCloud} alt="" />
                            </div>
                            <p>
                                0 <br /> Tunnels IPSec
                            </p>
                        </div>
                        <div className={"sub__ctn"}>
                            <div className={"ctn__img"}>
                                <img className={"order_5"} src={IconSND} alt="" />
                            </div>
                            <p>
                                0 <br /> Machines Virtuelles
                            </p>
                        </div>
                        <div className={"sub__ctn"}>
                            <div className={"ctn__img"}>
                                <img className={"order_4"} src={IconLink} alt="" />
                            </div>
                            <p>
                                0
                                <br />
                                VPN 0 liaisons
                            </p>
                        </div>
                        <div className={"sub__ctn"}>
                            <div className={"ctn__img"}>
                                <img className={"order_3"} src={IconServer} alt="" />
                            </div>
                            <p>
                                0 <br /> Appliances
                            </p>
                        </div>
                        <div className={"sub__ctn"}>
                            <div className={"ctn__img"}>
                                <img className={"order_6"} src={IconRSnd} alt="" />
                            </div>
                            <p>
                                0/0 <br /> VPN Nomades
                            </p>
                        </div>
                    </div>
                    <div className={"Banner internet ml-3"} style={{ width: "20vw" }}>
                        <div className={"sub__ctn2"}>
                            <div className={"ctn__img2"}>
                                <img className={"order_7"} src={IconBoitier} alt="" />
                            </div>
                            <p>
                                0 <br /> Switchs
                            </p>
                        </div>
                        <div className={"sub__ctn2"}>
                            <div className={"ctn__img2"}>
                                <img className={"order_8"} src={IconBorne} alt="" />
                            </div>
                            <p>
                                0 <br /> Wi-Fi
                            </p>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default Banner;
