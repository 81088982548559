import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { EntityType, GroupNetworkType, GroupPortType } from '../../../../types';
import Collections from '../../../../utils/Collections';
import GroupNetwork from './GroupNetwork/GroupNetwork';
import { Button } from "antd";
import { groupNetworkState } from "../../../../state";
import {each} from "lodash";
import {isIP} from "is-ip";
import isCidr from "is-cidr";

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
    };
    global: {
        entity_id: string;
        roles: Array<any>
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
        entityId: state.global.entity_id,
        role: state.global.roles
    };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    groupNetworks: Array<GroupNetworkType>;
    changeGroupeValue: (type: "groupNetworks" | "groupPorts", data: Array<GroupPortType | GroupNetworkType>) => void
    // changeValue: (path: string, value: string | Array<GroupNetworkType>) => void,
}

class GroupNetworks extends React.Component<Props> {

	changeValue = (groupNetworks: Array<GroupNetworkType>) => {
        this.props.changeGroupeValue('groupNetworks', groupNetworks)
    };

    addGroupe = () => {
        const newGroupe = [...this.props.groupNetworks, { ...groupNetworkState, entity_id: this.props.entityId ,networks: [{ value: '' }] }]
        this.props.changeGroupeValue('groupNetworks', newGroupe)
    }

    render() {
        const { role, groupNetworks } = this.props;
        return (
            <div className={'GroupNetworks'}>
                <div className="objects__title">
                    <h2>Objets Réseaux</h2>
                </div>
                {groupNetworks.map((groupNetwork, index) => (
                    <GroupNetwork
                        key={groupNetwork._id}
                        groupNetwork={groupNetwork}
                        changeValue={this.changeValue}
                        groupNetworks={groupNetworks}
                        groupNetworkIndex={index}
                        role={role}
                        indexNetworks={index + 1}
                    />
                ))}
                <Button htmlType="button" onClick={this.addGroupe} className={'btn__add__group btn__entry__update'}>
                    Ajouter un groupe
                </Button>
            </div>
        );
    }
}

export default connector(GroupNetworks);
