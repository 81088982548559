import React from 'react';
import BasicDrawerForm from '../../../../common/BasicDrawerForm/BasicDrawerForm';
import { Switch, Checkbox } from 'antd';
import { EntityType, FireWallType } from '../../../../types';
import TextField from '../../../../common/TextField/TextField';
import SelectMultiple from '../../../../common/SelectMultiple/SelectMultiple';
import { nanoid } from 'nanoid';
import Boitier from '../../../../assets/images/boitire.png';
import { connect, ConnectedProps } from 'react-redux';
import Collections from '../../../../utils/Collections';
import Socket from '../../../../utils/Socket';
import { fireWallState } from '../../../../state';
import { checkEmptyFields, ifNameExist } from '../../../../utils/CheckError';
import Loader from "../../../../common/Loader/Loader";

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
    };
    global: { entity_id: string };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
        entityId: state.global.entity_id,
    };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
    visible: boolean;
    onClose: () => void;
    selectedItems: Array<string>;
    firewall: Array<FireWallType>;
    formType: string;
}

interface State {
    data: FireWallType;
    errors: FireWallType;
    boolean: Boolean;
	loader: Boolean
}

class FirewallForm extends React.Component<Props & PropsFromRedux, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: fireWallState,
            errors: fireWallState,
            boolean: false,
	        loader: false
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.selectedItems !== this.props.selectedItems) {
            if (this.props.selectedItems.length > 0 && this.props.firewall && this.props.firewall.length > 0) {
                const firewallFind = this.props.firewall.find(
                    (firewall) => this.props.selectedItems[0] === firewall._id,
                );
                if (firewallFind) {
                    this.setState((state) => ({ ...state, data: firewallFind }));
                }
            } else {
                this.setState((state) => ({ ...state, data: fireWallState }));
            }
        }
        if (prevProps.formType !== this.props.formType && this.props.formType === 'create') {
            this.setState(() => ({ data: { ...fireWallState, entity_id: this.props.entityId } }));
        }
    }

    changeValue = (name: string, value: string | number) => {
        this.setState((state) => ({ ...state, data: { ...state.data, [name]: value } }));
    };

    create = () => {
        const { data, boolean } = this.state;
        let newState = { ...data }
        newState = {
            ...newState,
            //@ts-ignore
            enabled: boolean
        }
        Socket.insert('FireWall', data.entity_id, newState)
            .then(() => {
                this.props.onClose();
	            this.setState(() => ({loader:false}))
            })
            .catch((err) => console.error(err));
    };

    update = () => {
        const { data, boolean } = this.state;
        let newState = { ...data }
        newState = {
            ...newState,
            //@ts-ignore
            enabled: boolean
        }
        Socket.update('FireWall', data._id, data.entity_id, newState)
            .then(() => {
                this.props.onClose();
	            this.setState(() => ({loader:false}))
            })
            .catch((err) => console.error(err));
    };

    duplicate = () => {
	    const { data } = this.state;
	    let newState = { ...data }
	    newState = {
		    ...newState,
		    _id: ""
	    }
	    Socket.insert('FireWall', newState.entity_id, newState)
	    .then(() => {
		    this.props.onClose();
		    this.setState(() => ({loader:false}))
	    })
	    .catch((err) => console.error(err));
    };

    submit = () => {
        const { formType, firewall } = this.props;
        const { data } = this.state;

        if (ifNameExist(formType, data, firewall)) {
            //todo faire le message d'erreur pour un nom existant
        } else {
            const errors = checkEmptyFields(data, ['_id', '__v']);
            if (Object.keys(errors).length > 0) {
                this.setState(() => ({ errors }));
            } else {
                // @ts-ignore
                this[formType]();
	            this.setState(() => ({loader:true}))

            }
        }
    };

    selectEquipments = (value: Array<string>) => {
        const { entities } = this.props;
        const Equipments = Collections.flatRecursive(entities, 'Equipment', [], '');
        // const equipment = Equipments.find(equipment => equipment.name === value[0]);
        const newValue = value.map((id) => {
            const equipment = Equipments.find((equipment) => equipment.name === id);
            return { _id: equipment._id, name: equipment.name };
        });
        this.setState((state) => ({
            data: {
                ...state.data,
                equipment: newValue,
            },
        }));
    };

    changeSwitch = (checked: boolean) => {
        this.setState({ boolean: checked })
    }

    setDataEquipments = (e: any) => {
        const { entities } = this.props;
        const Equipments = Collections.flatRecursive(entities, 'Equipment', [], '');
       
        this.setState({data : { ...this.state.data, equipment : e.target.checked === true ? Equipments : []}})
    }

    render() {
        const { visible, onClose, entities, formType } = this.props;
        const { data, errors } = this.state;
        const Equipments = Collections.flatRecursive(entities, 'Equipment', [], '');

        return (
            <BasicDrawerForm
                formType={formType}
                data={data}
                header={<div>Firewall</div>}
                onClose={onClose}
                className={`FirewallForm FirewallForm__${formType}`}
                submit={this.submit}
                visible={visible}
            >
	            {!this.state.loader ?
		            <>
		            <div className={'ctn__fields'}>
                    <div className={'row_input'}>
                        {/*<label>{data.enabled === true ? "Activé" : "Désactivé"}</label>*/}
                        <label>État </label>
                        <Switch
                            onChange={(checked) => this.changeSwitch(checked)}
                            //  checked={data.enabled}
                            defaultChecked={data.enabled}
                            disabled={formType === 'view'}
                            aria-label="switch firewall"
                        />
                    </div>
                    <div className={'row_input'}>
                        <label>Nom</label>
                        <TextField
                            className={errors.name}
                            placeholder={'Nom'}
                            name={'name'}
                            value={data.name}
                            required={true}
                            changeValue={this.changeValue}
                            disabled={formType === 'view'}
                            type={'text'}
                            ariaLabel='nom'
                        />
                    </div>
                    <div className={'row_input'}>
                        <label>Équipements</label>
                        <div className={'ctn__select'} style={{display : 'flex', alignItems: 'baseline'}}>
                        <div style={{padding: '2rem'}}>
                                <label style={{marginRight: '1rem'}}>Tout</label>
                                <Checkbox disabled={formType === 'view'} onChange={(e) => this.setDataEquipments(e)}/>
                            </div>
                            <SelectMultiple
                                disabled={formType === 'view'}
                                items={Equipments}
                                changeValue={this.selectEquipments}
                                valuesName={'name'}
                                valuesDisplay={'name'}
                                value={data.equipment.map((element) => element.name)}
                                placeholder={'Sélectionner vos equipements'}
                                ariaLabel='équipements'
                            />
                        </div>
                    </div>
                    <div className={'contain-equipement'}>
                        {data.equipment.length > 0 ? (
                            data.equipment.map((element) => (
                                <div key={nanoid()} className={'equipement'}>
                                    <img className={'image-equipement'} src={Boitier} alt={''} />
                                    <p className={'name-equipement'}>{element.name}</p>
                                </div>
                            ))
                        ) : (
                            <p className={'p__placeholder'}>Veuillez sélectionner vos equipements</p>
                        )}
                    </div>
                </div>
		            </>
		            :
		            <div className={"contain-loader"}>
			            <Loader />
		            </div>
	            }
            </BasicDrawerForm>
        );
    }
}

export default connector(FirewallForm);
