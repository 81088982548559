import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";

const stateRefac: {
  Dashboard: string;
  Equipements: string;
  Notifications: string;
  Rapport: string;
  Labo: string;
  Securite: string;
  AccesInternet: string;
  Parametres: string;
  [key: string]: string;
} = {
    Dashboard: "Tableau de bord",
    Equipements: 'Équipements',
    Notifications: 'Notifications',
    Rapport: 'Rapports',
    Labo: "Labo",
    Securite: "Sécurité",
    AccesInternet: "Accès Internet",
    Parametres: "Paramètres"
};

interface PathParams {
  pathname: string;
}

interface Props {
  name: string;
  icon: JSX.Element | JSX.Element[];
  data?: any;
}

class Link extends React.Component<Props & RouteComponentProps<PathParams>> {
  render() {
    const { name, location, icon } = this.props;
    return (
      <li className={"Link"} data-active={location.pathname === `/${name}`}>
        <NavLink to={`${name}`} className={"n-l"}>
          {icon}
          <p>{stateRefac[name]}</p>
        </NavLink>
      </li>
    );
  }
}

export default withRouter(Link);
