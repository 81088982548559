import React from 'react';
import Socket from '../../../utils/Socket';
import { DpiCategoryType, DpiProtocolType, TraficStatType } from '../../../types';
import PieChart from '../../../common/PieChart/PieChart';
import loaderGif from '../../../assets/LOADER-WANUP_2.gif';
import SelectMultiple from '../../../common/SelectMultiple/SelectMultiple';

interface Props {
    Filters: JSX.Element | JSX.Element[];
    live: boolean;
    equipment_id: string;
    timeFilter: { $gte: Date; $lt: Date };
    unit: "octets" | "bytes"
}

interface State {
    data: Array<{
        description: string;
        id: number;
        name: string;
        color: string;
        protocols: Array<{
            category: number;
            description: string;
            id: number;
            img: string;
            name: string;
            value: number;
            _id: string;
        }>;
        _id: string;
    }>;
    loading: boolean;
    globalData: Array<{
        name: string;
        value: number;
        color: string;
    }>;
    selectApps: Array<string>;
}

class Trafic extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: [],
            loading: true,
            globalData: [],
            selectApps: [],
        };
    }

    componentDidMount() {
        this.initData();

    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (prevProps.equipment_id !== this.props.equipment_id || prevProps.timeFilter !== this.props.timeFilter) {
            this.initData();
        }
        if (this.state.data !== prevState.data) {
            if (this.state.data.length > 0) {
                this.setState(() => ({ globalData: this.globalData(this.state.data) }));
            }
        }
    }

    globalData = (data: any) => {
        const newData = [];
        for (const { name, protocols, color } of data) {
            if (protocols.length === 0) continue;
            let sum = 0;
            for (const { value } of protocols) {
                if (value === 0) continue;
                sum += value;
            }
            newData.push({ name, value: sum, color });
        }
        return newData;
    };

    async initData() {
        this.setState(() => ({ loading: true }));
        const traffics = await this.getTraffic();
        const protocols = await this.getProtocols();
        const categories = await this.getCategories();
        const protocolsWithValue = this.mergeProtocolsTraffics(protocols, traffics);
        const trafficData = this.sortTrafficByCategory(categories, protocolsWithValue);

        // @ts-ignore
        this.setState(() => ({ loading: false, data: trafficData }));
    }

    sortTrafficByCategory(categories: Array<DpiCategoryType>, protocols: any) {
        for (const category of categories) {
            category.protocols = protocols.filter((p: any) => {
                if (p.category === category.id && p.value) {
                    return p;
                }
            });
        }
        return categories;
    }

    mergeProtocolsTraffics(protocols: Array<DpiProtocolType>, traffics: Array<TraficStatType>) {
        for (const protocol of protocols) {
            for (const t of traffics) {
                if (!t.protocols[protocol.name]) continue;
                if (protocol.value) {
                    protocol.value = parseInt(`${protocol.value}`) + parseInt(`${t.protocols[protocol.name]}`);
                } else {
                    protocol.value = t.protocols[protocol.name];
                }
            }
        }
        return protocols;
    }

    getTraffic = async () => {
        const { timeFilter, equipment_id } = this.props;
        try {
            const res = await Socket.readLean(
                'Traffic_stat',
                {
                    device: equipment_id,
                    time: timeFilter,
                },
                {},
            );
            return res.data;
        } catch (e) {
            console.error(e);
        }
    };

    getProtocols = async () => {
        try {
            const res = await Socket.readLean('Dpi_protocol', {}, {});
            return res.data;
        } catch (e) {
            console.error(e);
        }
    };

    getTrafficDetails = async () => {
        try {
            const res = await Socket.readLean('TrafficStatsDetails', {}, {});
            return res.data;
        } catch (e) {
            console.error(e);
        }
    };

    getCategories = async () => {
        try {
            const res = await Socket.readLean('Dpi_categorie', {}, {});
            return res.data;
        } catch (e) {
            console.error(e);
        }
    };

    selectApp = (_: string, values: Array<string>) => {
        this.setState(() => ({
            selectApps: values,
        }));
    };

    onClick = () => {
      
    }

    render() {

        const { Filters, unit } = this.props;
        const { loading, selectApps, data, globalData } = this.state;
        const filterData = data.filter((d) => selectApps.includes(d.name));
        const filterGlobalData = globalData.filter((d) => selectApps.includes(d.name));
        return (
            <div className={'Trafic'}>
                {Filters}
                <div className="ctn__select__filter">
                    <SelectMultiple
                        items={data ? data.filter((d) => d.protocols.length > 0) : []}
                        changeValue={this.selectApp}
                        valuesName={'name'}
                        valuesDisplay={'name'}
                        name={'model'}
                        value={selectApps}
                        placeholder={'Sélectionner vos applications'}
                    />
                </div>
                {data.length > 0 && (
                    <div className={'ctn_category_chart'}>
                        <div className="global_data">
                            <div className={`ctn_chart`}>
                                <h5>Trafic</h5>
                                <PieChart
                                    data={filterGlobalData.length > 0 ? filterGlobalData : globalData}
                                    index={data.length + 2}
                                    unit={unit}
                                />
                            </div>
                        </div>

                        <div className="overview">
                            <p>Overview</p>

                            <div className={loading ? 'loader' : 'd-none'}>
                                <img src={loaderGif} />
                            </div>
                            <div className="ctn_charts">
                                {filterData.length > 0
                                    ? filterData.map((d, index) => {
                                        if (d.protocols.length > 0) {
                                            return (
                                                <div key={d._id} className={`ctn_chart`}>
                                                    <h5>{d.name}</h5>
                                                    <PieChart data={d.protocols} index={index + 1} color={d.color} unit={unit}/>
                                                </div>
                                            );
                                        }
                                    })
                                    : data.map((d, index) => {
                                        if (d.protocols.length > 0) {
                                            return (
                                                <div key={d._id} className={`ctn_chart`}>
                                                    <h5>{d.name}</h5>
                                                    <PieChart data={d.protocols} index={index + 1} color={d.color} unit={unit}/>
                                                </div>
                                            );
                                        }
                                    })}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default Trafic;
