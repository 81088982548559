import React from "react";
import { InterfaceType } from "../../../types";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4charts from "@amcharts/amcharts4/charts";
import { AxisLabel } from "@amcharts/amcharts4/charts";
import { convertValueUnit } from "../../../utils/Math";
import Socket from "../../../utils/Socket";

import noData from "../../../assets/images/Notfound-boitier.svg";

const lineColors = ["#417bf6", "#f21934"];

interface Props {
    data: any;
    Interfaces: Array<InterfaceType>;
    graphName: string;
    valuesLines: Array<string>;
    live: boolean;
    ping?: boolean;
    percent?: boolean;
}

interface State {
    activeInterface: string;
    lines: Array<string>;
}

class GraphLine extends React.Component<Props, State> {
    private chart: any;

    constructor(props: any) {
        super(props);
        this.state = {
            activeInterface: "",
            lines: [],
        };
    }

    getDataPerInterface = (id: string) => {
        const { data } = this.props;
        const newData = [];
        if (data && data.length > 0 && typeof data !== "string") {
            for (const d of data) {
                if (!d) continue;
                if (d.interface !== id) continue;
                newData.push({ ...d, time: new Date(d.time).getTime() });
            }
        }
        return newData;
    };

    componentDidMount() {
        if (this.props.data && this.props.data.length) {
            this.initChart();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.data !== this.props.data) {
            if (this.props.data && this.props.data.length > 0) {
                this.initChart();
            }
        }
        if (this.props.live !== prevProps.live) {
            this.liveData();
        }
    }

    initChart() {
        this.setState(() => ({ lines: this.props.valuesLines }));
        this.chart = this.createChart();
        if (this.chart) {
            this.createDateAxis();
            this.createValueAxis();
            this.createSeries();
        }
    }

    initInterface() {}

    createChart() {
        const { Interfaces } = this.props;
        if (Interfaces.length) {
            this.setState(() => ({ activeInterface: Interfaces[0]._id }));

            am4core.useTheme(am4themes_animated);
            am4core.options.minPolylineStep = 5;
            am4core.options.autoDispose = true;

            const chart = am4core.create(`chartdiv-${this.props.graphName}`, am4charts.XYChart);
            chart.data = this.getDataPerInterface(Interfaces[0]._id);
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.fullWidthLineX = true;
            chart.cursor.lineX.strokeWidth = 1;
            chart.cursor.lineY.strokeDasharray = "";
            chart.cursor.lineX.strokeDasharray = "";
            return chart;
        }
    }

    createDateAxis() {
        const dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.ticks.template.disabled = true;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.line.strokeOpacity = 1;
        dateAxis.renderer.line.strokeWidth = 2;
        dateAxis.renderer.line.stroke = am4core.color("#7e7e7e");
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.interpolationDuration = 500;
        dateAxis.rangeChangeDuration = 500;

        this.chart.cursor.xAxis = dateAxis;
        dateAxis.renderer.labels.template.fontSize = "0.5vw";

        return dateAxis;
    }

    createSeries() {
        let series: any;
        this.props.valuesLines.forEach((value, index) => {
            console.log("INDEX", lineColors[index]);
            series = this.chart.series.push(new am4charts.LineSeries());
            series.dataFields.dateX = "time";
            series.dataFields.valueY = value;
            series.dataFields.category = value;
            series.name = value;
            series.interpolationDuration = 500;
            series.defaultState.transitionDuration = 0;
            series.tooltipText = `{valueY.value}`;
            series.strokeWidth = 3;
            series.fillOpacity = 0.3;
            series.stroke = am4core.color(lineColors[index]);
            series.fill = am4core.color(lineColors[index]);
            const bullet = series.createChild(am4charts.CircleBullet);
            bullet.circle.radius = 5;
            bullet.fillOpacity = 1;
            bullet.fill = this.chart.colors.getIndex(0);
            bullet.isMeasured = false;
            series.events.on("validated", () => {
                if (this.chart.data.length > 0 && series.dataItems && series.dataItems.last) {
                    bullet.moveTo(series.dataItems.last.point);
                }
                bullet.validatePosition();
            });
            series.tensionX = 0.8;
            series.tooltip.getFillFromObject = false;
            series.tooltip.background.fill = am4core.color("#fff");
            series.tooltip.background.cornerRadius = 10;
            series.tooltip.pointerOrientation = "down";
            series.tooltip.label.fill = am4core.color("#000");
            series.tooltip.background.stroke = am4core.color("rgba(255, 255, 255, 0.7)");
            const shadow = series.tooltip.background.filters.getIndex(0);
            shadow.dx = 0;
            shadow.dy = 0;
            shadow.blur = 5;
            shadow.color = am4core.color("#c7c7c7");
            series.sequencedInterpolation = true;
            series.tooltip.disabled = false;
            series.adapter.add("tooltipText", (_: any, target: any) => {
                const data = target.tooltipDataItem.dataContext;
                if (data && value) {
                    const label = data[`${value}`];
                    let title;

                    switch (this.props.graphName) {
                        case "bande__passante":
                            title = value === "in" ? "Download" : "Upload";
                            break;
                        case "latency":
                            title = "Latence";
                            break;
                        case "jitter":
                            title = "Gigue";
                            break;
                        case "loss":
                            title = "Perte de paquets";
                            break;
                        default:
                            return;
                    }

                    return `${title} \n ${convertValueUnit(label, this.props.ping, this.props.percent)}`;
                }
            });
        });
        return series;
    }

    createValueAxis() {
        const valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.interpolationDuration = 500;
        valueAxis.rangeChangeDuration = 500;
        valueAxis.renderer.minLabelPosition = 0.05;
        valueAxis.renderer.maxLabelPosition = 0.95;
        valueAxis.renderer.ticks.template.disabled = true;
        valueAxis.renderer.grid.template.stroke = am4core.color("#7e7e7e");
        valueAxis.renderer.line.strokeOpacity = 1;
        valueAxis.renderer.line.strokeWidth = 2;
        valueAxis.renderer.line.stroke = am4core.color("#7e7e7e");
        valueAxis.renderer.labels.template.fontSize = "0.5vw";
        valueAxis.renderer.labels.template.adapter.add("text", (label: string | undefined) => {
            if (label) {
                const nLabel = parseInt(label.replace(/,/g, ""));
                return convertValueUnit(nLabel, this.props.ping, this.props.percent);
            }
        });
        return valueAxis;
    }

    toggleSlice = (id: string) => {
        this.setState(() => ({ activeInterface: id }));
        this.chart.data = this.getDataPerInterface(id);
    };

    toggleLine = (values: Array<string>) => {
        this.setState(() => ({ lines: values }));
        if (`${values}` === "in") {
            this.chart.series.getIndex(0).show();
            this.chart.series.getIndex(1).hide();
        } else if (`${values}` === "out") {
            this.chart.series.getIndex(1).show();
            this.chart.series.getIndex(0).hide();
        } else {
            this.chart.series.getIndex(1).show();
            this.chart.series.getIndex(1).show();
        }
    };

    renderFiltersInterfaces = (interfaces: Array<InterfaceType>) =>
        interfaces.map((inter, index) => (
            <div
                key={index}
                style={{ display: inter.enabled ? "block" : "none" }}
                className="legend-item"
                id={`legend-item-${inter._id}`}
                onClick={() => this.toggleSlice(inter._id)}
            >
                <div
                    className="legend-marker"
                    style={{
                        background:
                            this.state.activeInterface === inter._id && this.chart
                                ? this.chart.colors.getIndex(index)
                                : "transparent",
                    }}
                ></div>
                {inter.name}
            </div>
        ));

    liveData() {
        const { live, graphName } = this.props;
        if (live) {
            let prevTime = "";
            Socket.readChange("Sla", (resSla: any) => {
                if (resSla.data.interface === this.state.activeInterface) {
                    if (prevTime !== resSla.data.time) {
                        if (graphName === "bande__passante") {
                            Socket.readLast("Interfaces_stat", { interface: this.state.activeInterface }, {}).then(
                                (res) => {
                                    const { data } = res;
                                    if (data) {
                                        this.chart.addData({ ...data, time: new Date(data.time).getTime() }, 1);
                                    }
                                }
                            );
                        } else {
                            this.chart.addData(
                                {
                                    ...resSla.data,
                                    time: new Date(resSla.data.time).getTime(),
                                },
                                1
                            );
                        }
                        prevTime = resSla.data.time;
                    }
                }
            });
        }
    }

    interfacesPerType = () => {
        const { Interfaces } = this.props;
        const interfacesVirtual = [];
        const interfacesPhysical = [];
        for (const inter of Interfaces) {
            if (inter.type === "virtuelle") interfacesVirtual.push(inter);
            if (inter.type === "physical") interfacesPhysical.push(inter);
        }
        return { interfacesVirtual, interfacesPhysical };
    };

    render() {
        const { interfacesVirtual, interfacesPhysical } = this.interfacesPerType();
        const { data } = this.props;
        return (
            <div className={"GraphLine"}>
                {interfacesPhysical.length > 0 && (
                    <div className={"legend"}>
                        <label className={"legend__label"}>Afficher les interfaces physiques</label>
                        <div className={"legend__items"}>{this.renderFiltersInterfaces(interfacesPhysical)}</div>
                    </div>
                )}
                {interfacesVirtual.length > 0 && (
                    <div className={"legend"}>
                        <label className={"legend__label"}>Afficher les interfaces virtuelles</label>
                        <div className={"legend__items"}>
                            {interfacesVirtual.length && this.renderFiltersInterfaces(interfacesVirtual)}
                        </div>
                    </div>
                )}
                {this.props.valuesLines.length > 1 && interfacesVirtual.length > 0 && interfacesPhysical.length > 0 && (
                    <div className={"legend"}>
                        <label className={"legend__label"}>Afficher les vues</label>
                        <div className={"legend__items"}>
                            <div className="legend-item" onClick={() => this.toggleLine(["in", "out"])}>
                                <div
                                    className="legend-marker"
                                    style={{ background: `${this.state.lines}` === `in,out` ? "black" : "transparent" }}
                                />
                                Bande Passante Up/Down
                            </div>

                            <div className="legend-item" onClick={() => this.toggleLine(["out"])}>
                                <div
                                    className="legend-marker"
                                    style={{ background: `${this.state.lines}` === "out" ? "black" : "transparent" }}
                                />
                                Bande Passante Up
                            </div>

                            <div className="legend-item" onClick={() => this.toggleLine(["in"])}>
                                <div
                                    className="legend-marker"
                                    style={{ background: `${this.state.lines}` === "in" ? "black" : "transparent" }}
                                />
                                Bande Passante Down
                            </div>
                        </div>
                    </div>
                )}
                <div className="tokrtork">
                    {data && data.length === 0 && typeof data !== "string" ? (
                        <div className="ctn__noData">
                            <img src={noData} alt="" />
                        </div>
                    ) : (
                        <div id={`chartdiv-${this.props.graphName}`} className={"graph"} />
                    )}
                </div>
            </div>
        );
    }
}

export default GraphLine;
