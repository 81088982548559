import React from 'react';
import { routes } from '../../../routes/Routes';
import WanupLogo from '../../../assets/images/WANUP-LOGO.svg';
import Link from './Link/Link';
import Authorization from '../../Authorization/Authorization';
import SayseLogo from '../../../assets/images/logo_sayse_blanc_without_baseline.png';
import { connect, ConnectedProps } from "react-redux";
import { RouteComponentProps } from 'react-router';

import Socket from '../../../utils/Socket';
import User from '../../../utils/User';
import { Button } from 'antd';


interface ReduxState {
}


type PathParams = {
    pathname: string;
};



interface State {

}


interface Props extends RouteComponentProps<PathParams> {
    roles: any
}


class Navbar extends React.Component<any, Props> {
    constructor(props: any) {
        super(props);
    }

    componentDidMount() {
        this.UserRoles();
    }

    componentDidUpdate(prevState: any) {
        // if(prevState.roles !== this.state.roles) {
        //     this.UserRoles();
        // }
    }

    UserRoles() {
        if (User.token()) {
            User.userInfo().then((res) => {
                Socket.readLean('Roles', { _id: res.role_id }, {})
                    .then(res => {
                        this.setState({ roles: res.data[0].permission_wanup })
                        // this.props.defineUserRoles(res.data[0].permission_wanup)
                    })
                    .catch(err => console.error(err))
            }).catch(err => console.error(err))
        }
    }


    // todo remettre authorization
    render() {
        const { roles } = this.props
        return (
            <nav className={'Navbar'}>
                <img src={WanupLogo} alt={'logo_wanup'} />
                <ul>
                    {routes.map((route) => (
                        <Authorization key={route.name} type={'read'} page={route.name} data={roles[0]}>
                            <Link name={route.name} icon={route.icon} data={roles[0]} />
                        </Authorization>
                    ))}
                </ul>
                <img className={'logo__sayse'} src={SayseLogo} />
            </nav>
        );
    }
}

export default Navbar;
