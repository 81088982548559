import React from "react";
import ContainerRestPage from "../../../common/ContainerRestPage/ContainerRestPage";
import BasicTable from "../../../common/BasicTable/BasicTable";
import {ServerLdap} from "../../../types";
import AddButton from "../../../common/AddButton/AddButton";
import LDAPForm from "./LDAPForm/LDAPForm";
import BarTableActions from "../../../common/BarTableAction/BarTableActions";
import Socket from "../../../utils/Socket";

const columns = [
    {
        Header: 'Name',
        accessor: 'name',
        sort: true
    },
    {
        Header: 'Adresse IP',
        accessor: 'ip_server',
        sort: true
    },
    {
        Header: 'Login',
        accessor: 'login',
        sort: true
    },
    {
        Header: 'Password',
        accessor: 'password',
        sort: true
    },
    {
        Header: 'Requête',
        accessor: 'request',
        sort: true
    },

]

interface Props {
    ServerLdap: Array<ServerLdap>,
    role: Array<any>,
    entityID: string
}

interface State {
    visibleForm: boolean,
    selectedItems: string[],
    formType: string,
}
export default class Ldap extends React.Component<Props, State> {


    constructor(props: any) {
        super(props);
        this.state = {
            visibleForm: false,
            selectedItems: [],
            formType: '',
        };
    }

    openForm = (formType: string, selectedItem?: string): void => {
        if (selectedItem) {
            this.setState(() => ({ visibleForm: true, formType, selectedItems: [selectedItem] }));
        } else {
            this.setState(() => ({ visibleForm: true, formType }));
        }
    };

    closeForm = (): void => {
        this.setState(() => ({ visibleForm: false, formType: '', selectedItems: [] }));
    };

    addElement = () => {
        this.openForm('create');
    };

    closeEdit = (): void => {
        this.setState((state) => ({
            ...state,
            selectedItems: [],
        }));
    };

    deleteItems = async (): Promise<void> => {
        const { selectedItems } = this.state;
        const { ServerLdap } = this.props;
        if (ServerLdap) {
            try {
                const ids = ServerLdap.filter((n) => selectedItems.includes(n._id)).map((n) => n.entity_id);
                const res = await Socket.deleteMany('Server_Ldap', ids, selectedItems);
                if (res.status === 200) this.setState(() => ({ selectedItems: [] }));
            } catch (e) {
                console.error(e);
            }
        }
    };


    selectItems = (ids: Array<string>): void => {
        this.setState(() => ({ selectedItems: ids }));
    };

    render() {
        return (
            <div>
                <AddButton str={'un LDAP'} onClick={this.addElement} role={this.props.role}/>
                <ContainerRestPage>
                    <BasicTable openForm={this.openForm} selectedItems={this.state.selectedItems}
                                selectItems={this.selectItems} columns={columns} data={this.props.ServerLdap} tableName={'ldap__table'} />
                </ContainerRestPage>
                <BarTableActions
                    selectedItems={this.state.selectedItems}
                    openForm={this.openForm}
                    deleteItems={this.deleteItems}
                    closeBar={this.closeEdit}
                    role={this.props.role}
                />
                <LDAPForm
                    ServerLdap={this.props.ServerLdap}
                    entityID={this.props.entityID}
                    selectedItems={this.state.selectedItems}
                    onClose={this.closeForm}
                    visible={this.state.visibleForm}
                    formType={this.state.formType}
                />
            </div>
        )
    }
}