import React from "react";
import { InputNumber, Tooltip } from "antd";
import { debounce } from "throttle-debounce";

interface Props {
    className: string;
    placeholder: string;
    name: string;
    tooltip?: string;
    value: any;
    changeValue: (name: string, value: number) => void;
    type: string;
    disabled?: boolean;
    required?: boolean;
    color?: string;
    min: number;
    max: number;
    formatter?: any;
    keyboard?: boolean;
    style?: React.CSSProperties;
}

interface State {
    name: string;
    q: number;
}

class InputNumbers extends React.PureComponent<Props, State> {
    // eslint-disable-next-line @typescript-eslint/ban-types
    private autocompleteSearchDebounced: Function;

    constructor(props: any) {
        super(props);
        this.state = {
            name: "",
            q: 0,
        };
        this.autocompleteSearchDebounced = debounce(1000, this.autocompleteSearch);
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState((prevState) => ({ ...prevState, q: this.props.value }));
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.value !== this.props.value) {
            this.setState((prevState) => ({ ...prevState, q: this.props.value }));
        }
    }

    autocompleteSearch = (q: number) => {
        this.props.changeValue(this.state.name as string, q);
    };

    changeValue = (value: number | null) => {
        if (value) {
            this.setState(
                () => ({ name: this.props.name, q: value }),
                () => {
                    this.autocompleteSearchDebounced(this.state.q);
                }
            );
        }
        //  else {
        //     this.props.changeValue(this.props.name, value);
        // }
    };

    render() {
        const { className, placeholder, name, type, disabled, min, max, formatter, keyboard } = this.props;

        return this.props.tooltip ? (
            <Tooltip placement="topLeft" title={this.props.tooltip}>
                {
                    <InputNumber
                        className={`TextField ${className}`}
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        value={this.props.value}
                        onChange={this.changeValue}
                        disabled={disabled}
                        style={this.props.style}
                        min={min}
                        max={max}
                        formatter={formatter}
                        keyboard={keyboard}
                        controls={false}
                        data-required={true}
                    />
                }
            </Tooltip>
        ) : (
            <InputNumber
                className={`TextField ${className}`}
                type={type}
                name={name}
                placeholder={placeholder}
                value={this.props.value}
                onChange={this.changeValue}
                disabled={disabled}
                style={this.props.style}
                min={min}
                max={max}
                formatter={formatter}
                keyboard={keyboard}
                controls={false}
                data-required={true}
            />
        );
    }
}

export default InputNumbers;
