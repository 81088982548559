import React from 'react';
import Authorization from '../Authorization/Authorization';
import IconAdd from '../SVGComponents/IconAdd';

interface Props {
    str: string;
    onClick: () => void;
    role?: any
}

class AddButton extends React.Component<Props> {

    //todo remettre authorzation
    render() {
        const {str, onClick, role} = this.props;
        const key = window.location.pathname.substring(1)
        return (
            <Authorization type={'write'} page={key} data={role[0]}>
                <button className={'AddButton'} onClick={onClick}>
                    <IconAdd/>
                    <span>Ajouter {str}</span>
                </button>
            </Authorization>
        );
    }
}

export default AddButton;
