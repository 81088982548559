import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { EntityType, GroupNetworkType, GroupPortType } from "../../../../types";
import Collections from "../../../../utils/Collections";
import GroupPort from "./GroupPort/GroupPort";
import { CollectionsFields } from "../../../../store/types";
import { udpateCollections } from "../../../../store/actions";
import { Button } from "antd";
import { groupNetworkState, groupPortState } from "../../../../state";
import TextField from "../../../../common/TextField/TextField";
import { matchSorter } from "match-sorter";

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
    };
    global: {
        entity_id: string;
        roles: Array<any>;
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
        entityId: state.global.entity_id,
        role: state.global.roles,
    };
};

const mapDispatchToProps = {
    udpateCollection: (collection: CollectionsFields, data: Array<EntityType>) => udpateCollections(collection, data),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    groupPorts: Array<GroupPortType>;
    changeGroupeValue: (type: "groupNetworks" | "groupPorts", data: Array<GroupPortType | GroupNetworkType>) => void;
}

interface State {
    search: string;
}

class GroupPorts extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            search: "",
        };
    }

    changeValue = (groupPorts: Array<GroupPortType>) => {
        this.props.changeGroupeValue("groupPorts", groupPorts);
    };

    addGroupe = () => {
        const newGroupe = [
            ...this.props.groupPorts,
            { ...groupPortState, entity_id: this.props.entityId, ports: [{ value: "" }] },
        ];
        this.props.changeGroupeValue("groupPorts", newGroupe);
    };

    render() {
        const { role, groupPorts } = this.props;

        return (
            <div className={"GroupPorts"}>
                <div className="objects__title">
                    <h2>Objets Ports</h2>
                </div>
                {groupPorts.map((groupPort, index) => (
                    <GroupPort
                        key={groupPort._id}
                        groupPort={groupPort}
                        groupPorts={groupPorts}
                        groupPortIndex={index}
                        changeValue={this.changeValue}
                        role={role}
                        indexPorts={index + 1}
                    />
                ))}
                <Button htmlType="button" onClick={this.addGroupe} className={"btn__add__group btn__entry__update"}>
                    Ajouter un groupe
                </Button>
            </div>
        );
    }
}

export default connector(GroupPorts);
