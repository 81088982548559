import openSocket from "socket.io-client";
import User from "./User";
import {
    ApplicationTypes,
    CollectionTypes,
    EntityType,
    EquipmentType,
    GroupNetworkType,
    GroupPortType,
    ResponseReadSuccess,
    InterconnexionType,
    ResultCollectionAPI,
    RoutageType,
    GatewayType,
    ModelBuildingType,
} from "../types";

const VAR_ENV_URL: any = process.env.REACT_APP_BACK_URL;

export const socket = openSocket(VAR_ENV_URL);
// const ENV_URL: any = process.env.REACT_APP_BACK_URL
// export const socket = openSocket(ENV_URL);

class Socket {
    static read(collection: string, filter?: any): Promise<ResponseReadSuccess> {
        return new Promise((resolve, reject) => {
            const token = User.token();
            socket.emit("read", { token, collection, filter }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static readLean(collection: string, filter: any, keys: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const token = User.token();
            socket.emit("readLean", { token, collection, filter, keys }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static readLast(collection: string, filter: any, keys: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const token = User.token();
            socket.emit("readLast", { token, collection, filter, keys }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static readInterface(collection: string, filter: any, keys: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const token = User.token();
            socket.emit("readInterface", { token, collection, filter, keys }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static readNotifications(collection: string, filter: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const token = User.token();
            socket.emit("readNotifications", { token, collection, filter }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static modele(
        collection: "Gateways" | "Routage",
        data: Array<GatewayType | RoutageType>,
        removeType: Array<String>,
        dataModelBuilding: ModelBuildingType,
        entityId: string,
        formType: string
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            socket.emit(
                "modele",
                { collection, data, removeType, dataModelBuilding, entityId, formType },
                (err: any, res: any) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(res);
                    }
                }
            );
        });
    }

    static interconnexion(
        collection: "Interconnexion",
        selectedSource: string,
        interconnexion: Array<InterconnexionType>,
        newsInterco: Array<string>,
        entityId: string
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            socket.emit(
                "interconnexion",
                { collection, selectedSource, interconnexion, newsInterco, entityId },
                (err: any, res: any) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(res);
                    }
                }
            );
        });
    }

    static updateGroups(
        collection: "GroupeNetwork" | "GroupPorts",
        originalGroups: any,
        groups: Array<GroupPortType | GroupNetworkType>,
        entityId: string
    ): Promise<any> {
        return new Promise((resolve, reject) => {
            socket.emit("updateGroups", { collection, originalGroups, groups, entityId }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }
    static insert(collection: string, entityId: string | null, data: ApplicationTypes): Promise<any> {
        console.log(data);
        const token = User.token();
        return new Promise((resolve, reject) => {
            socket.emit("insert", { collection, entityId, token, data }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static insertMany(collection: string, entitiesIds: Array<string>, data: ApplicationTypes): Promise<any> {
        const token = User.token();
        return new Promise((resolve, reject) => {
            socket.emit("insertMany", { token, entitiesIds, collection, data }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static update(
        collection: string,
        id: string,
        entityId: string | null | undefined,
        newData: CollectionTypes
    ): Promise<any> {
        const token = User.token();
        return new Promise((resolve, reject) => {
            socket.emit("update", { collection, id, entityId, newData, token }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static updateDevice(entityId: string, data: EquipmentType): Promise<any> {
        const token = User.token();
        return new Promise((resolve, reject) => {
            socket.emit("updateDevice", { token, entityId, data }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static updateMany(
        collection: string,
        id: string,
        entitiesIds: Array<string>,
        newData: ApplicationTypes
    ): Promise<any> {
        const token = User.token();
        return new Promise((resolve, reject) => {
            socket.emit("updateMany", { token, id, entitiesIds, collection, newData }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static updateOtherUsers(collection: string, id: string, entityId: string, newData: ApplicationTypes): Promise<any> {
        const token = User.token();
        return new Promise((resolve, reject) => {
            socket.emit("updateOtherUsers", { collection, id, entityId, newData, token }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static remove(collection: string, entityId: string, id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const token = User.token();
            socket.emit("remove", { token, entityId, collection, id }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static deleteManyIds(collection: string, entityId: string, ids: Array<string>): Promise<any> {
        const token = User.token();
        return new Promise((resolve, reject) => {
            socket.emit("deleteManyIds", { token, entityId, collection, ids }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }
    static deleteMany(collection: string, entitiesIds: Array<string>, id: Array<string>): Promise<any> {
        const token = User.token();
        return new Promise((resolve, reject) => {
            socket.emit("removeMany", { token, entitiesIds, collection, id }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static createUser(user: any): Promise<any> {
        return new Promise((resolve, reject) => {
            socket.emit("createUser", user, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static loginUser(user: any): Promise<any> {
        return new Promise((resolve) => {
            socket.emit("login", user, (res: any) => {
                resolve(res);
            });
        });
    }

    static isAdmin(token: string | null): Promise<any> {
        return new Promise((resolve, reject) => {
            socket.emit("isAdmin", token, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static getUserInfo(token: any): Promise<any> {
        return new Promise((resolve, reject) => {
            socket.emit("getUserInfo", token, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res.data);
                }
            });
        });
    }

    static checkValidateToken(token: string): Promise<any> {
        return new Promise((resolve, reject) => {
            socket.emit("validateToken", token, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res.data);
                }
            });
        });
    }

    static forgotPassword(email: string): Promise<any> {
        return new Promise((resolve, reject) => {
            socket.emit("forgotPassword", email, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res.data);
                }
            });
        });
    }

    static resetPassword(token: string, password: string): Promise<any> {
        return new Promise((resolve, reject) => {
            socket.emit("resetPassword", { token, password }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res.data);
                }
            });
        });
    }

    static resetPasswordWithoutToken(email: string, password: string): Promise<any> {
        return new Promise((resolve, reject) => {
            socket.emit("resetPasswordWithoutToken", { email, password }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res.data);
                }
            });
        });
    }

    static resetPasswordVanko(token: string, lastPassword: string, verifypsw: string, newPwd: string): Promise<any> {
        return new Promise((resolve, reject) => {
            socket.emit("resetPasswordVanko", { token, lastPassword, verifypsw, newPwd }, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res.data);
                }
            });
        });
    }

    static readTest(entity: EntityType): Promise<Array<EntityType>> {
        return new Promise((resolve) => {
            socket.emit("readTest", entity, User.token(), (res: Array<EntityType>) => {
                resolve(res);
            });
        });
    }

    static readTestToken(collection: string, filter: any): Promise<ResultCollectionAPI> {
        return new Promise((resolve) => {
            socket.emit("readTestToken", collection, filter, User.token(), (res: ResultCollectionAPI) => {
                resolve(res);
            });
        });
    }

    static live(callback: any): void {
        socket.on(`live`, (res: any) => {
            callback(res);
        });
    }

    static disconnect(callback: any): void {
        socket.on("disconnect", (status: string) => {
            callback(status);
        });
    }

    static leaveAllRooms(): void {
        socket.emit("leaveAllRooms");
    }

    //--------------------------------------------------------------------------

    static lastInterface(collection: string, filter: any): Promise<any> {
        return new Promise((resolve) => {
            socket.emit("lastInterface", collection, filter, (res: any) => {
                resolve(res);
            });
        });
    }

    static charge(): Promise<any> {
        return new Promise((resolve) => {
            socket.on("readStatus", (res: any) => {
                resolve(res);
            });
        });
    }

    static readChange(collection: string, callback: any): any {
        socket.on(`read${collection}`, (res: any) => {
            callback(res);
        });
    }

    static closeWatch(): any {
        socket.emit("closeWatch");
    }

    static getTrafficDetails(filters: any): Promise<ResponseReadSuccess> {
        return new Promise((resolve, reject) => {
            socket.emit("getTrafficDetails", filters, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }
    

    static getTop10Source(withProtocol: boolean, filters: any): Promise<ResponseReadSuccess> {
        return new Promise((resolve, reject) => {
            socket.emit("getTop10Source", withProtocol, filters, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

    static getTop10Destination(withProtocol: boolean, filters: any): Promise<ResponseReadSuccess> {
        return new Promise((resolve, reject) => {
            socket.emit("getTop10Destination", withProtocol, filters, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }
    static getTop10Sankey(filters: any): Promise<ResponseReadSuccess> {
        return new Promise((resolve, reject) => {
            socket.emit("getTop10Sankey", filters, (err: any, res: any) => {
                if (err) {
                    reject(err);
                } else {
                    resolve(res);
                }
            });
        });
    }

}

export default Socket;
