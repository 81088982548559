import React from 'react';
import Fade from "../../common/Fade/Fade";
import {Tabs} from 'antd';
import Ldap from "../../components/Setting/LDAP/Ldap";
import Users from "../../components/Setting/Users/Users";
import Logs from "../../components/Setting/Logs/Logs";
import {EntityType, GroupPortType} from "../../types";
import {connect, ConnectedProps} from "react-redux";
import {logState, ServerLdapState, groupPortState, groupNetworkState} from "../../state";
import NetworksObjects from "../../components/Setting/NetworksObjects/NetworksObjects";
import PortsObjects from "../../components/Setting/PortsObjects/PortsObjects";
import './Settings.scss';

const {TabPane} = Tabs;


interface ReduxState {
    collections: {
        Entity: EntityType[]
    },
    global: {
        entity_id: string;
        roles: Array<any>
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        Entity: state.collections.Entity,
        roles: state.global.roles,
        entity_id: state.global.entity_id
    }
}

const mapDispatchToProps = {}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;


class Setting extends React.Component<PropsFromRedux> {

    componentDidMount() {
        document.title = 'Wanup | Paramètre';

    }

    render() {

		console.log(this.props.Entity)

	    const Server_Ldap = this.props.Entity.flatMap(entity => entity.Server_Ldap ? entity.Server_Ldap : ServerLdapState)
	    const logs = this.props.Entity.flatMap(entity => entity.Logs || logState)
	    const GroupPorts = this.props.Entity.flatMap(entity => entity.GroupPorts || groupPortState)
	    const GroupeNetwork = this.props.Entity.flatMap(entity => entity.GroupeNetwork || groupNetworkState)

        return (
            <div className={'Setting ContainerMainPageTabs'}>
                <Fade>
                    <Tabs className={'tabs'} destroyInactiveTabPane={true}>
                        <TabPane key={'LDAP'} tab={'LDAP'}>
                            <Ldap ServerLdap={Server_Ldap} role={this.props.roles} entityID={this.props.entity_id}/>
                        </TabPane>
                        <TabPane key={'Users'} tab={'Utilisateurs'}>
                            <Users Users={[]} Entity={this.props.Entity} entityID={this.props.entity_id}
                                   role={this.props.roles}/>
                        </TabPane>
                        <TabPane key={'Logs'} tab={'Logs'}>
                            <Logs Logs={logs} entityID={this.props.entity_id} role={this.props.roles} entities={this.props.Entity}/>
                        </TabPane>
                        <TabPane key={'NetworksObjects'} tab={'Objets réseaux'}>
                            <NetworksObjects Entity={this.props.Entity} entityID={this.props.entity_id}/>
                        </TabPane>
                        <TabPane key={'PortsObjects'} tab={'Objets ports'}>
                            <PortsObjects Entity={this.props.Entity} entityID={this.props.entity_id}/>
                        </TabPane>
                    </Tabs>
                </Fade>
            </div>
        )
    }

}

export default connector(Setting);