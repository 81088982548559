import React from "react";
import { Tabs } from "antd";
import Details from "./Details/Details";
import Dns from "./Dns/Dns";
import Dhcp from "./Dhcp/Dhcp";
import Relais from "./Relais/Relais";
import {
    DhcpOptionType,
    DhcpStatiqueType,
    EntryType,
    GatewayType,
    InterfaceAddressType,
    InterfaceType,
} from "../../../../../../types";
import Arp from "./Arp/Arp";

const { TabPane } = Tabs;

interface Props {
    interf: InterfaceType;
    interfaceIndex: number;
    Interfaces: Array<InterfaceType> | undefined;
    equipmentId: string;
    changeValue: (
        path: string,
        value:
            | string
            | boolean
            | InterfaceType
            | Array<InterfaceType | InterfaceAddressType | EntryType | DhcpOptionType | DhcpStatiqueType | string>
    ) => void;
    role: any;
    zone?: string;
    gateways?: Array<GatewayType>;
    activeNavInterface?: string;
}
interface State {
    activeKey: string;
}

class InterfacePanel extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            activeKey: this.getActiveTabKey(),
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.activeNavInterface !== this.props.activeNavInterface || prevProps.interf !== this.props.interf) {
            this.setState({ activeKey: this.getActiveTabKey() });
        }
    }

    getActiveTabKey = () => {
        const { activeNavInterface } = this.props;
        console.log("activeNavInterface", activeNavInterface);
        switch (activeNavInterface) {
            case "details":
                return "details";
            case "dns":
                return "dns";
            case "dhcp":
                return "dhcp";
            case "relais":
                return "relais";
            case "arp":
                return "arp";
            default:
                return "details";
        }
    };

    onTabChange = (key: any) => {
        this.setState({ activeKey: key });
    };

    render() {
        const { interf, interfaceIndex, changeValue, Interfaces, role, equipmentId, zone, gateways } = this.props;
        const { activeKey } = this.state;

        return (
            <Tabs defaultActiveKey="details" activeKey={activeKey} onChange={this.onTabChange}>
                <TabPane forceRender={true} tab={"Details"} key="details">
                    <Details
                        interfaceAddresses={interf.Interface_address}
                        filtering={interf.filtering}
                        interf={interf}
                        interfaceIndex={interfaceIndex}
                        changeValue={changeValue}
                        Interfaces={Interfaces}
                        role={role}
                        equipmentId={equipmentId}
                        zone={zone === undefined ? "WAN" : zone}
                        gateways={gateways}
                    />
                </TabPane>
                {zone === "LAN" && (
                    <>
                        <TabPane forceRender={true} tab={"DNS"} key="dns">
                            <Dns
                                dns={interf.Dns}
                                interfaceIndex={interfaceIndex}
                                changeValue={changeValue}
                                role={role}
                                interf={interf}
                            />
                        </TabPane>
                        <TabPane forceRender={true} tab={"DHCP"} key="dhcp">
                            <Dhcp
                                dhcp={interf.Dhcp}
                                interfaceIndex={interfaceIndex}
                                changeValue={changeValue}
                                role={role}
                                interf={interf}
                            />
                        </TabPane>
                        <TabPane forceRender={true} tab={"Relais"} key="relais">
                            <Relais
                                relais={interf.Relais}
                                interfaceIndex={interfaceIndex}
                                changeValue={changeValue}
                                role={role}
                                interf={interf}
                            />
                        </TabPane>
                    </>
                )}
                {interf.enabled && interf.state !== "down" && (
                    <TabPane forceRender={true} tab={"ARP"} key="arp">
                        <Arp
                            arp={interf.arp}
                            interfaceIndex={interfaceIndex}
                            changeValue={changeValue}
                            role={role}
                            interf={interf}
                        />
                    </TabPane>
                )}
            </Tabs>
        );
    }
}

export default InterfacePanel;
