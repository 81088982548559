import React from 'react';
import { Tabs } from 'antd';
import { DashboardType } from '../../../../types';
import Chart from './Chart/Chart';

const { TabPane } = Tabs;

interface Props {
    Dashboard: DashboardType | undefined;
}

class AccessIp extends React.Component<Props> {
    render() {
        const { Dashboard } = this.props;
        console.log("DASHBOARD", Dashboard);
        return (
            <div className={`Operators`}>
                <h3>Accès IP</h3>
                <div className={'ctn__charts'}>
                    <Tabs>
                        <TabPane tab={'Opérateurs'} key={'operator'}>
                            <div className="ctn__chart__access__ip">
                                <Chart
                                    data={Dashboard ? Dashboard.operators : [{ operator: 'data not found', count: 0 }]}
                                    name={'operator'}
                                />
                            </div>
                        </TabPane>
                        <TabPane tab={'Technologies'} key={'technology'}>
                            <div className="ctn__chart__access__ip">
                                <Chart
                                    data={Dashboard ? Dashboard.technologies : [{ technology: 'data not found', count: 0 }]}
                                    name={'technology'}
                                />
                            </div>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default AccessIp;
