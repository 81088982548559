import React from "react";
import BasicDrawerForm from "../../../../common/BasicDrawerForm/BasicDrawerForm";
import { Tabs } from "antd";
import Informations from "./Informations/Informations";
import Interfaces from "./Interfaces/Interfaces";
import Collections from "../../../../utils/Collections";
import axios from "axios";
import {
    DhcpOptionType,
    EntityType,
    EntryType,
    EquipmentType,
    GatewayType,
    GroupNetworkType,
    GroupPortType,
    InterfaceAddressType,
    InterfaceType,
    RoutageType,
    DhcpStatiqueType,
} from "../../../../types";
import SelectSimple from "../../../../common/SelectSimple/SelectSimple";

import { equipmentState } from "../../../../state";
import Passerelles from "./Passerelles/Passerelles";
import Routages from "./Routages/Routages";
import { changeValueWithPathName } from "../../../../utils/ChangeValue";
import { connect, ConnectedProps } from "react-redux";
import Socket from "../../../../utils/Socket";
import equipements from "../../../../views/Equipements/Equipements";
import Loader from "../../../../common/Loader/Loader";

const { TabPane } = Tabs;

interface ReduxState {
    global: { collapseEquipmentForm: string };
    collections: {
        Entity: Array<EntityType>;
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        collapseEquipmentForm: state.global.collapseEquipmentForm,
        entities: state.collections.Entity,
    };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    visible: boolean;
    onClose: () => void;
    formType: string;
    selectedItem: string;
    Equipments: Array<EquipmentType>;
    entityId: string;
    changeEquipment: (id: string) => void;
    roles: any;
}

interface State {
    equipment: EquipmentType;
    activeTab: string;
    groupNetworks: Array<GroupNetworkType>;
    groupPorts: Array<GroupPortType>;
    originalGroupNetworks: Array<GroupNetworkType>;
    originalGroupPorts: Array<GroupPortType>;
    loader: boolean;
}

class EquipmentForm extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            equipment: equipmentState,
            activeTab: "Informations",
            groupNetworks: [],
            groupPorts: [],
            originalGroupNetworks: [],
            originalGroupPorts: [],
            loader: false,
        };
    }

    componentDidMount() {
        this.initGroups();
    }

    initGroups() {
        const { entities } = this.props;
        this.setState(() => ({
            groupNetworks: Collections.flatRecursive(entities, "GroupeNetwork", [], ""),
            groupPorts: Collections.flatRecursive(entities, "GroupPorts", [], ""),
            originalGroupNetworks: Collections.flatRecursive(entities, "GroupeNetwork", [], ""),
            originalGroupPorts: Collections.flatRecursive(entities, "GroupPorts", [], ""),
        }));
    }

    removeNewGatewaysTmpIds = () => {
        return new Promise((resolve) => {
            const oldEquipment = this.props.Equipments.find((equipment) => this.props.selectedItem === equipment._id);
            if (oldEquipment && oldEquipment.Interface) {
                const Gateways = oldEquipment.Gateways;
                const refacGateways = [];
                if (this.state.equipment.Gateways) {
                    for (const newGateways of this.state.equipment.Gateways) {
                        // @ts-ignore
                        if (Gateways.find((i) => i._id === newGateways._id)) {
                            refacGateways.push(newGateways);
                            continue;
                        }
                        newGateways._id = "";
                        refacGateways.push(newGateways);
                    }
                    resolve(refacGateways);
                }
            }
        });
    };

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.entities !== this.props.entities) {
            this.initGroups();
        }
        if (prevProps.selectedItem !== this.props.selectedItem) {
            if (this.props.selectedItem.length > 0 && this.props.Equipments && this.props.Equipments.length > 0) {
                const equipmentFind = this.props.Equipments.find(
                    (equipment) => this.props.selectedItem === equipment._id
                );
                if (equipmentFind) {
                    this.setState((state) => ({ ...state, equipment: equipmentFind }));
                }
            } else {
                this.setState((state) => ({ ...state, equipment: equipmentState }));
            }
        }
        if (prevProps.formType !== this.props.formType && !this.props.formType) {
            this.setState(() => ({
                equipment: { ...equipmentState, entity_id: this.props.entityId },
                activeTab: "Informations",
            }));
        }
        if (prevProps.collapseEquipmentForm !== this.props.collapseEquipmentForm) {
            if (this.state.activeTab !== "Interfaces") this.setState(() => ({ activeTab: "Interfaces" }));
        }
    }

    getOriginalEquipment() {
        const equipments = Collections.flatRecursive(this.props.entities, "Equipment", [], "");
        const originalEquipment = equipments.find((equipment) => this.props.selectedItem === equipment._id);
        return originalEquipment;
    }

    getRemoveIds(originalData: Array<{ _id: string }>, currentData: Array<{ _id: string }>) {
        const ids: string[] = [];
        originalData.forEach((d) => {
            if (!currentData.map((g) => g._id).includes(d._id)) {
                ids.push(d._id);
            }
        });
        return ids;
    }

    submit = async () => {
        const { equipment, groupPorts, groupNetworks, originalGroupNetworks, originalGroupPorts } = this.state;
        const {
            Gateways: originalGateways,
            Routage: originalRoutages,
            Interface: originalInterface,
        } = this.getOriginalEquipment();
        const { Gateways, Routage, Interface } = equipment;

        this.setState(() => ({ loader: true }));
        let promises = [];

        if (Gateways) {
            const removeIds = this.getRemoveIds(originalGateways, Gateways);
            promises.push(Socket.deleteManyIds("Gateways", equipment.entity_uuid, removeIds));
        }

        if (Routage) {
            const removeIds = this.getRemoveIds(originalRoutages, Routage);
            promises.push(Socket.deleteManyIds("Routage", equipment.entity_uuid, removeIds));
        }
        if (Interface) {
            let oldAddress: InterfaceAddressType[] = [];
            let newAddress: InterfaceAddressType[] = [];
            Interface.map((Interfaces, _) => {
                const { Interface_address } = Interfaces;
                if (
                    originalInterface[_] !== undefined &&
                    originalInterface[_].Interface_address.length > 0 &&
                    Interface_address &&
                    Interface_address.length > 0
                ) {
                    originalInterface[_].Interface_address.map((elem: InterfaceAddressType) => {
                        oldAddress.push(elem);
                    });
                    Interface_address?.map((elem: InterfaceAddressType) => {
                        newAddress.push(elem);
                    });
                }
            });

            const removeIds = this.getRemoveIds(oldAddress, newAddress);
            promises.push(Socket.deleteManyIds("Interface_address", equipment.entity_uuid, removeIds));
            const removeIdsInterf = this.getRemoveIds(originalInterface, Interface);
            promises.push(Socket.deleteManyIds("Interface", equipment.entity_uuid, removeIdsInterf));
        }
        const nGateways = await this.removeNewGatewaysTmpIds();
        // @ts-ignore
        equipment.Gateways = nGateways;
        promises.push(Socket.updateDevice(equipment.entity_uuid, equipment));
        if (JSON.stringify(originalGroupNetworks) !== JSON.stringify(groupNetworks)) {
            Socket.updateGroups("GroupeNetwork", originalGroupNetworks, groupNetworks, this.props.entityId);
        }
        if (JSON.stringify(originalGroupPorts) !== JSON.stringify(groupPorts)) {
            Socket.updateGroups("GroupPorts", originalGroupPorts, groupPorts, this.props.entityId);
        }
        Promise.all(promises)
            .then((res) => {
                this.props.onClose();
                this.setState(() => ({ loader: false }));
            })
            .catch((err) => {
                console.error(err);
            });
    };

    drawerHeader = () => {
        const { Equipments, changeEquipment, selectedItem } = this.props;
        const { loader } = this.state;
        return (
            <div className={"ctn__select"}>
                {!loader && (
                    <SelectSimple
                        items={Equipments}
                        value={selectedItem}
                        valuesName={"_id"}
                        valuesDisplay={"name"}
                        changeValue={(_: string, value: string) => changeEquipment(value)}
                        name={"selectedItem"}
                    />
                )}
            </div>
        );
    };

    changeInformation = (name: string, value: string | Array<string>) => {
        if (name === "site_address1") {
            axios
                .get(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${value},+CA&key=AIzaSyAQU3x2PDxhLZEYFk4Af4HBPYDpy40aHEs`
                )
                .then((res) => {
                    if (res.data.results.length > 0) {
                        this.setState((state) => ({
                            equipment: {
                                ...state.equipment,
                                site_latitude: res.data.results[0].geometry.location.lat,
                                site_longitude: res.data.results[0].geometry.location.lng,
                                site_city:
                                    res.data.results[0].address_components[2] &&
                                    res.data.results[0].address_components[2].long_name,
                                site_zipcode:
                                    res.data.results[0].adress_components &&
                                    res.data.results[0].adress_components[6] &&
                                    res.data.results[0].address_components[6].long_name,
                            },
                        }));
                    }
                    this.setState(() => ({ loader: false }));
                })
                .catch((error) => console.error(error));
        }
        this.setState((state) => ({
            equipment: {
                ...state.equipment,
                [name]: value,
            },
        }));
    };

    changeValue = (
        path: string,
        value:
            | string
            | boolean
            | number
            | InterfaceType
            | Array<
                  | GatewayType
                  | RoutageType
                  | InterfaceType
                  | InterfaceAddressType
                  | EntryType
                  | DhcpOptionType
                  | string
                  | DhcpStatiqueType
              >
    ) => {
        this.setState((state) => {
            const equipment = changeValueWithPathName(state.equipment, path, value);
            return { equipment };
        });
    };

    changeGroupeValue = (type: "groupNetworks" | "groupPorts", data: Array<GroupPortType | GroupNetworkType>) => {
        // @ts-ignore
        this.setState(() => ({ [type]: data }));
    };

    changeTab = (key: string) => {
        this.setState(() => ({ activeTab: key }));
    };

    render() {
        const { onClose, visible, formType, roles, selectedItem } = this.props;
        const { equipment, activeTab, groupNetworks, groupPorts, loader } = this.state;
        const { Gateways } = equipment;
        const { Routage } = equipment;
        return (
            <BasicDrawerForm
                formType={formType}
                header={this.drawerHeader()}
                className={"EquipmentForm"}
                data={equipment}
                onClose={onClose}
                submit={this.submit}
                visible={visible}
                role={roles}
                loader={loader}
            >
                {!this.state.loader ? (
                    <Tabs activeKey={activeTab} onChange={this.changeTab}>
                        <TabPane
                            forceRender={true}
                            tab={<span className="TitleTab">Informations</span>}
                            key={"Informations"}
                        >
                            <Informations
                                equipment={equipment}
                                changeInformation={this.changeInformation}
                                role={roles}
                                changeGroupeValue={this.changeGroupeValue}
                                groupNetworks={groupNetworks}
                                groupPorts={groupPorts}
                            />
                        </TabPane>
                        <TabPane
                            forceRender={true}
                            tab={<span className="TitleTab">Interfaces</span>}
                            key={"Interfaces"}
                        >
                            <Interfaces
                                equipment={equipment}
                                equipmentId={selectedItem}
                                interfaces={equipment.Interface}
                                gateways={Gateways}
                                changeValue={this.changeValue}
                                role={roles}
                            />
                        </TabPane>
                        <TabPane
                            forceRender={true}
                            tab={<span className="TitleTab">Passerelles</span>}
                            key={"Passerelles"}
                        >
                            <Passerelles
                                Gateways={Gateways}
                                equipment={equipment}
                                changeValue={this.changeValue}
                                role={roles}
                            />
                        </TabPane>
                        <TabPane forceRender={true} tab={<span className="TitleTab">Routages</span>} key={"Routages"}>
                            <Routages
                                Routages={Routage}
                                equipment={equipment}
                                changeValue={this.changeValue}
                                role={roles}
                            />
                        </TabPane>
                    </Tabs>
                ) : (
                    <div className={"contain-loader"}>
                        <Loader />
                    </div>
                )}
            </BasicDrawerForm>
        );
    }
}

export default connector(EquipmentForm);
