import * as React from "react"

class Settings extends React.Component {
	render() {
		return(
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1200 1200"
			xmlSpace="preserve"
		>
			<path
				d="M1139.111 479.604h-75.67c-11.318-43.891-28.578-85.277-51.064-123.353l53.486-53.486c23.412-23.412 23.412-61.371 0-84.783l-84.783-84.783c-23.412-23.412-61.371-23.412-84.783 0l-53.486 53.486c-38.076-22.487-79.463-39.747-123.353-51.064v-75.67C719.458 26.841 692.617 0 659.507 0H539.606c-33.11 0-59.951 26.841-59.951 59.951v75.67c-43.891 11.318-85.277 28.577-123.353 51.064l-53.486-53.486c-23.412-23.412-61.371-23.412-84.783 0l-84.783 84.783c-23.412 23.412-23.412 61.371 0 84.783l53.486 53.486c-22.487 38.076-39.747 79.463-51.064 123.353h-75.67c-33.11 0-59.951 26.841-59.951 59.951v119.901c0 33.11 26.841 59.951 59.951 59.951h75.67c11.318 43.891 28.578 85.277 51.064 123.353l-53.486 53.486c-23.412 23.412-23.412 61.371 0 84.783l84.783 84.782c23.412 23.412 61.37 23.412 84.782 0l53.486-53.485c38.076 22.487 79.463 39.747 123.353 51.065v75.67c0 33.11 26.841 59.95 59.951 59.95h119.901c33.11 0 59.951-26.841 59.951-59.95v-75.67c43.891-11.318 85.277-28.578 123.353-51.065l53.486 53.485c23.412 23.412 61.37 23.411 84.782 0l84.783-84.782c23.412-23.412 23.413-61.371 0-84.783l-53.486-53.486c22.487-38.076 39.747-79.463 51.064-123.353h75.67c33.11 0 59.95-26.841 59.95-59.951V539.555c.003-33.11-26.838-59.951-59.948-59.951zM599.557 864.948c-146.327 0-264.948-118.621-264.948-264.948S453.23 335.052 599.557 335.052 864.505 453.673 864.505 600 745.884 864.948 599.557 864.948z"
			/>
		</svg>
		)
	}
}

export default Settings
