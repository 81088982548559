import React from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import * as am4charts from '@amcharts/amcharts4/charts';

interface Props {
    data: Array<{
        operator?: string;
        technology?: string;
        count: number;
    }>;
    name: string;
}
class Chart extends React.Component<Props> {
    private mount: any;

    componentDidMount() {
        this.initChart();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.data !== this.props.data) {
            if (this.props.data) {
                this.initChart();
            }
        }
    }

	technologyOrOperator() {
		const data = this.props

		let string = ''

		if(data) {
			if(data.name === "operator") {
				if(data.data.length > 1) {
					string = 'Opérateurs'
				}
			}

			else if(data.name === "technology") {
				if (data.data.length > 1) {
					string = "Technologies"
				}
			}

			return string
		}
	}



    initChart = () => {
        const { data } = this.props;
        if (this.mount) {
            am4core.useTheme(am4themes_animated);
            am4core.options.autoDispose = true;
            const container = am4core.create(`chartOperator_${this.props.name}`, am4core.Container);
            container.width = am4core.percent(100);
            container.height = am4core.percent(100);
            const legendContainer = am4core.create(`legendOperator_${this.props.name}`, am4charts.PieChart);
            legendContainer.width = am4core.percent(100);
            legendContainer.height = am4core.percent(100);

            const chart = container.createChild(am4charts.PieChart);
            chart.innerRadius = am4core.percent(60);
            chart.data = data;
            chart.responsive.enabled = true;
            const pieSeries = chart.series.push(new am4charts.PieSeries());
            pieSeries.dataFields.value = "count";
            pieSeries.dataFields.category = this.props.name;
            pieSeries.slices.template.stroke = am4core.color('#fff');
            pieSeries.slices.template.strokeWidth = 2;
            pieSeries.slices.template.strokeOpacity = 1;
            pieSeries.hiddenState.properties.opacity = 1;
            pieSeries.labels.template.disabled = true;
            pieSeries.ticks.template.disabled = true;
            chart.legend = new am4charts.Legend();
            chart.legend.fontSize = '0.8vw';

            const marker = chart.legend.markers.template.children.getIndex(0);
            if (marker) {
                //@ts-ignore
                marker.cornerRadius(12, 12, 12, 12);
                const markerSize = 45;
                marker.height = am4core.percent(markerSize);
                marker.width = am4core.percent(markerSize);
                marker.marginTop = am4core.percent(500);
            }
            chart.legend.position = 'top';
            chart.legend.width = am4core.percent(100);
            const modifLegend = chart.legend.valueLabels.template;

            modifLegend.adapter.add('text', () => {
                return '';
            });
          
            chart.legend.parent = legendContainer;
            pieSeries.slices.template.adapter.add('tooltipText', () => {
                return `{category}: {value}`;
                // return `{value}`
            });

            const label = pieSeries.createChild(am4core.Label);
            label.text = `${data.length > 1 ? data.length : "Data not found"}`;
            label.horizontalCenter = 'middle';
            label.verticalCenter = 'middle';
            label.fontSize = '1vw';
            label.fontFamily = 'Poppins';
            label.textAlign = 'middle';
            label.fontWeight = '600';
            label.adapter.add('text', (text) => {
                return `${text}
                 ${this.technologyOrOperator()}`;
            });
        }
    };
    render() {
        return (
            <>
                <div
                    className={'ChartOperator'}
                    ref={(mount) => (this.mount = mount)}
                    id={`chartOperator_${this.props.name}`}
                    style={{ height: '75%', width: '100%' }}
                />
                {/* <div id={`legendOperator_${this.props.name}`} style={{ height: '25%', width: '100%' }} /> */}
            </>
        );
    }
}

export default Chart;
