import React from "react";
import ReactTable from "react-table-6";
import { matchSorter } from "match-sorter";
import {
    AccesInternetType,
    ColumnBasicTable,
    EquipmentType,
    FiltrageWebTableType,
    FiltrageWebType,
    FireWallType,
    IntercoTableType,
    IpsecTableType,
    LinkType,
    LogType,
    ModeleTableType,
    QosType,
    ServerLdap,
    UserType,
    VpnUserType,
    TrafficStatsDetails,
} from "../../types";
import { Tooltip } from "antd";
import IconInfo from "../SVGComponents/IconInfo";
import NoDataImg from "../../assets/images/Notfound-boitier.svg";
import { data } from "cypress/types/jquery";

type dataType =
    | LinkType
    | IpsecTableType
    | FiltrageWebTableType
    | EquipmentType
    | FireWallType
    | ModeleTableType
    | VpnUserType
    | IntercoTableType
    | QosType
    | FiltrageWebType
    | AccesInternetType
    | ServerLdap
    | UserType
    | LogType
    | TrafficStatsDetails;

interface Props {
    columns: Array<ColumnBasicTable>;
    data: Array<dataType> | undefined;
    selectedItems?: Array<string> | string;
    selectItems?: (ids: Array<string>) => void;
    selectItem?: (id: string) => void;
    tableName: string;
    openForm?: (formType: string, selectedItem?: string) => void;
    role?: any;
    defaultSorted?: Array<any>;
}

interface State {
    selectedAll: boolean;
}

class BasicTable extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedAll: false,
        };
    }
    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.selectedItems) {
            if (prevProps.selectedItems !== this.props.selectedItems && this.props.data) {
                this.setState(() => ({ selectedAll: this.props.data?.length === this.props.selectedItems?.length }));
            }
        }
    }

    selectAll = (ids: Array<string>) => {
        const { selectedAll } = this.state;
        const { selectItems } = this.props;
        if (selectItems) {
            if (!selectedAll) {
                selectItems(ids);
                return { selectedItems: ids, selectAll: true };
            } else {
                selectItems([]);
                return { selectedItems: [], selectAll: false };
            }
        }
    };

    selectItem = (itemId: string) => {
        const { selectedItems, selectItem, selectItems } = this.props;

        if (typeof selectedItems === "string") {
            if (selectItem) {
                selectItem(itemId);
            }
        } else {
            if (selectItems && selectedItems) {
                if (selectedItems.find((id) => id === itemId)) {
                    selectItems(selectedItems.filter((id) => id !== itemId));
                } else {
                    selectItems([...selectedItems, itemId]);
                }
            }
        }
    };

    onRowClick = (_: void, rowInfo: any, column: any) => {
        const columnType = column && true && column.Header && column.Header.type;
        return {
            onClick: (): void => {
                if (rowInfo && columnType !== "input" && this.props.openForm && column.Header !== "Action") {
                    this.props.openForm("view", rowInfo.original._id);
                }
            },
        };
    };

    formatColumn = () => {
        const { columns, tableName } = this.props;
        return columns.map((column) => {
            return {
                id: column.accessor,
                filterMethod: (filter: { value: string }, rows: Array<{ original: dataType }>) => {
                    return matchSorter(rows, filter.value, { keys: [column.accessor] }, );
                },
                filterable: true,
                filterAll: true,
                sortable: column.sort,
                Filter: ({ onChange }: { onChange: (value: string) => void }) => {
                    return (
                        <input
                            placeholder="..."
                            className=" inputfilters"
                            onChange={(event) => onChange(event.target.value)}
                            style={column.sort ? { visibility: "visible" } : { visibility: "hidden" }}
                        />
                    );
                },
                Cell: (row: { original: { [key: string]: string } }) => {
                    return (
                        <span className={`${tableName}__${row.original[column.accessor]}`}>
                            {row.original[column.accessor]}
                        </span>
                    );
                },

                ...column,
                Header: (
                    <div className={"ctn__table__title"}>
                        <span>{column.Header}</span>
                        {column.sort && (
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="sort"
                                className="svg-inline--fa fa-sort fa-w-10 icon__sort__table "
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 320 512"
                            >
                                <path
                                    fill="currentColor"
                                    d="M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z"
                                />
                            </svg>
                        )}
                        {column.tooltip && (
                            <Tooltip className={"table__header__tooltip"} placement="topRight" title={column.tooltip}>
                                <button className={"btn__tooltip"}>
                                    <IconInfo />
                                </button>
                            </Tooltip>
                        )}
                    </div>
                ),
            };
        });
    };

    checkedRow = (row: { original: { _id: string } }): boolean => {
        const { selectedItems } = this.props;
        let checked = false;
        if (selectedItems) {
            if (typeof selectedItems === "string") {
                checked = selectedItems === row.original._id;
            } else {
                checked = !!selectedItems.find((i) => i === row.original._id);
            }
        }
        return checked;
    };

    columns() {
        const { data, selectedItems, selectItem, role } = this.props;
        const { selectedAll } = this.state;
        let key = window.location.pathname.substring(1);
        const condition = !!(role && role[0] && role[0][key].write === false);
        let columns = [];
        if (selectedItems && typeof selectedItems !== "string" && data) {
            columns = [
                {
                    Header: (
                        <input
                            onChange={() => this.selectAll(data.flatMap((d: any) => d._id))}
                            type="checkbox"
                            className="table__checkbox"
                            checked={selectedAll}
                            // disabled={condition}
                        />
                    ),
                    accessor: "volume",
                    Cell: (row: { original: { _id: string } }) => (
                        <input
                            type="checkbox"
                            className="table__checkbox"
                            checked={!!selectedItems.find((i) => i === row.original._id)}
                            onChange={() => this.selectItem(row.original._id)}
                            onClick={(e) => e.stopPropagation()}
                            // disabled={condition}
                        />
                    ),
                    filterable: false,
                    sortable: false,
                    maxWidth: 70,
                },
                ...this.formatColumn(),
            ];
        } else if (selectItem && data) {
            columns = [
                {
                    accessor: "volume",
                    Cell: (row: { original: { _id: string } }) => {
                        return (
                            <input
                                type="checkbox"
                                className="table__checkbox"
                                checked={selectedItems === row.original._id}
                                onChange={() => this.selectItem(row.original._id)}
                                onClick={(e) => e.stopPropagation()}
                            />
                        );
                    },
                    filterable: false,
                    sortable: false,
                    maxWidth: 70,
                },
                ...this.formatColumn(),
            ];
        } else {
            columns = this.formatColumn();
        }
        return columns;
    }

    NoDataComponent = () => {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
                    <img src={NoDataImg} width={180} height={180} />
                    <p style={{ textAlign: "center", marginBottom: "2vh" }}>Aucune donnée(s) disponible</p>
                </div>
            </div>
        );
    };

    render() {
        const { data, selectItem, selectItems, role, defaultSorted } = this.props;
        if (data && data.length > 0) {
            return (
                <ReactTable
                    className={`BasicTable BasicTable__${selectItems || selectItem ? "checkbox" : ""}`}
                    showPagination={true}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    data={data}
                    columns={this.columns()}
                    getTdProps={this.onRowClick}
                    rowsText="Lignes"
                    minRows={data && data.length < 10 ? data.length : 10}
                    pageSizeOptions={data && data.length < 11 ? [data.length] : [10, 20, 25, 50, 100]}
                    defaultPageSize={10}
                    defaultSorted={defaultSorted && defaultSorted.length > 0 ? defaultSorted : []}
                />
            );
        } else {
            return (
                <ReactTable
                    className={`BasicTable BasicTable__${selectItems || selectItem ? "checkbox" : ""}`}
                    showPagination={true}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    data={data}
                    columns={this.columns()}
                    getTdProps={this.onRowClick}
                    rowsText="Lignes"
                    minRows={data && data.length < 10 ? data.length : 10}
                    pageSizeOptions={data && data.length < 11 ? [data.length] : [10, 20, 25, 50, 100]}
                    defaultPageSize={10}
                    defaultSorted={defaultSorted && defaultSorted.length > 0 ? defaultSorted : []}
                    TbodyComponent={this.NoDataComponent}
                />
            );
        }
    }
}

export default BasicTable;
