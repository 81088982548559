import React from 'react';
import { SiteType } from '../../../../types';
import SelectSimple from '../../../../common/SelectSimple/SelectSimple';
import PieChartCharge from '../../../Equipements/Liste/EquipmentForm/Informations/ChargesPanel/PieChartCharge/PieChartCharge';
import Arrow from '../../../../common/SVGComponents/Arrow';
import { Tooltip } from 'antd';
import Socket from '../../../../utils/Socket';
import IconInfo from '../../../../common/SVGComponents/IconInfo';

interface Props {
    Sites: (SiteType | undefined)[];
    Site: Array<{
        download: number,
        sitename: any,
        upload: number,
        _id: string
    }> | undefined,
    entityId: string
}

interface State {
    site_id: string;
    chartType: string;
}

class TransitIp extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            site_id: '',
            chartType: 'download',
        };
    }

    changeValue = (_: string, value: string) => {
        this.setState(() => ({ site_id: value }));
    };

    componentDidMount() {
        this.initSite();
        const data = this.data();
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        if (prevProps.Site !== this.props.Site) {
            this.initSite();
        }
    }

    initSite = () => {
        const { Sites, Site } = this.props;
        if (Site && Site.length > 0 && Site[0]) {
            if ('_id' in Site[0]) {
                //@ts-ignore
                this.setState(() => ({ site_id: Site[0]._id }));
            }
        }
    };

    changeType = (type: string) => {
        this.setState(() => ({ chartType: type }));
    };

    async data() {
        const { Sites, Site, entityId } = this.props;


        const res = await Socket.read('Entity', entityId)
        return res.data

    }

    convertToGiga = (value: number) => {
        console.log(value)
        if (value >= 1000) {
            return value / 1000 + ' ' + 'Gb/s';
        } else {
            return value + ' ' + 'Mb/s';
        }
    }
    render() {
        const { Sites, Site, entityId } = this.props;
        const { site_id, chartType } = this.state;
        let newValue;
        let newValueFilter;
        let data;
        if (Site) {
	        console.log(Site)
	        console.log(Sites)
            //@ts-ignore
            newValue = Site
            newValueFilter = newValue.filter((elements) => {
                return elements.sitename !== undefined
            })
	        console.log(newValue)
            const site = newValue && newValue.length > 0 && newValue.find((site) => site._id === site_id);
			console.log(Sites)
            if (site) {
                data = [
                    {
                        category: chartType,
                        value: site && chartType === "upload" ? site.upload : site.download,
                        full: 1024,
                        color: chartType === "upload" ? '#F8606A' : '#6794DB'
                    },
                ]
            }
        }

		console.log(Sites)

        return (
            <div className={'TransitIp'}>
                <section className='tooltipsTransitIp'>
                <h3 >Transit IP </h3> 
                <Tooltip placement="top" zIndex={10} title={'Cumulé de la bande passante disponible par site'}>
                    <div className='iconInfoTransitIp'>
                    <IconInfo/>
                    </div>
                </Tooltip>
                </section>
                <div className={'ctn__bandwidth'}>
                    <SelectSimple
	                    // items={newValueFilter && newValueFilter.length > 0 ? newValueFilter : []}
	                    items={Sites}
                        //@ts-ignore
                        value={newValueFilter && newValueFilter.length > 0 && site_id}
                        changeValue={this.changeValue}
                        name={'_id'}
                        valuesName={'_id'}
                        valuesDisplay={'name'}
	                    className={'arrow'}
                    />
                    <div className={'ctn__transit__chart'}>
                        <PieChartCharge
                            labelUnit={data && data[0].value > 100 ? 'Gbps' : 'Mbps'}
                            chartPage='dashboard'
                            data={
                                data
                                    ? data
                                    : [
                                        {
                                            category: chartType,
                                            value: 0,
                                            full: 1024,
                                            color: chartType === "upload" ? '#F8606A' : '#6794DB'
                                        },
                                    ]
                            }
                            name={'transitIp'}
                        />
                    </div>
                    <div className={'ctn__arrow'}>
                        <button onClick={() => this.changeType('download')}>
                            <Tooltip title={'download'}>
                                <Arrow />
                            </Tooltip>
                        </button>
                        <button onClick={() => this.changeType('upload')}>
                            <Tooltip title={'upload'}>
                                <Arrow />
                            </Tooltip>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default TransitIp;