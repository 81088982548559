import React from 'react';
import BasicTable from '../../../common/BasicTable/BasicTable';
import {EquipmentType, InterconnexionType, IntercoTableType} from '../../../types';
import BarInterconnexionActions from './BarInterconnexionActions/BarInterconnexionActions';
import ContainerRestPage from '../../../common/ContainerRestPage/ContainerRestPage';
import {notification, Switch} from 'antd';
import logo from '../../../assets/images/World-icon.svg'
import SvgpPlanet from '../../../common/SVGComponents/Planet/Planet'
// @ts-ignore
import LoaderInterco from '../../../assets/Loader-Interco.mp4'
import Socket from "../../../utils/Socket";

const sourceColumns = [
    {
        Header: 'Nom du site',
        accessor: 'site_name',
        sort: true,
    },
    {
        Header: 'N° Série',
        accessor: 'serial_number',
        sort: true,
    },
    {
        Header: 'Équipement',
        accessor: 'name',
        sort: true,
    },
];



//@ts-ignore
const openNotificationWithIcon = type => {
    //@ts-ignore
    notification[type]({
        style: { color: 'green', marginTop: '15rem' },
        duration: 3,
        description: 'La connexion a été mise a jour',
        //   description:
        //     'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
    });
};

interface Props {
    entityId: string;
    Equipments: Array<EquipmentType> | undefined;
    Interconnexions: Array<InterconnexionType>;
    role: any;
}

interface State {
    selectedSource: string;
    selectedDestinations: Array<string>;
    formatterTableDest: Array<IntercoTableType>
    Interconnexions: Array<InterconnexionType>;
    video: boolean
}
class InterConnexion extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedSource: '',
            selectedDestinations: [],
            formatterTableDest: [],
            Interconnexions: [],
            video: false
        };
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {

        if (prevState.selectedSource !== this.state.selectedSource ) {
            this.setState(() => ({ selectedDestinations: this.getDestinationsComparedSource(), Interconnexions: this.state.Interconnexions }));
        }

        if(this.props.Interconnexions !== prevProps.Interconnexions) {
                this.setState(() => ({Interconnexions: this.state.Interconnexions}))
        }

        // if (prevState.Interconnexions !== this.state.Interconnexions) {

        // }

    }

    componentDidMount() {
        this.setState({ Interconnexions: this.props.Interconnexions })
    }

    getDestinationsComparedSource(): Array<string> {
        const { selectedSource } = this.state;
        const { Interconnexions } = this.props

        let destinations: Array<string> = [];
        if (selectedSource) {
            for (const d of Interconnexions) {
                if (d.equipment1_id === selectedSource && d.enabled)
                    destinations.push(d.equipment2_id.toString())
                if(d.equipment1_id === selectedSource) {
                    this.setState(() => ({selectedDestinations: destinations}));
                }
            }
        }
        return destinations;
    }

    formatterTableSource = (): Array<IntercoTableType> => {
        const { Equipments } = this.props;
        const tableData: Array<IntercoTableType> = [];
        if (Equipments && Equipments.length > 0) {
            Equipments.map((element) =>
                tableData.push({
                    _id: element._id,
                    site_name: element.site_name,
                    serial_number: element.serial,
                    name: element.name,
                }),
            );
        }
        return tableData;
    };


    formatterTableDest = () => {
        const { Equipments, Interconnexions } = this.props;
        const {selectedSource} = this.state;
        const tableData: Array<IntercoTableType> = [];

        const foundInterco = Interconnexions.filter(interco => interco.equipment1_id === selectedSource);
        const intercoDestIDs = foundInterco.map(interco => interco.equipment2_id);

            if (Equipments && Equipments.length > 0) {

                let equipments = Equipments.filter((e) => e._id !== this.state.selectedSource);

                const newEquipments = [...equipments.filter(e => intercoDestIDs.includes(e._id)), ...equipments.filter(e => !intercoDestIDs.includes(e._id))]

                newEquipments.map((element) =>

                    tableData.push({
                        _id: element._id,
                        site_name: element.site_name,
                        serial_number: element.serial,
                        name: element.name,
                        enabled: this.checkEnabled(element._id),
                        state: this.checkState(element._id)
                    }),
                );
            }

        return tableData;
    };

    checkEnabled = (idEquipmentDest: string) => {
        const { Interconnexions } = this.state;
        const interco = Interconnexions.find(element => element.equipment2_id === idEquipmentDest && element.equipment1_id === this.state.selectedSource)
        return interco?.enabled
    }

    checkState = (idEquipmentDest: string) => {
        const { Interconnexions } = this.state;
        const interco = Interconnexions.find(element => element.equipment2_id === idEquipmentDest && element.equipment1_id === this.state.selectedSource)

        return interco?.state
    }

    selectSource = (id: string) => {
        this.setState((state) => ({ selectedSource: state.selectedSource === id ? '' : id }));
    };

    selectDestinations = (ids: Array<string>) => {
            this.setState((state) => ({ selectedDestinations: state.selectedDestinations === ids ? [] : ids }));
    };

    closeEdit = (): void => {
        this.setState((state) => ({
            ...state,
            selectedSource: '',
        }));
    };


    intercoDeselect = () => {
        const { selectedSource, selectedDestinations, Interconnexions } = this.state;

        let IntercoDest: string[] = []

        for (const index in Interconnexions) {
            if(Interconnexions[index].equipment1_id === selectedSource) {
                IntercoDest.push(Interconnexions[index].equipment2_id)
            }
        }
        return IntercoDest.filter(x => selectedDestinations.indexOf(x as string) === -1)
    }

    enabledIntercoFalse = () => {
        const { selectedSource, Interconnexions } = this.state;
        const intercoDeselects = this.intercoDeselect()

        let tab: Array<InterconnexionType> = []
        for (const intercoDeselect of intercoDeselects) {
            Interconnexions.map(elem => {
                if (elem.equipment1_id === selectedSource && elem.equipment2_id === intercoDeselect) {
                    tab.push({...elem, enabled: false})
                }
            })
        }
        return tab
    }


    enabledIntercoTrue = () => {
        const { selectedSource, selectedDestinations, Interconnexions } = this.state;

        let tab: Array<InterconnexionType> = []
            for (const selectedDestination of selectedDestinations) {
                Interconnexions.map(elem => {
                    if (elem.equipment1_id === selectedSource && elem.equipment2_id === selectedDestination) {
                        tab.push({...elem, enabled: true})
                    }
                })
            }
        return tab
    }


    interConnexionsSocket = () => {
        const { entityId } = this.props;
        const { selectedSource, selectedDestinations, Interconnexions } = this.state;

        const interconnexion = Interconnexions.map(elem => elem.equipment1_id === selectedSource && elem.equipment2_id)
        const updateInterco = this.enabledIntercoTrue().concat(this.enabledIntercoFalse())
        const newsInterco = selectedDestinations.filter(x => interconnexion.indexOf(x) === -1);


        Socket.interconnexion("Interconnexion", selectedSource, updateInterco, newsInterco, entityId)
            .then(() => {
                openNotificationWithIcon('success')
                this.closeEdit()
            })
            .catch(error => console.error(error))
    }


    // @ts-ignore
    destColumns = () => ([
        {
            Header: 'Nom du site',
            accessor: 'site_name',
            sort: true,
        },
        {
            Header: 'N° Série',
            accessor: 'serial_number',
            sort: true,
        },
        {
            Header: 'Équipement',
            accessor: 'name',
            sort: true,
        },
        {
            Header: 'État',
            accessor: 'state',
            Cell: (row: { original: { state: boolean }; }) => (
                <>
                {/*<Switch*/}
                {/*    className="pull-right"*/}
                {/*    checked={row.original.state}*/}
                {/*    disabled={true}*/}
                {/*/>*/}
                <SvgpPlanet className={'logo-interconnexion'} data={row.original.state} />
                </>
            ),
        }
    ])



    render() {
        const { selectedSource, selectedDestinations } = this.state;
        const { role } = this.props;
        return (
            <div className={'Interconnexion'}>
                <div>
                    <h3>Sources</h3>
                    <ContainerRestPage>
                        <BasicTable
                            columns={sourceColumns}
                            data={this.formatterTableSource()}
                            tableName={'interco__table__source'}
                            selectedItems={selectedSource}
                            selectItem={this.selectSource}
                            role={role}
                        />
                    </ContainerRestPage>
                </div>
                <div>
                    <h3>Destinations</h3>
                    <ContainerRestPage>
                        <BasicTable
                            columns={this.destColumns()}
                            data={this.formatterTableDest()}
                            tableName={'interco__table__destination'}
                            selectedItems={selectedDestinations}
                            selectItems={this.selectDestinations}
                            role={role}

                        />
                    </ContainerRestPage>
                </div>
                <BarInterconnexionActions
                    selectedSource={selectedSource}
                    selectedDestinations={selectedDestinations.length}
                    closeBar={this.closeEdit}
                    interConnexions={this.interConnexionsSocket}
                    role={role}
                />
            </div>
        );
    }
}

export default InterConnexion;
