import React from "react";
import { DhcpStatiqueType } from "../../../../../../../../../types";
import TextField from "../../../../../../../../../common/TextField/TextField";
import { Button } from "antd";
import "./DhcpStatic.scss";

interface Props {
  dhcpStatic: DhcpStatiqueType;
  dhcpStatics: Array<DhcpStatiqueType>;
  interfaceIndex: number;
  dhcpStaticIndex: number;
  changeValue: (path: string, value: string | Array<DhcpStatiqueType>) => void;
  role: any;
}

interface State {
  edit: boolean;
}

class DhcpStatic extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      edit: false,
    };
  }

  componentDiMount() {
    const { mac, adress } = this.props.dhcpStatic;
    if (!mac && !adress) {
      this.setState(() => ({ edit: true }));
    }
  }

  edit = () => {
    this.setState((state) => ({ edit: !state.edit }));
  };

  remove = () => {
    const { interfaceIndex, dhcpStaticIndex, dhcpStatics, changeValue } =
      this.props;
    const removeDhcpStatic = dhcpStatics.filter(
      (e, index) => index !== dhcpStaticIndex && e
    );
    changeValue(
      `Interface[${interfaceIndex}].Dhcp.static[${dhcpStaticIndex}]`,
      removeDhcpStatic
    );
  };

  render() {
    const { interfaceIndex, dhcpStaticIndex, role } = this.props;
    const { mac, adress } = this.props.dhcpStatic;
    const { edit } = this.state;
    const key = window.location.pathname.substring(1);
    const rolesCondition =
      role[0] && role[0][key].write === false ? true : false;

    return (
      <div className={"DhcpStatic ctn__fields"}>
        <div>
          <label>Adresse Ip</label>
          <TextField
            className={""}
            placeholder={"Adress Ip"}
            name={`Interface[${interfaceIndex}].Dhcp.static[${dhcpStaticIndex}].adress`}
            value={adress}
            changeValue={this.props.changeValue}
            type={"text"}
            disabled={!edit || rolesCondition}
            required={true}
            isOption={true}
            isIp={true}
          />
        </div>
        <div>
          <label>MAC</label>
          <TextField
            className={""}
            placeholder={"MAC"}
            name={`Interface[${interfaceIndex}].Dhcp.static[${dhcpStaticIndex}].mac`}
            value={mac}
            changeValue={this.props.changeValue}
            type={"text"}
            disabled={!edit || rolesCondition}
            required={true}
            isMac={true}
          />
        </div>
        {role[0][key].write === true && (
          <div className={"ctn__btns"}>
            <Button
              htmlType="button"
              onClick={this.edit}
              className={"btn__dhcpStatic__update"}
            >
              Modifier
            </Button>
            <Button
              htmlType="button"
              onClick={this.remove}
              className={"btn__dhcpStatic__delete"}
            >
              Supprimer
            </Button>
          </div>
        )}
      </div>
    );
  }
}

export default DhcpStatic;
