import React from 'react';

import * as am4core from '@amcharts/amcharts4/core';
import { Label } from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ItemDataCharge } from '../../../../../../../types';

interface Props {
    data: any;
    name: string;
    labelUnit: string;
    chartPage: string
    charges?: string
}

class PieChartCharge extends React.Component<Props> {
    private chart: any;

    componentDidMount() {
        this.initChart();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.data !== this.props.data) {
            this.chart.data = this.props.data;
            if (this.props.chartPage === 'dashboard') {
                this.initChart();
            }
        }
    }

    // formatData(number : string){
    //     console.log(number, 'number')
    //     if((number === '0') || (number === '1')){
    //         return 'Liaison'
    //     } else {
    //         return 'Liaisons'
    //     }
        
    // }

    initChart = () => {
        const chart = this.createChart();
        this.createAxes(chart);
        this.createValueAxis(chart);
        const series = this.createSeries(chart);
        this.middleLable(series);
        this.chart = chart;
    };

    middleLable(series: any) {
        const label = series.createChild(am4core.Label);
        label.text = '{values.value.sum}';
        label.horizontalCenter = 'middle';
        label.verticalCenter = 'middle';
        label.textAlign = 'middle';
        label.fontWeight = '600';
        label.fontSize = '1vw';
        label.fontFamily = 'Poppins';
        label.adapter.add('text', (_: string | undefined, target: Label) => {
            // @ts-ignore
            const initialValue = target.dataItem.values.valueX.sum;
            //@ts-ignore
            if (this.props.charges) {
                return `${parseInt('' + initialValue + '') + this.props.charges}
                ${this.props.labelUnit} `;

            } else {
                return `${parseInt('' + initialValue + '')}
                ${this.props.labelUnit} `;
            }
        });
    }
    convertToGiga = (value: number) => {
        console.log(value)
        if (value >= 1000) {
            return value / 1000;
        } else {
            return value;
        }
    }
    createChart() {
        am4core.useTheme(am4themes_animated);
        am4core.options.autoDispose = true;
        const chart = am4core.create(`chartdiv__${this.props.name} `, am4charts.RadarChart);
        console.log([{...this.props.data[0], value: this.convertToGiga(this.props.data[0].value)}])
        chart.data = [{...this.props.data[0], value: this.convertToGiga(this.props.data[0].value)}];

        // Make chart not full circle
        chart.startAngle = 90;
        chart.endAngle = 450;
        chart.innerRadius = am4core.percent(72);
        chart.width = am4core.percent(100);
        chart.height = am4core.percent(115);
      
        return chart;
    }

    createAxes(chart: any) {
        const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = 'category';
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.grid.template.strokeOpacity = 0;
        categoryAxis.renderer.labels.template.horizontalCenter = 'right';
        categoryAxis.renderer.labels.template.fontWeight = '500';
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.renderer.labels.template.disabled = true;
    }

    createValueAxis(chart: any) {
        const valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.strokeOpacity = 0;
        valueAxis.min = 0;
        valueAxis.max = this.props.chartPage === 'dashboard' ? 1024 : 100;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.labels.template.disabled = true;
    }

    createSeries(chart: any) {
        const series1 = chart.series.push(new am4charts.RadarColumnSeries());
        series1.dataFields.valueX = 'full';
        series1.dataFields.categoryY = 'category';
        series1.clustered = false;
        series1.columns.template.fill = new am4core.InterfaceColorSet().getFor('alternativeBackground');
        series1.columns.template.fillOpacity = 0.08;
        series1.columns.template.cornerRadiusTopLeft = 20;
        series1.columns.template.strokeWidth = 0;
        series1.columns.template.radarColumn.cornerRadius = 20;

        const series2 = chart.series.push(new am4charts.RadarColumnSeries());
        if (this.props.chartPage === 'dashboard') {
            series2.fill = am4core.color(this.props.data[0].color)
        }
        series2.dataFields.valueX = 'value';
        series2.dataFields.categoryY = 'category';
        series2.clustered = false;
        series2.columns.template.strokeWidth = 0;
        series2.columns.template.tooltipText = '{category}: [bold]{value}[/]';
        series2.tooltip.autoTextColor = false;
        series2.tooltip.label.fill = am4core.color("#FFFFFF");
        series2.tooltip.label.fontSize = "0.53vw";
        series2.columns.template.radarColumn.cornerRadius = 20;
        // chart.cursor = new am4charts.RadarCursor();
        return series2;
    }

    render() {
        const { name } = this.props;
        return <div id={`chartdiv__${name} `} className={`chartdiv__${name}`} />;
    }
}

export default PieChartCharge;
