import React from "react";
import BasicTable from "../../../common/BasicTable/BasicTable";
import { EntityType, EquipmentType } from "../../../types";
import EquipmentForm from "./EquipmentForm/EquipmentForm";
import { UserStringFields } from "../../../store/types";
import { changeGlobalStringValue } from "../../../store/actions";
import { connect, ConnectedProps } from "react-redux";
import ContainerRestPage from "../../../common/ContainerRestPage/ContainerRestPage";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import IconReporting from "../../../common/SVGComponents/IconRapport";
import { Tooltip } from "antd";
import Collections from "../../../utils/Collections";

const stateRefac: {
	operationnal: string;
	degraded: string;
	offline: string;
	[key: string]: string;
} = {
	operationnal: "Opérationnel",
	degraded: "Dégradé",
	offline: "Hors ligne",
};

interface ReduxState {
	collections: {
		Entity: Array<EntityType>;
	};
	global: {
		entity_id: string;
		equipment_id: string;
		roles: Array<any>;
	};
}

const mapStateToProps = (state: ReduxState) => {
	return {
		roles: state.global.roles,
		entities: state.collections.Entity,
		entityId: state.global.entity_id,
		equipment_id: state.global.equipment_id,
	};
};

const mapDispatchToProps = {
	changeGlobalStringValue: (field: UserStringFields, value: string) =>
		changeGlobalStringValue(field, value),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
	Equipments: Array<EquipmentType>;
	Entity: any;
	equipment_id: string;
}

interface State {
	selectedItem: string;
	visibleForm: boolean;
	formType: string;
	refactoData: Array<EquipmentType>
}

type PathParams = {
	pathname: string;
};

class Liste extends React.PureComponent<
	Props & RouteComponentProps<PathParams>,
	State
	> {
	constructor(props: any) {
		super(props);
		this.state = {
			visibleForm: false,
			selectedItem: "",
			formType: "",
			refactoData: [],
		};
	}

	componentDidMount() {
		this.openForm("view", this.props.equipment_id);
		this.props.changeGlobalStringValue("equipment_id", "");
	}

	componentDidUpdate(prevProps: Readonly<Props & RouteComponentProps>) {
		if (prevProps.equipment_id !== this.props.equipment_id) {
			this.openForm("view", this.props.equipment_id);
			this.props.changeGlobalStringValue("equipment_id", "");
		}
	}

	openForm = (_: string, selectedItem?: string) => {
		if (selectedItem) {
			this.setState(() => ({
				visibleForm: true,
				formType: "update",
				selectedItem: selectedItem,
			}));
		}
	};

	closeForm = () => {
		console.log("CLOSE FORM ASKED");
		this.setState(() => ({
			formType: "",
			selectedItem: "",
			visibleForm: false,
		}));
	};

	refactorData = () => {
		const { entityId, Equipments, Entity } = this.props;

		let tab = []
		if(Equipments)
			for(const equipment of Equipments) {

				tab.push({
					_id: equipment._id,
					name: equipment.name,
					site_name: equipment.site_name,
					serial: equipment.serial,
					version: equipment.version,
					state: stateRefac[equipment.state],
					entity_name: equipment.entity_name,
					interfaces_nb: equipment.interfaces_nb,
					model: equipment.model
				})
			}
		
		tab.sort((a, b) => a.name.localeCompare(b.name));

		return tab
	}

	selectItems = (ids: Array<string>): void => {
		this.setState(() => ({ selectedItem: ids[0] }));
	};

	changeItemSelect = (id: string) => {
		this.setState(() => ({ selectedItem: id }));
	};

	redirectReportingEquipment = (id: string) => {
		this.props.changeGlobalStringValue("equipment_id", id);
		this.props.history.push("/Rapport");
	};

	render() {
		const { entityId, Equipments, roles, Entity } = this.props;
		const { visibleForm, formType, selectedItem } = this.state;
		const condition = !(
			Entity[0]?.profile === "revendeur" ||
			(Entity && Entity[0] && Entity[0].profile === "client")
		);
		const condition2 = !(Entity[0]?.profile === "client");
		console.log(Equipments)
		const columns = [
			{
				Header: "Revendeur",
				accessor: "entity_name",
				sort: true,
				show: condition,
				Cell: function cell(row: { original: EquipmentType }) {
					if (row.original.entity_name) {
						return (
							<span className={"cell__revendeur"}><span>{row.original.entity_name[0]}</span></span>
						);
					}
				},
			},
			{
				Header: "Client",
				accessor: "entity_name",
				sort: true,
				show: condition2,
			},
			{
				Header: "Nom du site",
				accessor: "site_name",
				tooltip:
					"Nom du site donné par le client / Un site peut héberger plusieurs équipements",
				sort: true,
			},
			{
				Header: "Équipement",
				accessor: "name",
				tooltip: "Nom de l’équipement donné par le client",
				sort: true,
			},
			{
				Header: "N°série",
				accessor: "serial",
				sort: true,
			},
			{
				Header: "Version",
				accessor: "version",
				sort: true,
			},
			{
				Header: "État",
				accessor: "state",
				tooltip: "État de fonctionnement de l’équipement",
				sort: true,
				Cell: function cell(row: { original: { state: string } }) {
					return (
						<div className={`${row.original.state} cell__state`}>
							<span>{stateRefac[row.original.state] === 'Dégradé' ? <Tooltip title={"Au moins une interface active n'est pas connectée"}>Dégradé</Tooltip> : row.original.state }</span>
						</div>
					);
				},
			},
			{
				Header: "Ports Actifs",
				accessor: "interfaces_nb",
				sort: true,
			},
			{
				Header: "Modèle",
				accessor: "model",
				sort: true,
			},
			{
				Header: "Action",
				accessor: "action",
				tooltip: "Rapports / Upgrade / Redémarrer",
				Cell: (row: { original: EquipmentType }) => {
					return (
						<div>
							<button
								onClick={() =>
									this.redirectReportingEquipment(row.original._id)
								}
								className={"btn__action"}
							>
								{/*<IconFlecheUp className={"arrow_up"}/>*/}
								<IconReporting />
							</button>
							{/*<div className="decodiv">*/}
							{/*    /!*<IconOnOff className={"deco"}/>*!/*/}
							{/*    <span className="decotext">Redémarrer</span>*/}
							{/*</div>*/}
						</div>
					);
				},
			},
		];
		return (
			<div className={"Liste"}>
				<ContainerRestPage>
					<BasicTable
						openForm={this.openForm}
						columns={columns}
		// @ts-ignore
						data={this.refactorData()}
						tableName={"equipment__table"} />
				</ContainerRestPage>
				<EquipmentForm
					changeEquipment={this.changeItemSelect}
					entityId={entityId}
					Equipments={Equipments}
					selectedItem={selectedItem}
					formType={formType}
					visible={visibleForm}
					onClose={this.closeForm}
					roles={roles}
				/>
			</div>
		);
	}
}

export default withRouter(connector(Liste));
