import React from "react";
import Navbar from "./Navbar/Navbar";
import NavTop from "./NavTop/NavTop";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { routes } from "../../routes/Routes";
import { DashboardType } from "../../types";
import User from "../../utils/User";

const stateRefac: {
    Equipements: string;
    Notifications: string;
    Rapport: string;
    Labo: string;
    Securite: string;
    AccesInternet: string;
    Parametres: string;
    [key: string]: string;
} = {
    Equipements: 'Équipements',
    Notifications: 'Notifications',
    Rapport: 'Rapports',
    Labo: "Labo",
    Securite: "Sécurité",
    AccesInternet: "Accès Internet",
    Parametres: "Paramètres"
};

interface ReduxState {
  global: {
    roles: Array<any>;
  };
}

type PathParams = {
  pathname: string;
};

interface Props extends RouteComponentProps<PathParams> {
  children: JSX.Element[] | JSX.Element;
  data: DashboardType | undefined;
  role: any;
}

interface State {
  SdWan: string | number;
}

class Layout extends React.Component<Props, State> {
  idleTimer: any;
  constructor(props: any) {
    super(props);
    this.idleTimer = null;
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.state = {
      SdWan: "...",
    };
  }

  calculSD_WAN = (data: any) => {
    const Activecloudconn =
      data && data.activecloudconn && data.activecloudconn[0]?.count
        ? data.activecloudconn[0].count
        : 0;
    const Activevbox =
      data && data.activevbox && data.activevbox[0]?.count
        ? data.activevbox[0].count
        : 0;
    const Activevpnuser =
      data && data.activevpnuser && data.activevpnuser[0]?.count
        ? data.activevpnuser[0].count
        : 0;
    const Activebox =
      data && data.activebox && data.activebox[0]?.count
        ? data.activebox[0].count
        : 0;
    const Activelink =
      data && data.activelink && data.activelink[0]?.count
        ? data.activelink[0].count
        : 0;
    const Activeswitchs =
      data && data.activeswitch && data.activeswitch[0]?.count
        ? data.activeswitch[0].count
        : 0;
    const Activeborne =
      data && data.activeap && data.activeap[0]?.count
        ? data.activeap[0].count
        : 0;

    const cloudconn =
      data && data.cloudconn && data.cloudconn[0]?.count
        ? data.cloudconn[0].count
        : 0;
    const vbox =
      data && data.vbox && data.vbox[0]?.count ? data.vbox[0].count : 0;
    const vpnuser =
      data && data.vpnuser && data.vpnuser[0]?.count
        ? data.vpnuser[0].count
        : 0;
    const box = data && data.box && data.box[0]?.count ? data.box[0].count : 0;
    const link =
      data && data.link && data.link[0]?.count ? data.link[0].count : 0;
    const switchs =
      data && data.switch && data.switch[0]?.count ? data.switch[0].count : 0;
    const borne = data && data.ap && data.ap[0]?.count ? data.ap[0].count : 0;

    let active =
      Activecloudconn +
      Activevbox +
      Activevpnuser +
      Activebox +
      Activelink +
      Activeswitchs +
      Activeborne;
    let on = cloudconn + vbox + vpnuser + box + link + switchs + borne;

    if (active > 0 && on > 0) {
      let sdn_wan: any = (active / on) * 100;
      this.setState({ SdWan: parseInt(sdn_wan) });
    } else {
      this.setState({ SdWan: "..." });
    }
  };

  componentDidMount() {
    const { children, data } = this.props;
    this.calculSD_WAN(data);
  }

  componentDidUpdate(prevProps: any) {
    const { data } = this.props;
    if (prevProps.data !== this.props.data) {
      this.calculSD_WAN(data);
    }
  }
  handleOnIdle(event: any) {
    User.removeToken();
    if (process.env.NODE_ENV !== "development")
      window.location.replace(`${process.env.REACT_APP_REDIRECTION}`);
  }

  render() {
    const { children, data, role } = this.props;
    return (
      <div className={"Layout"}>
        {/* <IdleTimer
                    ref={(ref: any) => { this.idleTimer = ref }}
                    timeout={900000} //900000ms = 15min
                    onIdle={this.handleOnIdle}
                    debounce={250}
                /> */}
                <Navbar roles={role && role} />
                <NavTop />
                <div className={'content'}>
                    <div className={'wrapper__title__page'}>
                        {routes.map((route) => {
                            return <div
                                key={route.name}
                                className={`title__page ${`/${route.name}` === this.props.location.pathname && 'current'
                                }`}
                            >
                                {route.name !== 'Dashboard' ? (
                                    <>
                                        {route.icon}
                                        <h2>{stateRefac[route.name]}</h2>
                                    </>
                                ) : (
                                    <h2 style={{fontWeight: 500}}>Le SD-WAN est <span style={{fontWeight: 'bold'}}>opérationnel à {this.state.SdWan} %</span></h2>
                                )}
                            </div>
                        })}
                    </div>
                    {children}
                </div>
            </div>

        );
    }
}

export default withRouter(Layout);
