export const convertValueUnitBytes = (initialValue: number, ping?: boolean, percent?: boolean) => {
    let value;

    const KB = 1024;
    const MB = 1024 * 1024;
    const GB = 1024 * 1024 * 1024;
    const TB = 1024 * 1024 * 1024 * 1024;

    if (ping) {
        value = `${parseFloat(`${initialValue}`).toFixed(2)} ms`;
    } else if (percent) {
        value = `${parseFloat(`${initialValue}`).toFixed(0)} %`;
    } else {
        initialValue = initialValue * 8
        if (initialValue <= MB) {
            value = `${parseFloat(`${initialValue / KB}`).toFixed(1)} Kb`;
        } else if (initialValue <= GB) {
            value = `${parseFloat(`${initialValue / MB}`).toFixed(1)} Mb`;
        } else if (initialValue <= TB) {
            value = `${parseFloat(`${initialValue / GB}`).toFixed(1)} Gb`;
        } else {
            value = `${parseFloat(`${initialValue / TB}`).toFixed(1)} Tb`;
        }
    }
    return value;
};
export const convertValueUnitOctets = (initialValue: number, ping?: boolean, percent?: boolean) => {
    let value;

    const Ko = 1024;
    const Mo = 1024 * 1024;
    const Go = 1024 * 1024 * 1024;
    const To = 1024 * 1024 * 1024 * 1024;

    if (ping) {
        value = `${parseFloat(`${initialValue}`).toFixed(2)} ms`;
    } else if (percent) {
        value = `${parseFloat(`${initialValue}`).toFixed(0)} %`;
    } else {
        if (initialValue <= Mo) {
            value = `${parseFloat(`${initialValue / Ko}`).toFixed(1)} Ko`;
        } else if (initialValue <= Go) {
            value = `${parseFloat(`${initialValue / Mo}`).toFixed(1)} Mo`;
        } else if (initialValue <= To) {
            value = `${parseFloat(`${initialValue / Go}`).toFixed(1)} Go`;
        } else {
            value = `${parseFloat(`${initialValue / To}`).toFixed(1)} To`;
        }
    }
    return value;
};

export const convertValueUnit = (initialValue: number, ping?: boolean, percent?: boolean) => {
    let value;

    const KB = 1024;
    const MB = 1024 * 1024;
    const GB = 1024 * 1024 * 1024;
    const TB = 1024 * 1024 * 1024 * 1024;

    if (ping) {
        value = `${parseFloat(`${initialValue}`).toFixed(2)} ms`;
    } else if (percent) {
        value = `${parseFloat(`${initialValue}`).toFixed(0)} %`;
    } else {
        initialValue = initialValue * 8;
        if (initialValue <= MB) {
            value = `${parseFloat(`${initialValue / KB}`).toFixed(1)} Kbps`;
        } else if (initialValue <= GB) {
            value = `${parseFloat(`${initialValue / MB}`).toFixed(1)} Mbps`;
        } else if (initialValue <= TB) {
            value = `${parseFloat(`${initialValue / GB}`).toFixed(1)} Gbps`;
        } else {
            value = `${parseFloat(`${initialValue / TB}`).toFixed(1)} Tbps`;
        }
    }
    return value;
};
