import React from 'react';
import { InterfaceType, RelaisType } from '../../../../../../../types';
import TextField from '../../../../../../../common/TextField/TextField';
import { Switch } from 'antd';

interface Props {
    interf: InterfaceType;
    relais: RelaisType | undefined;
    interfaceIndex: number;
    changeValue: (path: string, value: string | boolean) => void;
    role: any
}

class Relais extends React.Component<Props> {
    render() {
        const { relais, interfaceIndex, changeValue, role } = this.props;
        const key = window.location.pathname.substring(1)
        const rolesCondition = role[0] && role[0][key].write === false ? true : false;
        return (
            <div>
                <div className={'Relais ctn__fields'}>
                    <div>
                        <label>État</label>
                        <Switch
                            defaultChecked={relais?.enabled}
                            onChange={(checked: boolean) =>
                                changeValue(`Interface[${interfaceIndex}].Relais.enabled`, checked)
                            }
                            disabled={rolesCondition}
                        />
                    </div>
                    <div>
                        <label>IP relais 1</label>
                        <TextField
                            className={''}
                            placeholder={'192.168.1.1'}
                            name={`Interface[${interfaceIndex}].Relais.relay_ip1`}
                            value={relais ? relais.relay_ip1 : ''}
                            changeValue={changeValue}
                            required={relais?.enabled}
                            type={'text'}
                            disabled={rolesCondition}
                            isIp={true}
                        />
                    </div>
                    <div>
                        <label>IP relais 2</label>
                        <TextField
                            className={''}
                            placeholder={'192.168.1.1'}
                            name={`Interface[${interfaceIndex}].Relais.relay_ip2`}
                            value={relais ? relais.relay_ip2 : ''}
                            changeValue={changeValue}
                            type={'text'}
                            disabled={rolesCondition}
                            isIp={true}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Relais;
