import React from "react";
import moment from "moment";
import { ConfigProvider, DatePicker, Switch } from "antd";
// import locale from "antd/es/date-picker/locale/fr_FR";
import frFR from "antd/lib/locale/fr_FR";
import "dayjs/locale/fr";
import { EquipmentType, SiteType } from "../../../types";
import SelectSearch from "../../../common/SelectSearch/SelectSearch";
import "moment/locale/fr";

moment.locale("fr");

interface State {
    date_start: Date;
    date_end: Date;
    site_id: string;
    equipment_id: string;
    day_start: Date;
}

interface Props {
    date_start: Date;
    date_end: Date;
    Sites: Array<SiteType>;
    site_id: string;
    changeValues: (value: State) => void;
    equipment_id: string;
    live: boolean;
    changeSwitchValue: (value: boolean) => void;
    changeUnitValue?: (value: "octets" | "bytes") => void;
    changeUnit?: boolean;
    unit?: "octets" | "bytes";
}

class Filters extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            date_start: new Date(new Date().setHours(new Date().getHours() - 1)),
            date_end: new Date(),
            site_id: "",
            equipment_id: "",
            day_start: new Date(),
        };
    }

    conditionsChangeValue = (prevProps: Props, props: Props) =>
        prevProps.date_start !== props.date_start ||
        prevProps.date_end !== props.date_end ||
        prevProps.site_id !== props.site_id ||
        prevProps.equipment_id !== props.equipment_id;

    componentDidMount() {
        this.getDataParents();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.conditionsChangeValue(prevProps, this.props)) {
            this.getDataParents();
        }
    }

    getDataParents = () => {
        const { date_start, date_end, site_id, equipment_id } = this.props;
        this.setState(() => ({
            date_start,
            date_end,
            site_id,
            equipment_id,
        }));
    };

    changeDateStart = (e: any) => {
        if (e && e._d) {
            this.setState(() => ({
                date_start: e._d,
            }));
        }
    };

    changeDateEnd = (e: any) => {
        if (e && e._d) {
            this.setState(() => ({
                date_end: e._d,
            }));
        }
    };

    changeValue = (name: string, value: string) => {
        if (name === "site_id") {
            const matchingSite = this.props.Sites.find((elem) => elem._id === value);
            //@ts-ignore
            const newFirstEquipmentId = matchingSite ? matchingSite.Equipment[0]?._id : undefined;
            if (newFirstEquipmentId !== undefined) {
                this.setState((state) => ({
                    ...state,
                    [name]: value,
                    equipment_id: newFirstEquipmentId,
                }));
            }
        } else {
            this.setState((state) => ({ ...state, [name]: value }));
        }
    };

    submit = () => {
        this.props.changeValues(this.state);
    };

    render() {
        const { Sites, live, changeSwitchValue, changeUnit, unit, changeUnitValue } = this.props;
        const { date_start, date_end, site_id, equipment_id } = this.state;
        let Equipments: Array<EquipmentType> = [];
        const Site = Sites.find((s) => s._id === site_id);
        if (Site && Site.Equipment) Equipments = Site.Equipment;

        return (
            <ConfigProvider locale={frFR}>
                <div className={"Filters"}>
                    {/* @ts-ignore */}
                    {/*<div className={'ctn__filter'}>*/}
                    {/*    <label>Live</label>*/}
                    {/*    <Switch checked={live} onChange={changeSwitchValue} />*/}
                    {/*</div>*/}
                    <div className={"ctn__filter"}>
                        <label>Date et heure de début</label>
                        {/*<RangePicker*/}
                        {/*    allowClear={false}*/}
                        {/*    showTime*/}
                        {/*    defaultValue={[moment(date_start, 'DD-MM-YYYY'), moment(date_start, 'DD-MM-YYYY')]}*/}
                        {/*    onChange={this.changeDate}*/}
                        {/*/>*/}
                        <div className="ctn__input__date" data-error={date_start > date_end}>
                            <DatePicker
                                defaultValue={moment(date_start, "DD/MM/YYYY")}
                                format={["DD/MM/YYYY", "DD/MM/YY"]}
                                onChange={this.changeDateStart}
                                value={moment(date_start, "DD/MM/YYYY")}
                            />
                            <DatePicker
                                picker={"time"}
                                onChange={this.changeDateStart}
                                defaultValue={moment(date_start)}
                                value={moment(date_start, "DD/MM/YYYY")}
                            />
                        </div>
                    </div>
                    <div className={"ctn__filter"}>
                        <label>Date et heure de fin</label>
                        {/*<RangePicker*/}
                        {/*    allowClear={false}*/}
                        {/*    showTime*/}
                        {/*    defaultValue={[moment(date_end, 'DD-MM-YYYY'), moment(date_end, 'DD-MM-YYYY')]}*/}
                        {/*    onChange={this.changeDate}*/}
                        {/*/>*/}
                        <div className="ctn__input__date" data-error={date_start > date_end}>
                            <DatePicker
                                defaultValue={moment(date_end, "DD/MM/YYYY")}
                                onChange={this.changeDateEnd}
                                format={["DD/MM/YYYY", "DD/MM/YY"]}
                                value={moment(date_end, "DD/MM/YYYY")}
                            />
                            <DatePicker
                                picker={"time"}
                                onChange={this.changeDateEnd}
                                defaultValue={moment(date_end)}
                                value={moment(date_end, "DD/MM/YYYY")}
                            />
                        </div>
                    </div>
                    <div className={"ctn__filter"}>
                        <label>Site</label>
                        <SelectSearch
                            placeholder={"Sites"}
                            items={Sites}
                            changeValue={(value) => this.changeValue("site_id", value)}
                            valuesName={"_id"}
                            valuesDisplay={"name"}
                            value={site_id}
                            // roles={roles}
                        />
                    </div>
                    <div className={"ctn__filter"}>
                        <label>Équipement</label>
                        <SelectSearch
                            items={Equipments}
                            placeholder={"Équipement"}
                            changeValue={(value) => this.changeValue("equipment_id", value)}
                            valuesName={"_id"}
                            valuesDisplay={"name"}
                            value={equipment_id}
                            // roles={roles}
                        />
                    </div>
                    <div className={"ctn__btn__submit"}>
                        <button onClick={this.submit} disabled={date_start > date_end}>
                            Valider
                        </button>
                    </div>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {changeUnit && changeUnitValue !== undefined && (
                        <div className={"ctn__btn__unit"}>
                            <button onClick={() => changeUnitValue(unit == "octets" ? "bytes" : "octets")}>
                                {unit === "octets" ? " Afficher en Bits" : "Afficher en Octets"}
                            </button>
                        </div>
                    )}
                </div>
            </ConfigProvider>
        );
    }
}

export default Filters;
