import React from 'react';
import "./Loader.scss"

class Loader extends React.Component<any, any> {
	render() {
		return (
			<div className={"loader"}>
				<div className="chaotic-orbit"></div>
			</div>
		)
	}
}

export default Loader;
