import React from "react";
import { EntityType, InterfaceType, QosType } from "../../../types";
import BasicTable from "../../../common/BasicTable/BasicTable";
import BarTableActions from "../../../common/BarTableAction/BarTableActions";
import AddButton from "../../../common/AddButton/AddButton";
import QosForm from "./QosForm/QosForm";
import Collections from "../../../utils/Collections";
import ContainerRestPage from "../../../common/ContainerRestPage/ContainerRestPage";
import IconEdit from "../../../common/SVGComponents/IconEdit";
import IconUpdate from "../../../common/SVGComponents/IconUpdate";
import Socket from "../../../utils/Socket";
import { Alert, notification, Modal } from "antd";
import Interfaces from "../../Equipements/Liste/EquipmentForm/Interfaces/Interfaces";
import { deselectOptions } from "@testing-library/user-event/dist/select-options";
import { qosState } from "../../../state";

interface Props {
    qos: Array<QosType> | undefined;
    Interfaces: Array<InterfaceType> | undefined;
    entities: Array<EntityType>;
    entityId: string;
    role: any;
}

interface State {
    selectedSource: string;
    selectedDestinations: Array<string>;
    formType: string;
    visibleForm: boolean;
    message: Array<{ type: string; message: string }>;
    interfaceAlreadyUsed: boolean | null;
    promise: Array<any>;
    qos: QosType;
    allQos: Array<QosType>;
    interfaces: Array<InterfaceType>;
}

const openNotificationWithIcon = (type: string, qos: string, action: string) => {
    //@ts-ignore
    notification[type]({
        style: { marginTop: "15rem" },
        duration: 5,
        description: `La QoS ${qos} a bien été ${action}`,
    });
};

const qosColumns = [
    {
        Header: "Nom de la QoS",
        accessor: "name",
        sort: true,
        Cell: function cell(row: { original: QosType }) {
            return (
                <div className={"table__cell__color"}>
                    <div
                        style={{
                            position: "absolute",
                            height: "2rem",
                            width: "2rem",
                            marginLeft: "43rem",
                            borderRadius: "60%",
                            backgroundColor: `${row.original.color}`,
                        }}
                    />
                    <div>
                        <span>{row.original.name}</span>
                    </div>
                </div>
            );
        },
    },
];

const interfaceColumns = [
    {
        Header: "Interface",
        accessor: "name",
        sort: true,
    },
    // {
    //     Header: 'Nom du site',
    //     accessor: 'site_name',
    //     sort: true,
    // },
    {
        Header: "N°série",
        accessor: "serial",
        sort: true,
    },
    {
        Header: "Équipement",
        accessor: "boitier_name",
        sort: true,
    },
];

class Qos extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedSource: "",
            selectedDestinations: [],
            formType: "",
            visibleForm: false,
            qos: qosState,
            message: [
                {
                    type: "",
                    message: "",
                },
            ],
            interfaceAlreadyUsed: null,
            promise: [],
            interfaces: [],
            allQos: [],
        };
    }

    componentDidMount() {
        const { entities } = this.props;
        const Interfaces = Collections.flatRecursive(entities, "Interface", [], "");
        this.setState({ interfaces: Interfaces });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
        const { qos } = this.props;

        if (this.state.selectedSource !== prevState.selectedSource && qos) {
            this.setState(() => ({
                selectedDestinations: this.getDestinationsComparedSource(),
                qos: qos?.filter((elem) => elem._id === this.state.selectedSource)[0],
                allQos: qos,
            }));
        }

        if (this.props.entityId !== prevProps.entityId) {
            this.setState(() => ({ selectedSource: "", selectedDestinations: [] }));
        }

        // if(this.props.qos !== prevProps.qos) {
        //     this.setState(() => ({
        //         selectedDestinations: this.getDestinationsComparedSource(),
        //     }));
        // }
        if (prevProps.qos && this.props.qos && prevProps.qos.length < this.props.qos.length) {
            let qos = [...this.props.qos];
            if (qos.length > 0) {
                //@ts-ignore
                openNotificationWithIcon("success", qos.splice(qos.length - 1, 1)[0].name, "créée");
                // this.setState(() => ({
                //     message: [{
                //         type: "success",
                //         message: `La QoS ${qos.splice(qos.length - 1, 1)[0].name} a bien été créée `
                //     }]
                // }));
            }
        }
    }

    getDestinationsComparedSource(): Array<string> {
        const { qos, Interfaces } = this.props;
        let destinations: any = [];
        if (qos && qos.length > 0) {
            const findSpecificCos = qos.find((q) => q._id === this.state.selectedSource);
            if (findSpecificCos && Interfaces) {
                for (const interf of Interfaces) {
                    if (interf.qos_id !== findSpecificCos._id) continue;
                    destinations.push(interf._id);
                }
            }
        }

        return destinations;
    }

    refactorData = () => {
        const { selectedSource } = this.state;
        const { qos, entities } = this.props;
        const interfaces = Collections.flatRecursive(entities, "Interface", [], "");
        const equipments = Collections.flatRecursive(entities, "Equipment", [], "");
        const interfacesInQos = qos ? qos.flatMap((q) => q.interfaces) : [];
        const newInterfaces = [];
        let Interface;

        // @ts-ignore
        const interfQosSelect = selectedSource && qos.find((q) => q._id === selectedSource);

        let interfs = interfaces;
        if (selectedSource) {
            interfs = [
                ...interfaces.filter((interf) => interf.qos_id === selectedSource),
                ...interfaces.filter((interf) => interf.qos_id !== selectedSource),
            ];
        }
        for (const interf of interfs) {
            let isConnected = false;
            let boitier_name = "";
            let serial = "";
            let site_name = "";
            let equipment = equipments.filter((elem, i) => elem._id === interf.equipment_id)[0];
            let qos_name = "";
            let qos_color = "";

            if (interf.qos_id && qos) {
                let test = qos.find((elem) => elem._id === interf.qos_id);
                if (test) {
                    qos_name = test.name;
                    qos_color = test.color;
                }
            }
            if (interfQosSelect && interfQosSelect.interfaces.includes(interf._id)) {
                isConnected = interfacesInQos.includes(interf._id);
                boitier_name = equipment.name;
                serial = equipment.serial;
                site_name = equipment.site_name;
            } else {
                isConnected = interfacesInQos.includes(interf._id);
                boitier_name = equipment.name;
                serial = equipment.serial;
                site_name = equipment.site_name;
            }
            newInterfaces.push({ ...interf, isConnected, boitier_name, serial, site_name, qos_name, qos_color });
        }
        if (newInterfaces.length > 0) {
            Interface = newInterfaces.filter((interfaces) => interfaces.enabled === true);
        }
        return Interface?.filter((elem: InterfaceType) => elem.zone !== 'LAN');
    };

    selectDestinations = (selectedDestinations: Array<string>) => {
        this.setState(() => ({ selectedDestinations }));
    };

    selectSource = (id: string) => {
        this.setState((state) => ({ selectedSource: state.selectedSource === id ? "" : id }));
    };

    openForm = (formType: string, selectedItem?: string): void => {
        if (selectedItem) {
            this.setState(() => ({ visibleForm: true, formType, selectedSource: selectedItem }));
        } else {
            this.setState(() => ({ visibleForm: true, formType }));
        }
    };

    closeForm = (): void => {
        this.setState(() => ({ visibleForm: false, formType: "", selectedSource: "" }));
    };

    closeEdit = () => {
        this.setState((state) => ({
            ...state,
            selectedSource: "",
        }));
    };

    deleteItems = async () => {
        const { qos, entities } = this.props;
        const { selectedSource, selectedDestinations } = this.state;
        const Interfaces = Collections.flatRecursive(entities, "Interface", [], "");
        const promises = [];
        let qosSelected = qos?.filter((elem) => elem._id === selectedSource);
        let interfacesToUpdate: Array<InterfaceType> = [];
        let qosName = "";

        if (qosSelected && qosSelected.length > 0) {
            qosName = qosSelected[0].name;
            const res = Socket.remove("Qos", qosSelected[0].entity_id, qosSelected[0]._id);
            promises.push(res);

            if (selectedDestinations.length > 0) {
                selectedDestinations.map((elem) => {
                    interfacesToUpdate.push(Interfaces.filter((interfaces) => interfaces._id === elem)[0]);
                });
            }

            if (interfacesToUpdate.length > 0) {
                interfacesToUpdate.map((elem: InterfaceType) => {
                    elem.qos_id = "";
                    const res2 = Socket.update("Interface", elem._id, "", elem);
                    promises.push(res2);
                });
            }
        }
        await Promise.all(promises).then((res: Array<{ message: string; status: number }>) => {
            if (res[0].status === 200) {
                openNotificationWithIcon("warning", qosName, "supprimée");
            }
        });
        this.setState(() => ({ selectedSource: "" }));
    };

    addElement = () => {
        this.openForm("create");
    };

    LinkedInterfaceToQos = () => {
        const { qos, selectedDestinations, interfaces } = this.state;

        let tab: string[] = [];

        for (const selectDestination of selectedDestinations) {
            for (const interf of interfaces) {
                if (selectDestination === interf._id) {
                    tab.push(interf._id);
                }
            }
        }

        this.setState(
            (state) => ({ qos: { ...state.qos, interfaces: tab } }),
            () => Socket.update("Qos", qos._id, this.props.entityId, this.state.qos)
        );
    };

    LinkedInterfaceAlreadyExistToQos = () => {
        const { selectedDestinations, interfaces, qos } = this.state;
        let tab: string[] = [];
        return interfaces
            .filter(
                (interf) =>
                    selectedDestinations.includes(interf._id) &&
                    interf.qos_id &&
                    interf.qos_id !== this.state.selectedSource
            )
            .map((i) => i._id);
    };

    DeleteInterfaceAlreadyExistToQos = () => {
        const { qos, allQos, interfaces } = this.state;
        const LinkedInterfaceAlreadyExistToQos = this.LinkedInterfaceAlreadyExistToQos();
        const tabs: QosType[] = [];

        for (const qos of allQos) {
            for (const interf of LinkedInterfaceAlreadyExistToQos) {
                for (const interfQos of qos.interfaces) {
                    if (interfQos === interf) {
                        qos.interfaces.splice(0, 1);
                        tabs.push(qos);
                    }
                }
            }
        }
        for (const tab of tabs) {
            this.setState(
                () => ({ allQos: tabs }),
                () => Socket.update("Qos", tab._id, this.props.entityId, tab)
            );
        }
    };

    LinkedQosIdToInterface = () => {
        const { qos, selectedDestinations } = this.state;
        const newInterfaces: InterfaceType[] = [];
        for (const selectedDestination of selectedDestinations) {
            this.setState(
                (state) => ({
                    interfaces: state.interfaces.map((elem) =>
                        elem._id === selectedDestination ? Object.assign(elem, { qos_id: qos._id }) : elem
                    ),
                }),
                () =>
                    Socket.update(
                        "Interface",
                        selectedDestination,
                        this.props.entityId,
                        this.state.interfaces.filter((elem) => elem._id === selectedDestination)[0]
                    )
            );
        }
    };

    DeselectInterfaceToQos = () => {
        const { qos, selectedDestinations } = this.state;

        this.setState(
            () => ({
                qos: {
                    ...qos,
                    interfaces: selectedDestinations.filter((elem) => qos.interfaces.includes(elem)),
                },
            }),
            () => Socket.update("Qos", qos._id, this.props.entityId, this.state.qos)
        );
    };

    EmptyQosIdToInterface = () => {
        const { qos, selectedDestinations } = this.state;
        const deselectInterfaces = qos.interfaces.filter((elem) => selectedDestinations.indexOf(elem) == -1);

        for (const deselectInterface of deselectInterfaces) {
            this.setState(
                (state) => ({
                    interfaces: state.interfaces.map((elem) =>
                        elem._id === deselectInterface ? Object.assign(elem, { qos_id: "" }) : elem
                    ),
                }),
                () =>
                    Socket.update(
                        "Interface",
                        deselectInterface,
                        this.props.entityId,
                        this.state.interfaces.filter((elem) => elem._id === deselectInterface)[0]
                    )
            );
        }
    };

    linkIfNotCrush = () => {
        this.EmptyQosIdToInterface();
        this.DeselectInterfaceToQos();
        // this.LinkedInterfaceToQos()
        this.LinkedQosIdToInterface();
        this.setState(() => ({ selectedSource: "", selectedDestinations: [] }));
    };

    ConfirmInterfaceAlreadyUsed = () => {
        const { qos } = this.state;

        this.EmptyQosIdToInterface();
        this.DeselectInterfaceToQos();
        // this.LinkedInterfaceToQos()
        this.LinkedQosIdToInterface();
        // this.DeleteInterfaceAlreadyExistToQos()

        openNotificationWithIcon("success", qos.name, "actualisée");
        // this.setState({
        //     message: [{
        //         type: "success",
        //         message: `La QoS ${qos.name} a bien été actualisée `
        //     }]
        // })

        this.setState(() => ({ interfaceAlreadyUsed: false, selectedSource: "", selectedDestinations: [] }));
    };

    optionalItem = () => {
        const { selectedSource, selectedDestinations } = this.state;
        const { role } = this.props;
        let key = window.location.pathname.substring(1);
        const condition = role && role[0] && role[0][key].write;

        return (
            <div
                className={[selectedSource].length > 1 ? "d-none" : ""}
                onClick={() => condition === true && this.linkQos()}
            >
                <IconUpdate />
                <p>Actualiser</p>
            </div>
        );
    };

    insertQosIDToInterface() {
        const { selectedDestinations, selectedSource, qos } = this.state;
        const { Interfaces, entityId } = this.props;
        const promises = [];
        if (Interfaces) {
            for (const interf of Interfaces) {
                if (!selectedDestinations.includes(interf._id) && interf.qos_id === selectedSource) {
                    promises.push(Socket.update("Interface", interf._id, entityId, { ...interf, qos_id: "" }));
                }
                if (!selectedDestinations.includes(interf._id)) continue;
                if (selectedDestinations.includes(interf._id) && interf.qos_id === selectedSource) continue;
                promises.push(Socket.update("Interface", interf._id, entityId, { ...interf, qos_id: selectedSource }));
            }
        }
        Promise.all(promises)
            .then((res) => {
                openNotificationWithIcon("success", qos.name, "actualisée");

                this.setState(() => ({ interfaceAlreadyUsed: false, selectedSource: "", selectedDestinations: [] }));
            })
            .catch((e) => console.error(e));
    }

    linkQos() {
        if (this.LinkedInterfaceAlreadyExistToQos().length === 0) {
            this.insertQosIDToInterface();
        } else {
            this.setState(() => ({ interfaceAlreadyUsed: true }));
        }
    }
    render() {
        const { qos, entities, entityId, role } = this.props;
        const { selectedSource, selectedDestinations, visibleForm, formType, message, interfaceAlreadyUsed } =
            this.state;
        console.log(this.refactorData());
        return (
            <>
                {message && message.length > 0 && message[0].type !== "" && (
                    <>
                        {/*    Animation In and Out className={"scale-in-top"} */}
                        {/* @ts-ignore */}
                        <Alert
                            style={{ width: "30%", marginLeft: "60rem", fontSize: "2rem" }}
                            message={message[0].message}
                            type={"success"}
                            showIcon
                            closable
                            banner={true}
                        />
                        <br />
                    </>
                )}
                {interfaceAlreadyUsed && (
                    <Modal
                        title={"Une Interface contenant une qos existante s'apprête à être changée ?"}
                        visible={interfaceAlreadyUsed}
                        className="w-10"
                        onOk={() => this.insertQosIDToInterface()}
                        onCancel={() => this.setState(() => ({ interfaceAlreadyUsed: false }))}
                    >
                        Voulez-vous sauvegarder les informations ?
                    </Modal>
                )}
                <div className={"Qos"}>
                    <div className={"ctn__table"}>
                        <div className={"d-flex"}>
                            <h3>Sources</h3>
                            <AddButton str={"Une QoS"} onClick={this.addElement} role={role} />
                        </div>
                        <ContainerRestPage>
                            <BasicTable
                                columns={qosColumns}
                                data={qos}
                                tableName={"table__qos"}
                                selectedItems={selectedSource}
                                selectItem={this.selectSource}
                            />
                        </ContainerRestPage>
                    </div>
                    <div className={"ctn__table__dest"}>
                        <h3>Destinations</h3>
                        <ContainerRestPage>
                            <BasicTable
                                selectedItems={selectedDestinations}
                                selectItems={this.selectDestinations}
                                columns={interfaceColumns}
                                data={this.refactorData()}
                                tableName={"table__qos"}
                            />
                        </ContainerRestPage>
                    </div>
                    <div>
                        <BarTableActions
                            selectedItems={selectedSource !== "" ? [selectedSource] : []}
                            openForm={this.openForm}
                            deleteItems={this.deleteItems}
                            closeBar={this.closeEdit}
                            optionalItem={this.optionalItem()}
                            role={role}
                        />
                    </div>
                    <QosForm
                        Qos={qos}
                        selectedItem={selectedSource}
                        Entities={entities}
                        onClose={this.closeForm}
                        visibleForm={visibleForm}
                        formType={formType}
                    />
                </div>
            </>
        );
    }
}

export default Qos;
