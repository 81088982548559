import React from 'react';
import {EntityType, FiltrageWebType, UserType, VpnUserType} from "../../../../types";
import BasicDrawerForm from "../../../../common/BasicDrawerForm/BasicDrawerForm";
import TextField from "../../../../common/TextField/TextField";
import {ifMailExist, ifNameExist} from "../../../../utils/CheckError";
import {each} from "lodash";
import {isIP} from "is-ip";
import isCidr from "is-cidr";
import {Alert} from "antd";
import {userState} from "../../../../state";
import SelectMultiple from "../../../../common/SelectMultiple/SelectMultiple";
import Collections from "../../../../utils/Collections";
import Socket from "../../../../utils/Socket";
import { v4 as uuidv4 } from "uuid";
import Loader from "../../../../common/Loader/Loader";

interface Props {
    User: UserType[] | undefined
    entityID: string
    selectedItems: string[]
    onClose: () => void
    visible: boolean
    formType: string,
    Entity: EntityType[];
}

interface State {
    user: UserType
    errorMessage: string,
    nomades: VpnUserType[],
    filtrages_web: FiltrageWebType[],
	loader: boolean
}

export default class UserForm extends React.Component<Props, State> {

    private formRef: HTMLDivElement | null

    constructor(props: any) {
        super(props);
        this.state = {
            user: userState,
            errorMessage: '',
            nomades: [],
            filtrages_web: [],
	        loader: false
        }
        this.formRef = null;
    }

    componentDidMount() {
        if (this.props.Entity) {
            const entity = this.props.Entity.find((entity) => entity._id === this.props.entityID);

            const filtrage = Collections.flatRecursive(this.props.Entity, 'FiltrageWeb', [], '') || [];
            this.setState({
                nomades: entity && entity.Vpn_user ? entity.Vpn_user : [],
                filtrages_web: filtrage
            })
        }
    }

    updateFiltrage = async () => {
         try {
             await Promise.all(this.state.filtrages_web.map(filtrage => {
                 return Socket.update('FiltrageWeb', filtrage._id, filtrage.entity_id, filtrage)
             }))

             await Promise.all(this.state.nomades.map(filtrage => {
                 return Socket.update('Vpn_user', filtrage._id, filtrage.entity_id, filtrage)
             }))
         } catch (e) {
             console.error(e)
         }

    }

    create = () => {

        Socket.insert('User', null, this.state.user)
            .then((res) => {
                const entityUser = {
                    entity_uuid: this.props.entityID,
                    user_uuid: res.data._id,
                    uuid_vpn_template: '',
                    user_type: '',
                    filtrage_web: '',
                    valid_from: '',
                    valid_to: '',
                    modified_by: '',
                    vpn_connected: false
                };
                this.updateFiltrage()
                //@ts-ignore
                Socket.insert('EntityUser', null, entityUser)
                    .then(res => {
                        this.props.onClose();
                    })

            })
            .catch((error) => console.error('Create user error: ', error));
    };

    update = () => {
		console.log(this.state.user)
        Socket.update('Users', this.state.user._id, null, this.state.user)
            .then((res) => {
                console.log(res);
                this.updateFiltrage();
                this.props.onClose();
            })
            .catch((error) => console.error(error));
    };

    duplicate = () => {
        this.setState(state => ({...state, user: {...state.user, '_id': ''}}), () => {
            this.create();
        })
    };

    validateIpAddress = (ipaddress: any, type: string) => {
        return type === "ip" && isIP(ipaddress) || isCidr(ipaddress) !== 0;
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.selectedItems !== this.props.selectedItems) {
            if (this.props.selectedItems.length > 0 && this.props.User && this.props.User.length > 0) {
                const userFind = this.props.User.find((user) => this.props.selectedItems[0] === user._id);
                if (userFind) {
                    this.setState((state) => ({...state, user: userFind}));
                }
            } else {
                this.setState((state) => ({...state, user: userState}));
            }
        }
        if (prevProps.formType !== this.props.formType && this.props.formType === 'create') {
            this.setState(() => ({user: {...userState, _id: uuidv4(), entity_id: this.props.entityID}}));
        }
        if (prevProps.visible !== this.props.visible) {
            if (!this.props.visible) {
                this.setState(() => ({errorMessage: ''}));
            }
        }
    }


    changeValue = (name: string, value: string | boolean) => {

        this.setState(state => ({...state, user: {...state.user, [name]: value}}))
    }

    checkRequiredError = () => {
        let error = false;
        let isIpValid: boolean;

        each(this.formRef?.querySelectorAll('[data-required]'), (element) => {
            // @ts-ignore
            const value = element.value ? element.value : element.getAttribute('data-value');
            const isIp = element.getAttribute("data-isip")

            if (isIp) isIpValid = this.validateIpAddress(value, "ip");


            if (!value || isIp && !isIpValid) {
                element.classList.add('error__field__form');
                error = true;
            } else {
                element.classList.remove('error__field__form');
            }
        });
        each(this.formRef?.querySelectorAll('[data-isip]'), (element) => {
            // @ts-ignore
            isIpValid = this.validateIpAddress(element.value, "ip");
            // @ts-ignore
            if (element.value && !isIpValid) {
                element.classList.add('error__field__form');
                error = true;
            }
        })
        return error;
    };

    submit = () => {
        if (ifNameExist(this.props.formType, this.state.user, this.props.User)) {
            this.setState(() => ({errorMessage: 'Nom Déjà existant'}));
        }
		else if (ifMailExist(this.props.formType, this.state.user, this.props.User)) {
	        this.setState(() => ({errorMessage: 'Mail Déjà existant'}));
        }

		else if (this.checkRequiredError()) {
            this.setState(() => ({errorMessage: 'Des champs semblent incorrects ou manquants.'}));
        } else {
            // @ts-ignore
            this[this.props.formType]();
        }
    }

    selectNomades = (values: string[]) => {
        const newNomades = []
        for (const nomade of this.state.nomades) {
            let newNomade = {...nomade};
            if (!newNomade.local) newNomade.local = [];

            if(values.includes(nomade._id) && !newNomade.local.includes(this.state.user._id) ) {
                newNomade.local.push(this.state.user._id)
            } else if (!values.includes(nomade._id) && newNomade.local.includes(this.state.user._id)) {
                newNomade.local = newNomade.local.filter(id => id !== this.state.user._id);
            }
            newNomades.push(newNomade)
        }
        this.setState({nomades: newNomades})
    }

    selectFiltrages = (values: string[]) => {
        const newFiltrages = []
        for (const filtrage of this.state.filtrages_web) {
            let newFiltrage = {...filtrage};
            if (!newFiltrage.local) newFiltrage.local = [];

            if(values.includes(filtrage._id) && !newFiltrage.local.includes(this.state.user._id) ) {
                newFiltrage.local.push(this.state.user._id)
            } else if (!values.includes(filtrage._id) && newFiltrage.local.includes(this.state.user._id)) {
                newFiltrage.local = newFiltrage.local.filter(id => id !== this.state.user._id);
            }
            newFiltrages.push(newFiltrage)

        }
        this.setState({filtrages_web: newFiltrages})
    }

    render() {

        const selectedNomades = this.state.nomades.filter(nomade =>  nomade.local && nomade.local.includes(this.state.user._id)).map(n => n._id);
        const selectedFiltrage = this.state.filtrages_web.filter(filtrage => {
            return filtrage.local && filtrage.local.includes(this.state.user._id)
        }).map(n => n._id);
        console.log(this.state.user);
        return (
            <BasicDrawerForm className={`ipsec__form ipsec__form__${this.props.formType}`} onClose={this.props.onClose}
                             submit={this.submit} visible={this.props.visible} header={<p>Utilisateurs</p>}
                             data={this.state.user} formType={this.props.formType}>
	            {!this.state.loader ?
		            <>
                <div ref={(form) => (this.formRef = form)}>
                    <div className={'ipsec__form__row'}>
                        <label>Prénom</label>
                        <TextField className={''} placeholder={'Prénom'} name={'firstname'} value={this.state.user.firstname}
                                   changeValue={this.changeValue} type={'text'} required={true}/>
                    </div>
                    <div className={'ipsec__form__row'}>
                        <label>Nom</label>
                        <TextField className={''} placeholder={'Nom'} name={'lastname'} value={this.state.user.lastname}
                                   changeValue={this.changeValue} type={'text'} required={true}/>
                    </div>

                    <div className={'ipsec__form__row'}>
                        <label>Mot de passe</label>
                        <TextField className={''} placeholder={'Entrez un mot de passe'} name={'password'} value={this.state.user.password}
                                   changeValue={this.changeValue} type={"password"} required={true}/>
                    </div>

                    <div className={'ipsec__form__row'}>
                        <label>E-mail</label>
                        <TextField  className={''} placeholder={'Entrez une adresse email'} name={'email'} value={this.state.user.email}
                                   changeValue={this.changeValue} type={'text'} required={true}/>
                    </div>

                    <div className={'ipsec__form__row'}>
                        <label>Téléphone</label>
                        <TextField className={''} placeholder={'Entrez un numéro de téléphone'} name={'mobile'} value={this.state.user.mobile}
                                   changeValue={this.changeValue} type={'text'} required={true}/>
                    </div>

                    <div className={'ipsec__form__row'}>
                        <label>Profil de filtrage</label>
                        <SelectMultiple
                            items={this.state.filtrages_web}
                            changeValue={this.selectFiltrages}
                            valuesName={'_id'}
                            valuesDisplay={'name'}
                            value={selectedFiltrage}
                            placeholder={'Sélectionner vos filtrages'}
                            disabled={this.props.formType === "view"}
                        />
                    </div>

                    <div className={'ipsec__form__row'}>
                        <label>Profil de nomade</label>
                        <SelectMultiple
                            items={this.state.nomades}
                            changeValue={this.selectNomades}
                            valuesName={'_id'}
                            valuesDisplay={'name'}
                            value={selectedNomades}
                            placeholder={'Sélectionner vos nomades'}
                            name={""}
                            disabled={this.props.formType === "view"}
                        />
                    </div>

                    {this.state.errorMessage && (
                        <Alert className={'alert__error__fields'} message={this.state.errorMessage} type="error"/>
                    )}
                </div>
            </>
		    :
			    <div className={"contain-loader"}>
				    <Loader />
			    </div>
		    }
            </BasicDrawerForm>
        )
    }
}