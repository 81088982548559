import React from "react";
import { Dropdown } from "antd";
import DefaultProfil from "../../../../assets/images/defaultProfil.png";
import Logout from "../../../SVGComponents/Logout";
import axios from "axios";
import User from "../../../../utils/User";
import { EntityType, UserType } from "../../../../types";
import { withRouter } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";

interface State {
    imgValide: boolean;
    userInfo: UserType;
}

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
    };
    global: {
        entity_id: string;
        equipment_id: string;
        entities: any;
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entity_id: state.global.entity_id,
    };
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

class SettingsButton extends React.PureComponent<PropsFromRedux, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            imgValide: false,
            userInfo: {
                _id: "",
                parent_id: "",
                lastname: "",
                firstname: "",
                email: "",
                mobile: "",
                password: "",
                profile: "",
                picture: "",
                enabled: true,
                role_id: "",
                valid_from: "",
                valid_to: "",
                modified_by: "",
            },
        };
    }

    componentDidMount() {
        this.checkImageValide();
    }

    componentDidUpdate(prevProps: Readonly<PropsFromRedux>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.entity_id !== this.props.entity_id) {
            this.checkImageValide();
        }
    }

    logout = () => {
        console.log(process.env.NODE_ENV);
        User.removeToken();
        window.location.replace(`${process.env.REACT_APP_REDIRECTION}`);
    };

    menu() {
        const { userInfo } = this.state;
        const word = "sayse";
        return (
            <div className={"SettingsButton__dropdown"}>
                <button
                    style={{ borderBottom: "solid #C0C0C0 1px" }}
                    onClick={() => window.location.replace(`${process.env.REACT_APP_REDIRECTION}/home`)}
                >
                    Accueil
                </button>
                {userInfo && userInfo.email && userInfo.email.includes(word) && (
                    <button
                        style={{ borderBottom: "solid #C0C0C0 1px" }}
                        formTarget="_blank"
                        onClick={() =>
                            window.open(`https://forms.clickup.com/36744436/f/131b7m-1682/IK0STI07U50N6MLA0V`)
                        }
                    >
                        Suggestion
                    </button>
                )}
                <button className={"Logout"} onClick={() => this.logout()}>
                    <Logout />
                    Déconnexion
                </button>
            </div>
        );
    }

    checkImageValide = async () => {
        const user = await User.userInfo();
        this.setState({ userInfo: user });

        const img = axios.get(`${process.env.REACT_APP_BACK_AUTH_URL}/user/${user && user._id + ".png"}`);
        img.then((res: any) => {
            if (res.status === 200) {
                this.setState({ imgValide: true });
            }
        }).catch((error: any) => console.error(error));
    };

    render() {
        return (
            <Dropdown overlayClassName={""} overlay={this.menu()} placement="bottomRight">
                <div className={"ctn__img"}>
                    <img
                        src={
                            this.state.imgValide
                                ? `${process.env.REACT_APP_BACK_AUTH_URL}/user/${
                                      this.state.userInfo && this.state.userInfo._id + ".png"
                                  }?${global.Date.now()}`
                                : DefaultProfil
                        }
                        alt="wanup_photo_profile"
                    />
                </div>
            </Dropdown>
        );
    }
}

export default connector(SettingsButton);
