import {Tooltip} from "antd";
import IconInfo from "../common/SVGComponents/IconInfo";

// const refactorName = (name: string) => {
//     let newName = '';
//     if (name) {
//         for (let i = 0; i < name.length; i++) {
//             const char = name[i];
//             if (char.match(/[A-Z]/g) && i > 0) newName += ` `;
//             newName += char;
//         }
//     }
//     return newName;
// }

export const tooltipInfo = (tabName: string, tooltip?: string) => {
    return (
        <div className={'tab_tooltip'}>
            <span>{tabName}</span>
            {tooltip && (
                <Tooltip placement="topRight" title={tooltip}>
                    <button className={'btn__tooltip'}>
                        <IconInfo/>
                    </button>
                </Tooltip>
            )}
        </div>
    );
}