import React from "react";
import {RoutageType, GatewayType} from "../../../../../../../types";
import {Dropdown, Radio, Button} from "antd";
import DragNDrop from "../../../../../../../common/DragNDrop/DragNDrop";
import IconAdd from "../../../../../../../common/SVGComponents/IconAdd";
import gateway from "../../../Passerelles/Gateway/Gateway";

interface Props {
    routage: RoutageType;
    index: number;
    changeValue: (path: string, value: string | Array<any>) => void;
    Gateways: Array<GatewayType>;
    role: any;
}

interface State {
    gateways: Array<GatewayType>;
    gatewaySort: Array<GatewayType>;
}

class RoutageStatus extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            gateways: [],
            gatewaySort: [],
        };
    }

    refactorGateways = () => {
        const {routage, Gateways} = this.props;
        const {gateway} = routage;

        const newGateways = [...gateway];
        newGateways.push(...Gateways.filter(gateway => !newGateways.map(r => r._id).includes(gateway._id)));

        return newGateways.filter(g => Gateways.map(r => r._id).includes(g._id));
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.Gateways !== this.props.Gateways) {
            this.setState(() => ({gatewaySort: this.refactorGateways()}))
        }
    }

    componentDidMount() {
        this.setState(() => ({gatewaySort: this.refactorGateways()}))
    }

    changeRadio = (e: any) => {
        const {changeValue, index} = this.props;
        changeValue(`Routage[${index}].status`, e.target.value);
    };

    selectGateways = (id: string, value: string) => {
        const {changeValue, index, routage} = this.props;
        let newData = {...routage};
        if (value === "load_balancing") {
            newData.load_balancing.push(id);
            changeValue(`Routage[${index}].load_balancing`, newData.load_balancing);
        } else {
            changeValue(`Routage[${index}].autonomous`, [id]);
        }
    };

    getDragPositions = (positions: Array<string>) => {
        const {routage, changeValue, index} = this.props;
        const gateway = routage.gateway.map((gateway, index) => ({
            ...gateway,
            drag_order: parseInt(positions[index]),
        }));
        const n = this.state.gatewaySort.map((gateway, index) => ({
            ...gateway,
            drag_order: parseInt(positions[index]),
        })).sort((a, b) => a.drag_order - b.drag_order)
        changeValue(`Routage[${index}].gateway`, n);
    };

    render() {
        const {routage, Gateways, role} = this.props;
        const {gateways} = this.state;
        let arr = [...gateways];
        if (this.props.routage.load_balancing.length > 1) {
            routage.load_balancing.map((elem) => {
                arr.splice(
                    arr.findIndex((elements) => elements._id === elem),
                    1
                );
            });
        }
        const key = window.location.pathname.substring(1);
        const rolesCondition = !(role[0] && role[0][key].write);
        // const rolesCondition = !!(role[0] && role[0][key].write === true);
        return (
            <div className={"RoutageStatus"}>
                <Radio.Group onChange={this.changeRadio} value={routage.status} disabled={rolesCondition}>
                    <Radio value={"failover"}>Failover</Radio>
                    <Radio value={"load_balancing"}>Load balancing</Radio>
                    <Radio value={"autonomous"}>Autonome</Radio>
                </Radio.Group>
                <div
                    style={{
                        display: routage.status === "load_balancing" ? "flex" : "none",
                    }}
                    className="container-gatewaySelected"
                >
                    <div className="item-gateway">
                        {routage.load_balancing.length > 0 ? (
                            Gateways &&
                            Gateways.filter(
                                (elem) => elem._id === routage.load_balancing[0]
                            ).map((elem, i) => {
                                return (
                                    <div className="gatewaySelected" key={i}>
                                        <div className={"port_bg_blue"}/>
                                        <p>{elem.name}</p>
                                    </div>
                                );
                            })
                        ) : (
                            <Dropdown
                                placement={"bottomCenter"}
                                className={"dropdown"}
                                disabled={rolesCondition}
                                overlay={
                                    <div className={"optionsDropdown"}>
                                        {Gateways &&
                                            Gateways.filter((elem) => elem._id).map((elem, i) => {
                                                return (
                                                    <Button
                                                        key={i}
                                                        value={elem._id}
                                                        className="btn-value"
                                                        onClick={() =>
                                                            this.selectGateways(elem._id, "load_balancing")
                                                        }
                                                    >
                                                        {elem.name}
                                                    </Button>
                                                );
                                            })}
                                    </div>
                                }
                            >
                                <Button type="text">
                                    <IconAdd/>
                                </Button>
                            </Dropdown>
                        )}
                    </div>
                    <div className="item-gateway">
                        {routage.load_balancing.length > 1
                            ? Gateways &&
                            Gateways.filter(
                                (elem) => elem._id === routage.load_balancing[1]
                            ).map((elem, i) => {
                                return (
                                    <div className="gatewaySelected" key={i}>
                                        <div className={"port_bg_blue"}/>
                                        <p>{elem.name}</p>
                                    </div>
                                );
                            })
                            : routage.load_balancing.length === 1 && (
                            <Dropdown
                                placement={"bottomCenter"}
                                className={"dropdown"}
                                disabled={rolesCondition}
                                overlay={
                                    <div className={"optionsDropdown"}>
                                        {Gateways &&
                                            Gateways.filter(
                                                (elem) =>
                                                    elem._id !== "" &&
                                                    elem._id !== routage.load_balancing[0]
                                            ).map((elem, i) => {
                                                return (
                                                    <Button
                                                        key={i}
                                                        value={elem._id}
                                                        className="btn-value"
                                                        onClick={() =>
                                                            this.selectGateways(
                                                                elem._id,
                                                                "load_balancing"
                                                            )
                                                        }
                                                    >
                                                        {elem.name}
                                                    </Button>
                                                );
                                            })}
                                    </div>
                                }
                            >
                                <Button type="text">
                                    <IconAdd/>
                                </Button>
                            </Dropdown>
                        )}
                    </div>
                    <div className="item-gateway">
                        {routage.load_balancing.length > 2
                            ? Gateways &&
                            Gateways.filter(
                                (elem) => elem._id === routage.load_balancing[2]
                            ).map((elem, i) => {
                                return (
                                    <div className="gatewaySelected" key={i}>
                                        <div className={"port_bg_blue"}/>
                                        <p>{elem.name}</p>
                                    </div>
                                );
                            })
                            : routage.load_balancing.length === 2 && (
                            <Dropdown
                                placement={"bottomCenter"}
                                className={"dropdown"}
                                disabled={rolesCondition}
                                overlay={
                                    <div className={"optionsDropdown"}>
                                        {arr
                                            .filter((elem) => elem._id)
                                            .map((elem, i) => {
                                                return (
                                                    <Button
                                                        key={i}
                                                        value={elem._id}
                                                        className="btn-value"
                                                        onClick={() =>
                                                            this.selectGateways(
                                                                elem._id,
                                                                "load_balancing"
                                                            )
                                                        }
                                                    >
                                                        {elem.name}
                                                    </Button>
                                                );
                                            })}
                                    </div>
                                }
                            >
                                <Button type="text">
                                    <IconAdd/>
                                </Button>
                            </Dropdown>
                        )}
                    </div>
                </div>

                {routage.status === "autonomous" && (
                    <div style={{display: routage.status === "autonomous" ? "flex" : "none"}}>
                        {routage.autonomous &&
                        routage.autonomous.length > 0 &&
                        routage.autonomous[0].length > 0 ? (
                            Gateways &&
                            Gateways.filter((elem) => elem._id === routage.autonomous[0]).map(
                                (elem, i) => {
                                    return (
                                        <div className="gatewaySelected" key={i}>
                                            <div className={"port_bg_blue"}/>
                                            <p>{elem.name}</p>
                                        </div>
                                    );
                                }
                            )
                        ) : (
                            <Dropdown
                                overlay={
                                    <div className={"optionsDropdown"}>
                                        {Gateways &&
                                            Gateways.filter((elem) => elem._id).map((elem, i) => {
                                                return (
                                                    <Button
                                                        key={i}
                                                        value={elem._id}
                                                        className="btn-value"
                                                        onClick={() =>
                                                            this.selectGateways(elem._id, "autonomous")
                                                        }
                                                    >
                                                        {elem.name}
                                                    </Button>
                                                );
                                            })}
                                    </div>
                                }
                                disabled={rolesCondition}
                                placement={"bottomCenter"}
                                className={"dropdown-autonomous"}
                            >
                                <Button type="text">
                                    <IconAdd/>
                                </Button>
                            </Dropdown>
                        )}
                    </div>
                )}

                {this.state.gatewaySort.length > 0 && (
                    <div
                        style={{display: routage.status === "failover" ? "flex" : "none"}}
                    >
                        <DragNDrop
                            className={``}
                            itemClassName={"routage__port"}
                            getDragPositions={this.getDragPositions}
                            index={this.props.index}
                        >
                            {this.state.gatewaySort.filter(gateway => gateway && gateway.name).map((gateway) => {
                                return <p key={gateway._id}>{gateway.name}</p>;
                            })}
                        </DragNDrop>
                    </div>
                )}
            </div>
        );
    }
}

export default RoutageStatus;
