import React from 'react';
import Socket from '../../../../../../utils/Socket';
import PieChartCharge from './PieChartCharge/PieChartCharge';
import { ItemDataCharge } from '../../../../../../types';

interface State {
    data: Array<ItemDataCharge>;
}

interface Props {
    equipment_id: string;
}

class ChargesPanel extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: [
                {
                    category: 'CPU',
                    value: 0,
                    full: 100,
                },
                {
                    category: 'RAM',
                    value: 0,
                    full: 100,
                },
                {
                    category: 'DISK',
                    value: 0,
                    full: 100,
                },
            ],
        };
    }

    getStatus = async () => {
        await Socket.readLast('Status', { device: this.props.equipment_id }, {}).then((res) => {
            if (res && res.data) {
                console.log("CHARGE", res);
                const { cpu, ram, disk } = res.data;
                this.setState(() => ({
                    data: [
                        {
                            category: 'CPU',
                            value: cpu ? cpu : "0",
                            full: 100,
                        },
                        {
                            category: 'RAM',
                            value: ram ? ram : "0",
                            full: 100,
                        },
                        {
                            category: 'DISK',
                            value: disk ? disk : "0",
                            full: 100,
                        },
                    ],
                }));
            }
        });
    };

    componentDidMount() {
        this.getStatus();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.equipment_id !== this.props.equipment_id) {
            this.getStatus();
        }
    }

    render() {
        const { data } = this.state;
        return (
            <div className={'ChargesPanel'}>
                {data.map((d, index) => (
                    <div key={index} className={'ctn__chart'}>
                        <PieChartCharge name={d.category} labelUnit={d.category} data={[d]} chartPage='equipement' charges={"%"} />
                    </div>
                ))}
            </div>
        );
    }
}

export default ChargesPanel;
