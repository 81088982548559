import React, {PureComponent} from 'react';
import { ClasseType } from '../../../../../types';
import { Tabs } from 'antd';
import SimplePieChart from '../../../../../common/SimplePieChart/SimplePieChart';

const { TabPane } = Tabs;

interface Props {
    apps: Array<ClasseType>;
    notAllowedLimitation: number;
    notAllowedReservation: number;
}

class GlobalViews extends PureComponent<Props> {

    render() {
        const { apps, notAllowedLimitation, notAllowedReservation } = this.props;
        const limitations = [
            ...apps.map((app) => ({ name: app.name, value: app.limitation })),
            {
                name: 'Non alloue',
                value: notAllowedLimitation,
            },
        ];
        const reservations = [
            ...apps.map((app) => ({ name: app.name, value: app.reservation })),
            {
                name: 'Non alloue',
                value: notAllowedReservation,
            },
        ];
        return (
            <div className={'GlobalViews mn_ctn'}>
                <h4>Vue Globale</h4>
                <Tabs defaultActiveKey={'Reservations'}>
                    <TabPane tab={'Réservations'} key={'Reservations'}>
                        <SimplePieChart data={reservations} name={'reservations'} />
                    </TabPane>
                    <TabPane tab={'Limitations'} key={'Limitations'}>
                        <SimplePieChart data={limitations} name={'limitations'} />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default GlobalViews;
