//@ts-nocheck

import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { AlertNotificationType, AlertType, EntityType } from "../../types";
import moment from "moment";
import { DatePicker, Drawer } from "antd";
import BasicTable from "../../common/BasicTable/BasicTable";
import Fade from "../../common/Fade/Fade";
import ContainerRestPage from "../../common/ContainerRestPage/ContainerRestPage";

const stateRefac: {
    operationnal: string;
    degraded: string;
    offline: string;
    [key: string]: string;
} = {
    operationnal: "Opérationnel",
    degraded: "Dégradé",
    offline: "Hors ligne",
};

const columns = [
    {
        Header: "Boitier",
        accessor: "boitier_name",
        sort: true,
        Cell: function cell(row: { original: { ticket: { ticketNumber: string } } }) {
            return row.original.name && <p>{row.original.name}</p>;
        },
    },
    {
        Header: "Type d'alerte",
        accessor: "type",
        sort: true,
        Cell: function cell(row: { original: { type: string } }) {
            return <div className={`${row.original.type} cell__state`}>{row.original.type}</div>;
        },
    },
    {
        Header: "Service",
        accessor: "service",
        sort: true,
    },
    {
        Header: "Date de début",
        accessor: "begin_date",
        sort: true,
        Cell: function (row: any) {
            if (row.original.begin_date) {
                const beginHours = row.original.begin_date.slice(11, 16);
                return <div>{new Date(row.original.begin_date).toLocaleDateString("fr-FR") + " " + beginHours}</div>;
            } else {
                return <div></div>;
            }
        },
    },
    {
        Header: "Date de fin",
        accessor: "end_date",
        sort: true,
        Cell: function (row: any) {
            if (row.original.end_date) {
                const endHours = row.original.end_date.slice(11, 16);
                return <div>{new Date(row.original.end_date).toLocaleDateString("fr-FR") + " " + endHours}</div>;
            } else {
                return <div></div>;
            }
        },
    },
    {
        Header: "Détail",
        accessor: "details",
        sort: true,
    },
    {
        Header: "Ticket",
        accessor: "ticket_id",
        sort: true,
        Cell: function cell(row: { original: { ticket_id: String } }) {
            return (
                row.original.ticket && (
                    <div
                        className={"detail"}
                        style={{ marginRight: "5rem" }}
                        dangerouslySetInnerHTML={{ __html: row.original.ticket_id }}
                    />
                )
            );
        },
    },
];

const typeRefac: {
    information: string;
    critical: string;
    warning: string;
    [key: string]: string;
} = {
    information: "Information",
    critical: "Critique",
    warning: "Dégradé",
};

interface ReduxState {
    collections: {
        Entity: Array<EntityType>;
        AlertsNotifications: Array<AlertNotificationType>;
    };
}

const mapStateToProps = (state: ReduxState) => {
    return {
        entities: state.collections.Entity,
        entityId: state.global.entity_id,
        alertsNotifications: state.collections.AlertsNotifications,
    };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface State {
    columns: [];
    begin_date: any;
    end_date: any;
    loading: boolean;
    notificationsSideBar: boolean;
    alert: any;
    formType: String;
}

class Notifications extends React.Component<PropsFromRedux, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            columns: [],
            begin_date: "",
            end_date: "",
            loading: true,
            notificationsSideBar: false,
            alert: null,
            formType: "",
        };
    }

    componentDidMount = () => {
        document.title = "Wanup | Notifications";
        const end_date = new Date();
        const begin_date = new Date(new Date().setMonth(new Date().getMonth() - 1));
        this.setState((prevState) => ({
            ...prevState,
            begin_date,
            end_date,
            loading: this.props.entities.length === 0,
        }));
    };

    // componentDidUpdate(prevProps: Readonly<PropsFromRedux>) {
    //     if (this.props.entities !== prevProps.entities) {
    //         this.setState(() => ({ loading: this.props.entities.length === 0 }));
    //     }
    // }

    flatRecursive = (a: any, searchKey: any, t: any, keys: any, current: any) => {
        if (Array.isArray(a)) {
            for (let i = 0; i < a.length; i++) {
                if (a[i].site_name) keys.site_name = a[i].site_name;
                if (current === "Equipment") {
                    if (a[i].name) keys.name = a[i].name;
                    if (a[i].state) keys.state = a[i].state;
                }
                if (a[i].entity_uuid) keys.entity_uuid = a[i].entity_uuid;
                if (typeof a[i] === "object" && !Array.isArray(a[i])) {
                    for (let [key, value] of Object.entries(a[i])) {
                        key = key;
                        if (key === searchKey) {
                            //@ts-ignore
                            if (value.length > 0) {
                                //@ts-ignore
                                value = Array.from(value, (x) => ({ ...x, ...keys }));
                            }
                            //@ts-ignore
                            t.push(...value);
                        }
                        if (typeof value === "object" && value) {
                            this.flatRecursive(value, searchKey, t, keys, key);
                        }
                    }
                }
            }
        }
        return t;
    };

    formatData() {
        const { entities, alertsNotifications, entityId } = this.props;
        const beginDate = new Date(new Date(this.state.begin_date).setHours(0o0, 0o0, 0o0));
        const endDate = new Date(new Date(this.state.end_date).setHours(23, 59, 0o0));
        const alerts = this.flatRecursive(entities, "Alerts", [], {}, "");
        const equipments = this.flatRecursive(entities, "Equipment", [], {}, "");
        return alerts
            .map((alert: AlertType) => {
                const tickets = alertsNotifications.filter((ticket) => ticket.alert_id === alert._id);
                const equipmentName = equipments.filter((id) => id._id === alert.equipment_id);
                return {
                    ...alert,
                    boitier_name: equipmentName.length > 0 ? equipmentName[0].name : null,
                    type: typeRefac[alert.type],
                    archived: alert.archived ? "En cours" : "Terminé",
                    state: alert.state,
                    begin_date: alert.begin_date,
                    end_date: alert.end_date,
                    ticket_id: alert.ticket_id,
                    ticket:
                        tickets.length > 0
                            ? {
                                  ticketSteps: tickets,
                              }
                            : null,
                };
            })
            .filter((alert: AlertType) => {
                const alertBeginDate = new Date(alert.begin_date);
                const alertEndDate = new Date(alert.end_date);
                return (
                    (alertBeginDate > beginDate && alertEndDate < endDate && alertBeginDate < endDate) ||
                    (alertBeginDate > beginDate && alertBeginDate < endDate && isNaN(alertEndDate))
                );
            });
    }

    getTicketNumber(str: string) {
        return str.substring(str.indexOf(">") + 1, str.lastIndexOf("<"));
    }

    openSidebar = (bool: boolean, alert?: any) => {
        this.setState({ notificationsSideBar: bool, alert, formType: "view" });
    };

    onClose = () => {
        this.setState({ notificationsSideBar: false, alert: null, formType: "" });
    };

    changeDateStart = (e: any) => {
        if (e && e._d) {
            this.setState(() => ({
                begin_date: new Date(e._d),
            }));
        }
    };

    changeDateEnd = (e: any) => {
        if (e && e._d) {
            this.setState(() => ({
                end_date: new Date(e._d),
            }));
        }
    };

    compare(a: any, b: any) {
        if (a.step_order < b.step_order) {
            return -1;
        }
        if (a.step_order > b.step_order) {
            return 1;
        }
        return 0;
    }
    render() {
        const { begin_date, end_date, alert } = this.state;
        const dataTable = this.formatData();
        let data = [];
        if (alert) {
            dataTable.filter((elem) => elem._id === alert).length > 0 &&
                data.push(dataTable.filter((elem) => elem._id === alert)[0]);
        }
        return (
            <div className="Notifications">
                <Fade>
                    <div className="notifications-content">
                        <div className={"ctn-inputs-date"}>
                            {begin_date && (
                                <div className={"ctn-input-date"}>
                                    <label> Date de début</label>
                                    <DatePicker
                                        defaultValue={moment(begin_date, "DD-MM-YYYY")}
                                        onChange={(date, dateString) =>
                                            this.changeDateStart(date, dateString, "begin_date")
                                        }
                                    />
                                </div>
                            )}
                            {end_date && (
                                <div className={"ctn-input-date"}>
                                    <label> Date de fin</label>
                                    <DatePicker
                                        defaultValue={moment(end_date, "DD-MM-YYYY")}
                                        onChange={(date, dateString) =>
                                            this.changeDateEnd(date, dateString, "end_date")
                                        }
                                    />
                                </div>
                            )}
                        </div>
                        <ContainerRestPage>
                            <BasicTable
                                columns={columns}
                                data={dataTable}
                                tableName={"notifications"}
                                openForm={this.openSidebar}
                                defaultSorted={[
                                    {
                                        id: "begin_date",
                                        desc: true,
                                    },
                                ]}
                            />
                        </ContainerRestPage>
                    </div>
                </Fade>
                {data.length > 0 && data[0].ticket !== null && (
                    <Drawer
                        title={`Détails du ticket N° ${data[0].ticket_id}`}
                        placement="right"
                        className={"DrawerNotifications"}
                        onClose={this.onClose}
                        closable={true}
                        visible={this.state.notificationsSideBar}
                        width="30%"
                        key={data[0]._id}
                    >
                        <div>
                            Boitier : <h3>{data[0].name}</h3>
                            <br />
                            Ticket :{" "}
                            <h3>
                                <a
                                    href={`https://support.sayse.fr/a/tickets/${data[0].ticket_id}`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {data[0].ticket_id}
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                                        <path d="M13.723 18.654l-3.61 3.609c-2.316 2.315-6.063 2.315-8.378 0-1.12-1.118-1.735-2.606-1.735-4.188 0-1.582.615-3.07 1.734-4.189l4.866-4.865c2.355-2.355 6.114-2.262 8.377 0 .453.453.81.973 1.089 1.527l-1.593 1.592c-.18-.613-.5-1.189-.964-1.652-1.448-1.448-3.93-1.51-5.439-.001l-.001.002-4.867 4.865c-1.5 1.499-1.5 3.941 0 5.44 1.517 1.517 3.958 1.488 5.442 0l2.425-2.424c.993.284 1.791.335 2.654.284zm.161-16.918l-3.574 3.576c.847-.05 1.655 0 2.653.283l2.393-2.389c1.498-1.502 3.94-1.5 5.44-.001 1.517 1.518 1.486 3.959 0 5.442l-4.831 4.831-.003.002c-1.438 1.437-3.886 1.552-5.439-.002-.473-.474-.785-1.042-.956-1.643l-.084.068-1.517 1.515c.28.556.635 1.075 1.088 1.528 2.245 2.245 6.004 2.374 8.378 0l4.832-4.831c2.314-2.316 2.316-6.062-.001-8.377-2.317-2.321-6.067-2.313-8.379-.002z" />
                                    </svg>
                                </a>
                            </h3>
                        </div>
                        <div className={"ctn_steps"}>
                            {data[0].ticket.ticketSteps.length > 0 &&
                                data[0].ticket.ticketSteps
                                    .sort((a, b) => this.compare(a, b))
                                    .map((step) => (
                                        <div
                                            className={`ctn_step ${step.done === true ? "done_step" : "progress_step"}`}
                                            style={step.done === true ? { opacity: 1 } : { opacity: 0.3 }}
                                        >
                                            <div className={`step `} key={step._id}>
                                                <h3>{step.step}</h3>
                                                <span className={data[0].type}>{data[0].type}</span>
                                                {step.detail.trim() ? (
                                                    <div
                                                        className={"detail"}
                                                        dangerouslySetInnerHTML={{ __html: step.detail }}
                                                    />
                                                ) : (
                                                    <div className={"detail"}>En attente de traitement...</div>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                        </div>
                    </Drawer>
                )}
            </div>
        );
    }
}

export default connector(Notifications);
