import React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { ProtocolType } from "../../types";
import { convertValueUnit, convertValueUnitOctets } from "../../utils/Math";

interface Props {
    data: Array<{ protocol: string, count: number }>,
    Protocols: Array<ProtocolType>,
    name: string
}

class ColumnsChart extends React.Component<Props> {
    private chart: any;

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (prevProps.data !== this.props.data) {
            this.amchart(this.props.data);
        }
    }

    amchart(data: any) {
        am4core.useTheme(am4themes_animated);
        am4core.options.autoDispose = true;
        let array: any[] = [];
        console.log("DATA",data);
        if (data.length > 0) {
            data && data.forEach((e: any) => {
                array.push({
                    protocol: e.protocol,
                    count: e.count
                })
            })
        } else {
            array.push({
                protocol: 'Aucune donnée(s) disponible', count: 0
            })
        }

        console.log("ARRAY", array);
        const chart = am4core.create(`chartdiv__${this.props.name}`, am4charts.XYChart);

        chart.data = array && array;
        chart.height = am4core.percent(85);


        const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.dataFields.category = "protocol";
        categoryAxis.renderer.minGridDistance = 60;
        categoryAxis.renderer.inversed = false;
        categoryAxis.renderer.grid.template.disabled = true;
        // categoryAxis.renderer.label.template.marginTop = 40;

        const image = new am4core.Image();
        image.horizontalCenter = "middle";
        image.width = 50;
        image.height = 50;
        // image.margin(50, 0,0,0)
        image.verticalCenter = "top";
        image.adapter.add("href", (href, target) => {
            return href;
        })
        categoryAxis.renderer.labels.template.disabled = false
        var axisLabels = categoryAxis.renderer.labels.template;
        axisLabels.fontSize = 8;
        axisLabels.fontWeight = "bold";
        axisLabels.fontFamily = "Helvetica";
        // axisLabels.rotation = -90;
        axisLabels.dy = 0;
        categoryAxis.renderer.labels.template.adapter.add("dy", function(dy, target) {
            if (target.dataItem && target.dataItem.index && dy) {
                return dy + 25;
            }
            return dy;
        });
        // categoryAxis.dataItems.template.bullet = image;
        const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.min = 0;
        valueAxis.extraMax = 0.1;

        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.renderer.labels.template.disabled = true;
        valueAxis.renderer.labels.template.adapter.add("text", (label, target, key) => {
            const dataValue = target.dataItem.values.value.value;
            //@ts-ignore
            if (!isNaN(dataValue)) {
                //@ts-ignore
                return convertValueUnitOctets(dataValue);
            }
            return label;
        });
        const series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.categoryX = "protocol";


        let hoverState = series.columns.template.column.states.create("hover");
        // hoverState.properties.cornerRadiusTopLeft = 0;
        // hoverState.properties.cornerRadiusTopRight = 0;
        hoverState.properties.fillOpacity = 0.8;

        series.dataFields.valueY = "count";
        series.tooltipText = "{valueY.value}"
        series.columns.template.strokeOpacity = 0;
        series.columns.template.column.cornerRadiusTopRight = 5;
        series.columns.template.column.cornerRadiusTopLeft = 5;
        series.columns.template.column.cornerRadiusBottomRight = 5;
        series.columns.template.column.cornerRadiusBottomLeft = 5;
        series.columns.template.width = am4core.percent(65);
        series.columns.template.height = am4core.percent(95);
        const labelBullet = series.bullets.push(new am4charts.LabelBullet());
        labelBullet.label.verticalCenter = "bottom";
        labelBullet.label.dy = 30;
        // labelBullet.label.text = "{valueY.value}";
        labelBullet.label.adapter.add("text", (_, label) => {
            // @ts-ignore
            const initialValue = label.dataItem?.values.valueY.value;
            // @ts-ignore
            return convertValueUnitOctets(initialValue, false, false)
        })
        labelBullet.label.fill = am4core.color('#ffffff')
        labelBullet.label.fontSize = '1.5rem'


        chart.zoomOutButton.disabled = true;
        this.chart = chart;

        series.columns.template.adapter.add("fill", function (_, target) {
            //@ts-ignore
            return chart.colors.getIndex(target.dataItem.index);
        });
        categoryAxis.sortBySeries = series;
    }

    componentDidMount() {
        this.amchart(this.props.data);
    }


    render() {
        const { name } = this.props;
        return (
            <div id={`chartdiv__${name}`}>

            </div>
        );
    }
}

export default ColumnsChart;
