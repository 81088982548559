import React from 'react';
import { GroupPortType } from '../../../../../types';
import TextField from '../../../../../common/TextField/TextField';
import { Button } from 'antd';

interface Props {
    groupPorts: Array<GroupPortType>;
    groupPort: GroupPortType;
    groupPortIndex: number;
    changeValue: (GroupPorts: Array<GroupPortType>) => void;
    role: any,
    indexPorts: number
}

interface State {
    edit: boolean;
}

class GroupPort extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            edit: false,
        };
    }

    componentDidMount() {
        const { groupPort } = this.props;
        if (!groupPort._id) {
            this.setState(() => ({ edit: true }));
        }
    }

    edit = () => {
        this.setState((state) => ({ edit: !state.edit }));
    };

    remove = () => {
        const { groupPorts, groupPort } = this.props;
        const newGroupPorts = groupPorts.filter((group) => groupPort._id !== group._id);
        this.props.changeValue(newGroupPorts);
    };

    changeValue = (name: string, value: string, index?: number) => {
        const { groupPorts, groupPortIndex } = this.props;
        const newGroupPorts = groupPorts.map((group, indexGroup) => {
            if (groupPortIndex !== indexGroup) return group;
            if (name === 'name') {
                return { ...group, [name]: value };
            } else {
                return {
                    ...group,
                    ports: group.ports.map((port, i) => {
                        if (index !== i) return port;
                        return { ...port, [name]: value };
                    }),
                };
            }
        });
        this.props.changeValue(newGroupPorts);
    };

    addIp = () => {
        const { groupPorts, groupPortIndex } = this.props;
        const newGroupPorts = groupPorts.map((group, index) => {
            if (index === groupPortIndex) {
                return { ...group, ports: [...group.ports, { value: '' }] }
            } else {
                return group;
            }
        })
        this.setState(() => ({ edit: true }));
        this.props.changeValue(newGroupPorts);
    }

    removeAddressIp = (index: number) => {
        const { changeValue, groupPorts, groupPortIndex } = this.props;
        const newGroupPorts = groupPorts.map((group, indexGn) => {
            if (indexGn === groupPortIndex) {
                return {
                    ...group,
                    ports: group.ports.filter((_, i) => index !== i)
                }
            } else {
                return group;
            }
        })
        changeValue(newGroupPorts);

    };

    render() {
        const { edit } = this.state;
        const { groupPort, role, indexPorts } = this.props;
        const key = window.location.pathname.substring(1)

        return (
            <div className={'GroupPort ctn__fields'}>
                <div>
                    <label>Nom du groupe</label>
                    <TextField
                        className={''}
                        required={true}
                        placeholder={'Nom du groupe'}
                        name={'name'}
                        value={groupPort.name}
                        changeValue={this.changeValue}
                        type={'text'}
                        disabled={!edit}
                    />
                </div>
                <div className={'ctn__details'}>
                    <label>Détails</label>
                    <div className={'list__details'}>
                        {groupPort.ports.map((port, index) => {
                            return (
                                <div key={index} className="d-flex">
                                    <div
                                        onClick={() => this.removeAddressIp(index)}
                                        className={'icon__cross__remove'}
                                        title={'Supprimer'}
                                        style={{ marginTop: "3.2rem" }}

                                    />
                                    <TextField
                                        key={index}
                                        className={''}
                                        placeholder={'Détail'}
                                        name={'value'}
                                        value={port.value}
                                        changeValue={(name, value) => this.changeValue(name, value, index)}
                                        type={'text'}
                                        disabled={!edit}
                                        required={true}
                                        isPort={true}
                                        indexPorts={indexPorts}
                                        indexField={index}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
                {
                    role[0][key].write === true &&
                    <>
                        <Button htmlType="button" onClick={this.addIp} className={'btn__add__ip btn__entry__update'}>
                            Ajouter des ports
                        </Button>
                        <div className={'ctn__btns'}>
                            <Button htmlType="button" onClick={this.edit} className={'btn__entry__update'}>
                                Modifier
                            </Button>
                            <Button htmlType="button" onClick={this.remove} className={'btn__entry__delete'}>
                                Supprimer
                            </Button>
                        </div>
                    </>
                }
            </div>
        );
    }
}

export default GroupPort;
