import React from "react";
import {EntityType, GroupNetworkType, GroupPortType} from "../../../types";
import Collections from "../../../utils/Collections";
import GroupPorts from "./GroupPorts/GroupPorts";
import Socket from "../../../utils/Socket";
import {Button, notification} from "antd";

interface Props {
    Entity: EntityType[];
    entityID: string
}

interface State {
    groupPorts: GroupPortType[];
    originalGroups: GroupNetworkType[];
}

const openNotificationWithIcon = (type: string) => {
	//@ts-ignore
	notification[type]({
		style: { color: 'green', marginTop: '12rem' , marginRight: '3rem' },
		duration: 3,
		description: 'Les modifications ont été prises en compte',
		//   description:
		//     'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
	});
};

export default class PortsObjects extends React.Component<Props, State> {


    constructor(props: Props | Readonly<Props>) {
        super(props);
        this.state = {
            originalGroups: [],
            groupPorts: []
        }
    }

    componentDidMount() {
        const groupPorts = Collections.flatRecursive(
            this.props.Entity,
            "GroupPorts",
            [],
            ""
        )
        this.setState(() => ({
	        originalGroups: groupPorts,
	        groupPorts
        }))
    }

    changeGroupeValue = (
        type: "groupNetworks" | "groupPorts",
        data: Array<GroupNetworkType | GroupPortType>
    ) => {
        // @ts-ignore
        this.setState(() => ({groupPorts: data}));
    };

	closeEdit = (): void => {
		this.setState((state) => ({
			...state,
			selectedSource: '',
		}));
	};

    saveGroups = () => {
        Socket.updateGroups('GroupPorts', this.state.originalGroups, this.state.groupPorts, this.props.entityID)
        .then((res) => {
            console.log(res);
	        openNotificationWithIcon('success')
	        this.closeEdit()
        })
        .catch(err => console.error(err))
    }

    render() {
        console.log(this.state.groupPorts, this.props.entityID);
        return (
            <>
                <div className={'objects'}>
                    <GroupPorts groupPorts={this.state.groupPorts.filter(element => element.entity_id === this.props.entityID)} changeGroupeValue={this.changeGroupeValue}/>
                </div>
                <Button className={'submit__group'} onClick={this.saveGroups}>Enregistrer</Button>
            </>
        )
    }
}