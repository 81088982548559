import React from 'react';
import {Select, Switch} from 'antd';
import {connect, ConnectedProps} from 'react-redux';
import {nanoid} from 'nanoid';
import {EntityType, FireWallType, ProtocolType, RuleType} from '../../../../../types';
import Collections from '../../../../../utils/Collections';
import Socket from '../../../../../utils/Socket';
import {ruleState} from '../../../../../state';
import BasicDrawerForm from '../../../../../common/BasicDrawerForm/BasicDrawerForm';
import SelectSimple from '../../../../../common/SelectSimple/SelectSimple';
import TextField from '../../../../../common/TextField/TextField';

const {Option} = Select;
const dscp = [
	'Best Effort',
	'CS1',
	'CS2',
	'CS3',
	'CS4',
	'CS5',
	'CS6',
	'CS7',
	'AF11',
	'AF12',
	'AF13',
	'AF21',
	'AF22',
	'AF23',
	'AF31',
	'AF32',
	'AF33',
	'AF41',
	'AF42',
	'AF43',
	'EF',
];
const huit_cent_un = ['0', '1', '2', '3', '4', '5', '6', '7'];
const action = ['Accept', 'Reject', 'Drop', 'SNAT', 'DNAT', 'Masquerade'];

interface ReduxState {
	collections: {
		Entity: Array<EntityType>;
		Protocols: Array<ProtocolType>;
	};
	global: { entity_id: string };
}


const mapStateToProps = (state: ReduxState) => {
	return {
		entities: state.collections.Entity,
		entityId: state.global.entity_id,
		protocols: state.collections.Protocols
	};
};

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
	formType: string;
	rules: Array<RuleType> | undefined;
	selectedItems: Array<string>;
	firewall: FireWallType | undefined;
	closeForm: () => void;
	visibleForm: boolean;
	openForm: (formType: string, selectedItem?: string) => void;
	isUpdate: (bool: boolean) => void
}

interface State {
	data: RuleType;
	errors: RuleType;
}

class RegleForm extends React.Component<Props, State> {
	constructor(props: any) {
		super(props);
		this.state = {
			data: ruleState,
			errors: ruleState,
		};
	}

	componentDidUpdate(prevProps: Readonly<Props>) {
		if (prevProps.selectedItems !== this.props.selectedItems) {
			if (this.props.selectedItems.length > 0 && this.props.rules && this.props.rules.length > 0) {
				const ruleFind = this.props.rules.find((rule) => this.props.selectedItems[0] === rule._id);
				if (ruleFind) {
					this.setState((state) => ({...state, data: ruleFind}));
				}
			} else {
				this.setState((state) => ({...state, data: ruleState}));
			}
		}
		if (prevProps.formType !== this.props.formType && this.props.formType !== 'view') {
			if (this.props.firewall && this.props.firewall._id) {

				let newState = { ...this.state.data}
				newState = { ...newState,
					entity_uuid: this.props.entityId,
					firewall_uuid: this.props.firewall && this.props.firewall._id ? this.props.firewall._id : ''
				}

				this.setState(() => ({
					data: newState
				}));
			}
		}
	}

	changeSelectValue = (name: string, value: string) => {
		this.setState((state) => ({data: {...state.data, [name]: value}}));
	};

	onChange = (name: string, value: string | boolean) => {
		this.setState((state) => ({data: {...state.data, [name]: value}}));
	};

	create = () => {
		const { data } = this.state;

		data.index = this.props.rules ? this.props.rules.length : 0;
		Socket.insert('Rules', data.entity_uuid, data)
		.then((res) => {
			if (res.status === 200) {
				this.props.closeForm();
			}
		})
		.catch((err) => console.error(err));
	};

	update = () => {
		const {data} = this.state;

		Socket.update('Rules', data._id, data.entity_uuid, data)
		.then((res) => {
			if (res.status === 200) {
				this.props.closeForm();
			}
		})
		.catch((err) => console.error(err));
	};

	duplicate = () => {
		const {data} = this.state;
		console.log(data)

		let newState = { ...data }
		newState = {
			...newState,
			_id: ""
		}

		data.index = this.props.rules ? this.props.rules.length : 0;
		Socket.insert('Rules', newState.entity_uuid, newState)
		.then((res) => {
			if (res.status === 200) {
				this.props.closeForm();
			}
		})
		.catch((err) => console.error(err));
	};

	submit = () => {
		this.props.isUpdate(true);
		const {formType} = this.props;
		const {data} = this.state;
		// const errors = checkEmptyFields(data, ['_id', '__v', 'index', 'comments', 'destination', 'destinationPort', 'protocols', 'dscp', 'dscp_modif', 'nat', 'natPort', 'source', 'sourcePort', 'huit_cent_deux_p', 'interface_in', 'interface_out']);
		//
		// console.log(errors)
		// if (Object.keys(errors).length > 0) {
		// 	this.setState(() => ({errors}));
		// } else {
			// @ts-ignore
			this[formType]();
		// }
	};

	onItem = () => {
		const {entities, firewall} = this.props;

		const entity = this.props.entities.find((entity) => entity._id === this.props.entityId);

		const filtrage = Collections.flatRecursive(entities, 'Interface', [], '');
		const equipments = Collections.flatRecursive(entities, 'Equipment', [], '');
		const equipmentFilter = equipments.filter(e => e.entity_uuid === entity?._id)
		let interfaces: string[] = []
        const equipmentIds = firewall?.equipment.map(e => e._id);

		const res = filtrage.filter((item, index) => {
			return index === filtrage.findIndex((obj) => {
				return item.id === obj.id
			})
		})

	    // for (const interf of res) {
		// 	if(equipmentIds) {
		// 	    for(const equipment of equipmentIds) {
		// 			for(const equip of equipmentFilter) {
		// 				if(equip._id === interf.equipment_id) {
		// 					interfaces.push(interf.name + " |  " + equip.name)
		// 				}
		// 			}
		// 	    }
		// 	}
	    // }

		for (const interf of res) {
			if(equipmentIds) {
				for(const equipment of equipmentIds) {
					interfaces.push(interf.name)
				}
			}
		}

		return interfaces.filter((item, index) => {
		    return index === interfaces.findIndex((obj) => {
			    return item === obj
		    })
	    })
    }

    render() {
        const {entities, visibleForm, closeForm, formType, protocols} = this.props;
        const {data, errors} = this.state;
        const groupNetworks = Collections.flatRecursive(entities, 'GroupeNetwork', [], '');
        const groupPorts = Collections.flatRecursive(entities, 'GroupPorts', [], '');
        const qos = Collections.flatRecursive(entities, 'Qos', [], '');


        // @ts-ignore
	    return (
            <BasicDrawerForm
                formType={formType}
                data={data}
                header={<p>Regles</p>}
                className={'ReglesForm'}
                onClose={closeForm}
                submit={this.submit}
                visible={visibleForm}
            >
                <div className={'ctn_inputs'}>

                    <div>
                        <label htmlFor="enabled">{"État"}</label>
                        <Switch
                            checked={this.state.data.enabled}
                            onChange={(e) => this.onChange('enabled', e)}
                            disabled={formType === 'view'}
                        />
                    </div>

                    <div>
                        <label htmlFor="i_e">{"Interface d'entrée"}</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('interface_in', '')}/>
                            <SelectSimple
                                items={this.onItem()}
                                placeholder={"Selectionner une interface d'entrée"}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'interface_in'}
                                value={this.state.data.interface_in}
                                ariaLabel='interface_in'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="i_s">Interface de sortie</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('interface_out', '')}/>
                            <SelectSimple
                                items={this.onItem()}
                                placeholder={'Selectionner une interface de sortie'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'interface_out'}
                                value={this.state.data.interface_out}
                                ariaLabel='interface_out'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="source">IP Source</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('source', '')}/>
                            <SelectSimple
                                items={groupNetworks}
                                valuesName={'name'}
                                valuesDisplay={'name'}
                                placeholder={'Selectionner une source'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'source'}
                                value={this.state.data.source}
                                ariaLabel='source'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="source_port">Port Source</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('sourcePort', '')}/>
                            <SelectSimple
                                items={groupPorts}
                                placeholder={'Selectionner un port source'}
                                valuesName={'name'}
                                valuesDisplay={'name'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'sourcePort'}
                                value={this.state.data.sourcePort}
                                ariaLabel='sourceePort'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="destination">IP Destination</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('destination', '')}/>
                            <SelectSimple
                                items={groupNetworks}
                                placeholder={'Selectionner une destination'}
                                valuesName={'name'}
                                valuesDisplay={'name'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'destination'}
                                value={this.state.data.destination}
                                ariaLabel='destination'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="d_p">Destination de port</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('destinationPort', '')}/>
                            <SelectSimple
                                items={groupPorts}
                                placeholder={'Selectionner un port de destination'}
                                valuesName={'name'}
                                valuesDisplay={'name'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'destinationPort'}
                                value={this.state.data.destinationPort}
                                ariaLabel={'destinationPort'}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="protocols">Protocole</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('protocols', '')}/>
                            <SelectSimple
                                items={protocols.map((element: { name: string; }) => element.name)}
                                placeholder={'Selectionner un protocol'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'protocols'}
                                value={this.state.data.protocols}
                                ariaLabel='protocols'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="dscp">DSCP</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'} onClick={() => this.changeSelectValue('dscp', '')}/>
                            <Select
                                value={this.state.data.dscp}
                                defaultValue={this.state.data.dscp}
                                dropdownClassName={'SelectSimple__Regle__dropdown'}
                                disabled={formType === 'view'}
                                placeholder={'Sélectionner un DSCP'}
                                onChange={(value) => this.changeSelectValue('dscp', value)}
                                id={'dscp'}
                                aria-label='dscp'
                            >
                                {dscp.map((e, i) => (
                                    <Option key={nanoid()} value={i}>
                                        {e}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    {/*<div>*/}
                    {/*    <label htmlFor="801.1p">801.1p</label>*/}
                    {/*    <Select*/}
                    {/*        dropdownClassName={'SelectSimple__Regle__dropdown'}*/}
                    {/*        disabled={formType === 'view'}*/}
                    {/*        value={this.state.data.huit_cent_un_p ? this.props.rules && this.props.rules[0].huit_cent_un_p : undefined}*/}
                    {/*        onChange={(value) => this.changeSelectValue('huit_cent_un_p', value)}*/}
                    {/*        id={'802.1p'}*/}
                    {/*        aria-label='801.1p'*/}
                    {/*    >*/}
                    {/*        {huit_cent_un.map((e) => (*/}
                    {/*            <Option key={nanoid()} value={e}>*/}
                    {/*                {e}*/}
                    {/*            </Option>*/}
                    {/*        ))}*/}
                    {/*    </Select>*/}
                    {/*</div>*/}
                    <div>
                        <label htmlFor="802.1p">802.1p</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('huit_cent_deux_p', '')}/>
                            <Select
                                dropdownClassName={'SelectSimple__Regle__dropdown'}
                                disabled={formType === 'view'}
                                placeholder={'Selectionner un 802.1p'}
                                value={this.state.data.huit_cent_deux_p}
                                defaultValue={this.state.data.huit_cent_deux_p}
                                onChange={(value) => this.changeSelectValue('huit_cent_deux_p', value)}
                                id={'802.1p'}
                                aria-label='802.1p'
                            >
                                {huit_cent_un.map((e) => (
                                    <Option key={nanoid()} value={e}>
                                        {e}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="action">Action</label>
	                    <div className={'d-flex'}>
	                    <div className={'icon__cross__remove'}
	                         onClick={() => this.changeSelectValue('action', '')}/>
                        <Select
                            dropdownClassName={'SelectSimple__Regle__dropdown'}
                            disabled={formType === 'view'}
                            placeholder={'Selectionner une action'}
                            value={this.state.data.action}
                            onChange={(value) => this.changeSelectValue('action', value)}
                            id={'action'}
                            aria-label='action'
                        >
                            {action.map((e) => (
                                <Option key={nanoid()} value={e}>
                                    {e}
                                </Option>
                            ))}
                        </Select>
	                    </div>
                    </div>
                    <div>
                        <label htmlFor="dscp_modif">DSCP modifié</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('dscp_modif', '')}/>
                            <Select
                                dropdownClassName={'SelectSimple__Regle__dropdown'}
                                disabled={formType === 'view'}
                                value={this.state.data.dscp_modif}
                                placeholder={'Selectionner un DSCP modifié'}
                                onChange={(value) => this.changeSelectValue('dscp_modif', value)}
                                id={'dscp_modif'}
                                aria-label='dscp_modif'
                            >
                                {dscp.map((e, i) => (
                                    <Option key={i} value={i}>
                                        {e}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div>
                        <label htmlFor="802.1p">802.1p modifié</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('huit_cent_deux_p', '')}/>
                            <Select
                                dropdownClassName={'SelectSimple__Regle__dropdown'}
                                disabled={formType === 'view'}
                                value={this.state.data.huit_cent_deux_p}
                                onChange={(value) => this.changeSelectValue('huit_cent_deux_p', value)}
                                id={'802.1p'}
                                aria-label='802.1p'
                                placeholder={'Sélectionner un DSCP modifié'}
                            >
                                {huit_cent_un.map((e) => (
                                    <Option key={nanoid()} value={e}>
                                        {e}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    </div>
                    {/*<div>*/}
                    {/*    <label htmlFor="Qos">QoS appliquée</label>*/}
                    {/*    <div className={'d-flex'}>*/}
                    {/*        <div className={'icon__cross__remove'}*/}
                    {/*             onClick={() => this.changeSelectValue('qos', '')}/>*/}
                    {/*        <SelectSimple*/}
                    {/*            items={qos}*/}
                    {/*            placeholder={'Selectionner une QOS'}*/}
                    {/*            valuesName={'name'}*/}
                    {/*            valuesDisplay={'name'}*/}
                    {/*            disabled={formType === 'view'}*/}
                    {/*            changeValue={this.changeSelectValue}*/}
                    {/*            name={'qos'}*/}
                    {/*            value={this.state.data.qos}*/}
                    {/*            ariaLabel='qos'*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div>
                        <label htmlFor="nat">IP Nat</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('nat', '')}/>
                            <SelectSimple
                                items={groupNetworks}
                                placeholder={'Selectionner un Nat'}
                                valuesName={'name'}
                                valuesDisplay={'name'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'nat'}
                                value={this.state.data.nat}
                                ariaLabel='nat'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="nat_port">Port Nat</label>
                        <div className={'d-flex'}>
                            <div className={'icon__cross__remove'}
                                 onClick={() => this.changeSelectValue('natPort', '')}/>
                            <SelectSimple
                                items={groupPorts}
                                placeholder={'Selectionner un Natport'}
                                valuesName={'name'}
                                valuesDisplay={'name'}
                                disabled={formType === 'view'}
                                changeValue={this.changeSelectValue}
                                name={'natPort'}
                                value={this.state.data.natPort}
                                ariaLabel='natPort'
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="commentaire">Commentaires</label>
                        <TextField
                            placeholder={'commentaire...'}
                            disabled={formType === 'view'}
                            name={'comments'}
                            value={this.state.data.comments}
                            className={''}
                            changeValue={this.onChange}
                            type={'text'}
                            required={false}
                            ariaLabel='commentaire'
                        />
                    </div>
                </div>
            </BasicDrawerForm>
        );
    }
}

export default connector(RegleForm);