import React, { useState, useEffect } from "react";
import "./DualButton.scss";

const DualButton = ({ initial, onChange, style = {}, disabled, interf }) => {
    const [selected, setSelected] = useState(initial);

    useEffect(() => {
        setSelected(initial);
    }, [initial]);

    const handleClick = (value) => {
        if (disabled) {
            return;
        }
        setSelected(value);
        onChange(value);
    };
    return (
        <div
            // className={
            //     interf.state !== "down" && interf.enabled ? "dual-button" : interf.enabled ? "dual-button-down" : "dual-button"
            // }
            className="dual-button"
            style={style}
        >
            <button className={selected === "WAN" ? "selected" : ""} onClick={() => handleClick("WAN")}>
                WAN
            </button>
            <button className={selected === "LAN" ? "selected" : ""} onClick={() => handleClick("LAN")}>
                LAN
            </button>
        </div>
    );
};

export default DualButton;
