import {GroupPortType, LogType, ModelBuildingType, ServerLdap, UserType} from './types';
import {
  DhcpType,
  DnsType,
  EntryType,
  EquipmentType,
  FireWallType,
  GatewayType,
  GroupNetworkType,
  InterfaceAddressType,
  InterfaceType,
  IpsecType,
  LinkType,
  QosType,
  RelaisType,
  RoutageType,
  RuleType,
  VpnUserType,
	ProtocolType
} from "./types";

export const ruleState: RuleType = {
  _id: "",
  firewall_uuid: "",
  enabled: false,
  entity_uuid: "",
  source: "",
  sourcePort: "",
  destination: "",
  destinationPort: "",
  interface_in: "",
  interface_out: "",
  dscp: "",
  huit_cent_deux_p: "",
  action: "",
  dscp_modif: "",
  qos: "",
  comments: "",
  nat: "",
  natPort: "",
  index: 0,
  protocols: "",
};

export const protocolState: ProtocolType = {
	_id: "",
	name: "",
	category_name: "",
	description: "",
	img: "",

};

export const equipmentState: EquipmentType = {
  _id: "",
  model: [],
  template: [],
  entity_uuid: "",
  entity_name: "",
  site_uuid: "",
  site_name: "",
  site_address1: "",
  site_address2: "",
  site_zipcode: "",
  site_city: "",
  site_latitude: "",
  site_longitude: "",
  site_country: "",
  name: "",
  state: "",
  serial: "",
  version: "",
  customized: "true",
  interfaces_nb: 0,
  provider_name: "",
  max_data: "",
};

export const interfaceAddressState: InterfaceAddressType = {
  _id: "",
  address: "",
  enabled: "",
  interface_uuid: "",
  entityId: "",
  gateway_id: ''
};

export const linkState: LinkType = {
  _id: "",
  reference: "",
  operator_uuid: "",
  operator_name: "",
  equipment_uuid: "",
  equipment_name: "",
  interface_uuid: "",
  interface_name: "",
  download_bw: "",
  upload_bw: "",
  ip: [],
  technology_name: "",
  technology_uuid: "",
  max_data: "",
  enabled: false,
  entity_uuid: "",
  entity_name: "",
  site_uuid: "",
  site_name: "",
  site_address1: "",
  site_address2: "",
  site_zipcode: "",
  site_latitude: "",
  site_longitude: "",
  site_country: "",
  entityId: "",
  id: "",
  technology: "",
  state: "",
};

export const qosState: QosType = {
  _id: "",
  classes: [],
  entity_id: "",
  name: "",
  color: "",
  interfaces: [],
};

export const groupNetworkState: GroupNetworkType = {
  _id: "",
  name: "",
  entity_id: "",
  networks: [],
};

export const groupPortState: GroupPortType = {
  _id: "",
  name: "",
  entity_id: "",
  ports: [],
};

export const fireWallState: FireWallType = {
  _id: "",
  entity_id: "",
  name: "",
  enabled: false,
  equipment: [],
};

export const vpnUserState: VpnUserType = {
    dns1: '',
    dns2: '',
    entity_id: '',
    id: '',
    modified_by: null,
    name: '',
    networks: '',
    valid_from: null,
    valid_to: null,
    _id: '',
    type: 'LDAP',
    ldap: '',
    local: [],
    radius: '',

}

export const ipsecState: IpsecType = {
  _id: "",
  local_networks: [],
  remote_networks: [],
  equipment: [],
  enabled: false,
  entity_id: "",
  local_id: "",
  local_ip: "",
  local_name: "",
  local_uuid: "",
  name: "",
  p1_dhgroup: 0,
  p1_dhgroup_name: "",
  p1_encryption: 0,
  p1_encryption_name: "",
  p1_hash: 0,
  p1_hash_name: "",
  p1_lifetime: 0,
  p1_uuid: "",
  p2_dhgroup: 0,
  p2_dhgroup_name: "",
  p2_encryption: 0,
  p2_encryption_name: "",
  p2_hash: 0,
  p2_hash_name: "",
  p2_lifetime: 0,
  p2_uuid: "",
  presharedkey: "",
  remote_id: "",
  remote_ip: "",
  remote_name: "",
  remote_uuid: "",
  state: "",
  type: 0,
};

export const gatewayState: GatewayType = {
  _id: "",
  name: "",
  entity_id: "",
  equipment_uuid: "",
  address: "",
  interface_id: "",
  interface_name: "",
  order: [],
  enabled: false,
  latency: 0,
  packet_loss: 0,
  target_address: "",
};

export const relaisState: RelaisType = {
  _id: "",
  interface_id: "",
  relay_ip1: "",
  relay_ip2: "",
  enabled: false,
  entityId: "",
};

export const entryState: EntryType = {
  domain: "",
  address: "",
};

export const dhcpState: DhcpType = {
  _id: "",
  interface_id: "",
  range_start: "",
  range_end: "",
  duration: "",
  dns1: "",
  dns2: "",
  gateway: "",
  domain: "",
  tftp1: "",
  tftp2: "",
  enabled: false,
  options: [],
  static: [],
  entityId: "",
};

export const dnsState: DnsType = {
  _id: "",
  interface_id: "",
  entityId: "",
  enabled: false,
  entries: [],
};

export const optionState = {
  option: "",
  value: "",
};

export const dhcpStatiqueState = {
  adress: "",
  mac: "",
};
export const interfaceState: InterfaceType = {
  _id: "",
  address: "",
  enabled: false,
  dhcp_client: false,
  name: "",
  Dhcp: dhcpState,
  Dns: dnsState,
  Relais: relaisState,
  Interface_address: [],
  equipment_id: "",
  filtering: [],
  id: "",
  internal_name: "",
  mac: "",
  mtu: "",
  qos_id: "",
  speed_duplex: "",
  type: "",
  parent_interface: [],
  type_vm: "",
  public_ip: "",
  pppoe_login: "",
  pppoe_password: "",
  zone: '',
  ip_gateway: "",
  state: "",
};

export const routagesState: RoutageType = {
  _id: "",
  name: "",
  enabled: false,
  entity_id: "",
  equipment_uuid: "",
  interface_in: "",
  interface_out: "",
  route_in: "",
  route_out: "",
  status: "",
  gateway: [],
  dragOrder: [],
  autonomous: [],
  load_balancing: [],
};

export const modelBuildingState: ModelBuildingType = {
    _id: '',
    entity_id: '',
    equipment_id: '',
    name: '',
    enabled: false,
    routages: [],
    gateways: [],
}


export const ServerLdapState : ServerLdap  = {
    _id: '',
    name: '',
    ip: '',
    port: '',
    common_name_identifier:'',
    distinguished_name:'',
    login: '',
    password: '',
    secure_connexion: false,
    entity_id:'',
}

export const userState: UserType = {
    _id: '',
    parent_id: '',
    lastname: '',
    firstname: '',
    email: '',
    mobile: '',
    password: '',
    profile: '',
    picture: '',
    enabled: false,
    role_id: '',
    valid_from: '',
    valid_to: '',
    modified_by: ''
}


export const logState: LogType = {
    _id: '',
    entity_id: '',
    begin_date: '',
    end_date: '',
    equipment_id: '',
    service: '',
    details: '',
    archived: '',
    category_id: '',
}